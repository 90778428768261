<template>
  <div class="login-modal" v-if="isVisible">
    <div class="login-modal-card">
      <div class="login-modal-card-content">
        <div class="sso-button-login">
          <div id="google-signin-button-login"></div>
        </div>
        <div class="login-separator-or">OR</div>
        <form @submit.prevent="submitForm" class="input-form-login">
          
          <div class="input-field-title">Email</div>
          <input type="email" class="input-field" v-model="formData.email" autocomplete="email">
          <div class="error-message2" v-if="formErrors.email">{{ formErrors.email }}</div>
          
          <div class="passoword-header">
            <div class="input-field-title">Password</div>
            <div class="inline-text-link"><router-link to="/forgot-password" @click="closeModal">Forgot password</router-link></div>
          </div>
          
          
          <div class="password-container">
            <input :type="isPasswordVisible ? 'text' : 'password'" class="input-field" v-model="formData.password" autocomplete="current-password">
            <div class="error-message1" v-if="formErrors.password">{{ formErrors.password }}</div>
            <img 
              :src="isPasswordVisible ? '/images/icon-password-displayed.png' : '/images/icon-password-hidden.png'"
              class="toggle-password-icon"
              @click="togglePasswordVisibility"
              :alt="isPasswordVisible ? 'Hide password' : 'Show password'"
            />
          </div>
          
          <button type="submit" class="button-form-action">Login</button>
        </form>
        <div class="button-form-text-action" @click="closeModal">Cancel</div>
        <!--<div class="secondary-text-login">Don't have an account yet? <router-link to="/pricing"><a class="inline-text-link">Sign up</a></router-link></div>-->
      </div>
    </div>
  </div>
</template>

<script>

/* global google */
import { inject, nextTick } from 'vue';
import axios from 'axios';

export default {

data() {
  return {
    isPasswordVisible: false,
    isVisible: false,
    currentPage: 1,
    formData: {
      email: '',
      password: '',
    },
    formErrors: {
    email: '',
    password: ''
  },
  apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
  };
},
setup() {  
  // Injecting the global state
  const globalState = inject('globalState');
  return { globalState };
},
watch: {
isVisible(newVal) {
  if (newVal) {
    this.$nextTick(() => {
      this.initializeGoogleSignIn();
    });
  }
}
},

mounted() {
  this.initializeGoogleSignIn();
  document.body.style.visibility = 'visible'; // If you used visibility: hidden;
},

computed: {
  isSubscribed() {
    return this.$store.getters.isSubscribed;
  },
  brandId() {
    return this.$store.getters.brandId;
  }
},

methods: {

  isEmailValid(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
},

  togglePasswordVisibility() {
          this.isPasswordVisible = !this.isPasswordVisible;
      },
  
  initializeGoogleSignIn() {
  nextTick(() => {
  google.accounts.id.initialize({
      client_id: '154747152337-ti4sfr4gfdls58464vknvlvlnnc6dcf9.apps.googleusercontent.com',
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("google-signin-button-login"),
      { theme: "outline", size: "large", width: 270 } 
    );
  });
  },

  handleCredentialResponse(response) {
  
  if (response && response.credential) {
      this.googleSignIn(response.credential);
  } else {
      console.error("Google Sign-In failed or returned no credential.");
  }





  
},
async googleSignIn(idToken) {
  try {
    const response = await axios.post(`${this.apiEndpoint}/google-signin`, { id_token: idToken });
    const email = response.data.email;
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
  
    const base64Url = response.data.access_token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));

    // Log the decoded payload
    console.log('Decoded JWT Payload from Google Sign-In:', payload);
    console.log('setIsSubscribed:', payload.sub.is_subscribed);

    // Update Vuex store with subscription status and email
    if (payload.sub && payload.sub.is_subscribed !== undefined) {
      this.$store.commit('setIsSubscribed', payload.sub.is_subscribed);
    }
    if (response.data.email) {
      this.$store.dispatch('updateEmail', response.data.email);
    }
    localStorage.setItem('userEmail', email);
    console.log('login email:', email);    
    this.globalState.updateLoginStatus(true, payload.sub.is_subscribed);

    const brand_id = payload.sub.brand_id;
    this.$store.dispatch('updateBrandId', brand_id);
    localStorage.setItem('brandId', brand_id);
    console.log('brand_id:', brand_id);
    

    if (payload.sub.is_subscribed) {
      //this.$router.push('/dashboard');
      //this.$router.push('/dashboard').then(() => {
      //window.location.href = window.location.origin + '/dashboard';
      //});
      window.location.reload();
      } else {
        await this.$store.dispatch('updateEmail', email);
        console.log('email:', email);
        this.closeModal();
      }
    
  } catch (error) {
    console.error("Error during Google Sign-In", error);
    // Handle the error appropriately
  }
},
  closeModal() {
    this.isVisible = false;
  },
  emitSwitchToSignUp() {
    this.$emit('switchToSignUp');
    this.closeModal(); // Close the login modal
  },

  async submitForm() {
  // Reset form errors
  this.formErrors.email = '';
  this.formErrors.password = '';

  // Validate email
  if (!this.formData.email) {
    this.formErrors.email = 'Email is required';
  } else if (!this.isEmailValid(this.formData.email)) {
    this.formErrors.email = 'Email is invalid';
  }

  // Validate password
  if (!this.formData.password) {
    this.formErrors.password = 'Password is required';
  }

  // If there are any errors, stop the form submission
  if (this.formErrors.email || this.formErrors.password) {
    return;
  }
  console.log('Form submitted'); 
    try {
      // Make the POST request with form data
      const response = await axios.post(`${this.apiEndpoint}/member-login`, {
        email: this.formData.email,
        password: this.formData.password
      });

      // Logging the received JWT tokens for debugging
      console.log('Received JWT tokens:', response.data);
      this.$store.dispatch('updateEmail', this.formData.email);

      // Store JWT tokens in localStorage or sessionStorage
      localStorage.setItem('accessToken', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);

      // Decoding JWT to extract subscription status
      const decodedPayload = JSON.parse(atob(response.data.access_token.split('.')[1]));

      console.log('Decoded JWT Payload:', decodedPayload);

      // Updating Vuex store with email
      const email = this.formData.email

      await this.$store.dispatch('updateEmail', email);
      localStorage.setItem('userEmail', email);
      console.log('email:', email);

      // Updating Vuex store with brand_id
      const brand_id = decodedPayload.sub.brand_id;
      this.$store.dispatch('updateBrandId', brand_id);
      localStorage.setItem('brandId', brand_id);
      console.log('brand_id:', brand_id);

      // Updating Vuex store with subscription status
      const isSubscribedValue = decodedPayload.sub.is_subscribed; 
      this.$store.commit('setIsSubscribed', isSubscribedValue);

      // Handle successful login here (e.g., update global state, redirect)
      console.log('Login successful:', response.data);


      // Update global login state with subscription status
      this.globalState.updateLoginStatus(true, isSubscribedValue); 

      if (isSubscribedValue) {
        //this.$router.push('/dashboard');
        //this.$router.push('/dashboard').then(() => {
        //window.location.href = window.location.origin + '/dashboard';
        //});
        window.location.reload();
      } else {
        await this.$store.dispatch('updateEmail', this.formData.email);
        console.log('email:', this.formData.email);
        this.closeModal();
      }



      
    } catch (error) {
    if (error.response && error.response.status === 404) {
          this.formErrors.email = "Email or password is incorrect";
      } else if (error.response && error.response.status === 401) {
          this.formErrors.password = "Incorrect password";
      } else {
          // Handle other errors...
          console.error('Login failed with an error:', error);
      }
    }
  }
}
};
</script>