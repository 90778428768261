<template>
  
  <div class="investors-body">
    <div class="investors-container">
      <div class="content-centred">
          <div class="investors-growth"> <img src="/images/investors-growth.jpg" class="img-fit" /></div>
          <div class="text-blocks">
            <div class="text-block-headine">Crowdscore offers a unique investment opportunity with massive growth potential.</div>
            <div class="text-block-subtitle">Contact <a href="mailto:investors@crowdscore.ai" class="inline-text-link">investors@crowdscore.ai</a> for more info.</div>
          </div>
      </div>
      <div class="flex-stickybottom">
        <div class="lowercontent-background"></div>
        <HomeFooter />
      </div>
    </div>
  </div>


</template>

<script>
  
  import HomeFooter from '/crowdscore/main/frontend/src/components/HomeFooter.vue';
  
  export default {
    name: 'InvestorsPage',
    components: {
        HomeFooter
    },
  }

</script>
