<template>
      
      <div id="section1" class="homefeatures">

            <div class="feature-block-fwdth-center">
                  <div class="feature-block-headine"> Find the next big stars!</div>
                  
                  <div class="feature-block-subtitle">Crowdscore uses advanced AI to indentify the world's most influential micro creators. Micro creators average 3x more engagement, providing unparalleled value at affordable prices. 
                        Partnering with emerging talent offers a strategic, cost-effective way amplify your growth.
                  </div>

                  <div class="feature-block-subtitle-m">Crowdscore uses advanced AI to indentify the world's most influential micro creators.</div>
                  <ul class="bullets-m">
                        <li class="bullets-m">Micro creators average 3x more engagement, providing unparalleled value at affordable prices</li>
                        <li class="bullets-m">Partnering with emerging talent offers a strategic, cost-effective way to amplify your growth</li>
                  </ul>
            </div>

            <div class=" feature-block-floater">
                  <div class="feature-block-floater1"></div>
            </div>
                        
            <div class="feature-block-fwdth-left">
                  <div class="content">
                        <div class="feature-block-text">
                              <b class="headline">Discover hidden gems using AI-powered search</b>
                              <ul class="bullets">
                                    <li class="bullets">AI powered influencer marketing platform</li>
                                    <li class="bullets">Search by vibe, keywords, categories and more</li>
                                    <li class="bullets">Conversational AI prompts</li>
                              </ul>
                        </div>
                        <div class="feature-image">
                              <img class="feature-image-description-icon" alt="" src="images/homepage-features-discover.jpg" />
                        </div>                      
                  </div>
            </div>

            <!--<div class=" feature-block-floater">
                  <div class="feature-block-floater2"></div>
            </div> -->
      
            <div class="feature-block-fwdth-right">
                  <div class="content-right">
                        <div class="feature-image-right">
                              <img class="feature-image-description-icon" alt="" src="/images/homepage-features-insights.jpg" />
                        </div>
                        <div class="feature-block-text">
                              <b class="headline">Advanced AI insights and analytics</b>
                              <ul class="bullets">
                                    <li class="bullets">Quickly gauge brand alignment and cost per post</li>
                                    <li class="bullets">Track engagement rates, audience growth and more</li>
                                    <li class="bullets">Automate your influencer marketing</li>
                              </ul>
                        </div>
                  </div>
            </div>

            
            <div class=" feature-block-floater">
                  <div class="feature-block-floater3"></div>
            </div>
      
            <div class="feature-block-fwdth-left">
                  <div class="content">
                        <div class="feature-block-text">
                              <b class="headline">Create saved lists</b>
                              <ul class="bullets">
                                    <li class="bullets">Unlimited favorite lists</li>
                                    <li class="bullets">Organize your rosters</li>
                                    <li class="bullets">Monitor prospects</li>
                              </ul> 
                        </div>
                        <div class="feature-image">
                              <img class="feature-image-description-icon" alt="" src="images/homepage-features-lists.jpg" />
                        </div>
                  </div>
            </div>

            <div class=" feature-block-floater">
                  <div class="feature-block-floater4"></div>
            </div>

            <div class="feature-block-fwdth-center-bottom">
                  <div class="feature-block-headine-bottom">Low cost and simple to use</div>
                  <div class="feature-block-subtitle-bottom">Other tools are expensive and complicated. Crowdscore saves you time and helps you maximize your influencer marketing ROI.<p></p></div>
                  <div v-if="!isSubscribed">
                        <router-link to="/pricing">
                              <div class="button-primary-style3">
                                    <div class="button-text">Unlock Full Access</div>                                
                              </div>
                        </router-link>
                  </div>
            </div>

            <div class="lowercontent-background"></div>
      
      </div>

</template>

<script>

import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  
      name: 'HomeFeatures',

      setup() {
      const store = useStore(); // Access Vuex store
      const isSubscribed = computed(() => store.state.isSubscribed); // Use Vuex for isSubscribed
      
      return {
            isSubscribed,
      };
      },

}

</script>