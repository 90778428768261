<template>
  <div class="db-body">

    <!-- TaskPanel with Conditional Prop -->
    <div class="db-taskpanel-default">
      <TaskPanel v-if="!isMobile" @search="fetchSearchResults" @categorySearch="fetchCategorySearchResults" @vibeSearch="fetchVibeSearchResults" @listSearch="fetchListSearchResults"/>
      <TaskPanel v-else :activate-lists-tab="activateListsTab" @search="fetchSearchResults" @categorySearch="fetchCategorySearchResults" @vibeSearch="fetchVibeSearchResults" @listSearch="fetchListSearchResults" /> 
    </div>
    <div class="db-content" v-if="$route.path === '/top100'">
      <DashboardDefault v-if="!isMobile" />
      <DashboardDefaultM v-else />
    </div>
    <div class="db-content" v-if="$route.path === '/mylists'">
      <Mylists  />
    </div>
    <div class="db-content" v-if="$route.path === '/dashboard'|| $route.name === 'UserProfile'">
      <DashboardContent :searchInput="searchInput" :username="localUsername" :categoryId="categoryId" :vibeId="vibeId" :currentListName="currentListName" :profiles="profiles" @vibeSearch="fetchVibeSearchResults" />
    </div>
  </div>  
</template>

<script>
import TaskPanel from './Dashboard/TaskPanel.vue'; // Import the Task Panel component
import DashboardContent from './Dashboard/DashboardContent.vue'; // Import the Dashboard Content component
import DashboardDefault from './Dashboard/DashboardDefault.vue';
import DashboardDefaultM from './Dashboard/DashboardDefaultM.vue';
import Mylists from './Dashboard/MyLists.vue';

export default {
  components: {
    TaskPanel,
    DashboardContent,
    DashboardDefault,
    DashboardDefaultM,
    Mylists
  },
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchInput: '', // This will hold the last search input
      profiles: [], // Add this line to define profiles
      currentListName: '',
      categoryId: '',
      listId: '',
      vibeId: '',
      contentKey: 0,
      windowWidth: window.innerWidth,
      localUsername: this.username
    };
  },
  
  computed: {
    isMobile() {
      return this.windowWidth <= 600;
    }
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  this.localUsername = this.username;

  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },

  watch: {
    '$route'(newRoute) {
      if (newRoute.params.username) {
        this.localUsername = newRoute.params.username;
      }

      console.log("watch:", newRoute.query);
    },

    searchInput(newVal, oldVal) {
    console.log('searchInput changed from', oldVal, 'to', newVal);
  },
  
  },



  methods: {

    fetchSearchResults(searchInput) {
   //   console.log("DashboardHome received search input:", searchInput); // Add this line
      // Updates the searchInput with the value emitted from TaskPanel
      this.searchInput = searchInput;
      this.contentKey++;
      sessionStorage.setItem('onsite', 'true');
      
    },
    fetchCategorySearchResults(categoryId) {
 //     console.log("DashboardHome received category id:", categoryId);
      this.searchInput = `c=${categoryId}`;
      this.contentKey++;
      sessionStorage.setItem('onsite', 'true');
    },
    fetchVibeSearchResults(vibeId) {
 //     console.log("DashboardHome received vibe id:", vibeId);
      this.searchInput = `v=${vibeId}`;
      this.contentKey++;
      sessionStorage.setItem('onsite', 'true');
    },
    fetchListSearchResults(listId, listName) {
 //     console.log("DashboardHome received list name:", listName);
      this.searchInput = `l=${listId}`;
      this.currentListName = listName;
      this.contentKey++;
      sessionStorage.setItem('onsite', 'true');
    },

    removePrefix(searchInput) {
      return searchInput.replace(/^(c=|v=)/, '');
  },
  },


  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Access query parameters directly from 'to' route
      const query = to.query;
      if (query.search) {
        vm.fetchSearchResults(query.search);
      } else if (query.vibe) {
        vm.fetchVibeSearchResults(query.vibe);
      } else if (query.category) {
        vm.fetchCategorySearchResults(query.category);
      } else if (query.list) {
        vm.fetchListSearchResults(query.list);
      }
      else if (to.params.username) {
        vm.localUsername = to.params.username;
      }

    });
  },

  beforeRouteUpdate(to, from, next) {
    const query = to.query;
      if (query.search) {
        this.fetchSearchResults(query.search);
      } else if (query.vibe) {
        this.fetchVibeSearchResults(query.vibe);
      } else if (query.category) {
        this.fetchCategorySearchResults(query.category);
      } else if (query.list) {
        this.fetchListSearchResults(query.list);
      } else if (to.params.username) {
      this.localUsername = to.params.username;
    }
  next();
},
/*
  watch:{
    $route(to) {
      const query = to.query;
      if (query.search) {
        this.fetchSearchResults(query.search);
      } else if (query.vibe) {
        this.fetchVibeSearchResults(query.vibe);
      } else if (query.category) {
        this.fetchCategorySearchResults(query.category);
      } else if (query.list) {
        this.fetchListSearchResults(query.list);
      }
    }
  },
*/

  
  mounted() {

  const query = this.$route.query;

  if (query.search) {
    localStorage.setItem('search', query.search);
    localStorage.setItem('searchType', 'keyword');
  } 
  else if (query.vibe) {
    localStorage.setItem('vibe', query.vibe);
    localStorage.setItem('searchType', 'vibe');
  } 
  else if (query.category) {
    localStorage.setItem('category', query.category);
    localStorage.setItem('searchType', 'category');
  } 
  else if (query.list) {
    localStorage.setItem('list', query.list);
    localStorage.setItem('searchType', 'list');
    this.fetchListSearchResults(query.list);
  }  
  else if (query.top100) {
    localStorage.setItem('top100', query.top100);
    localStorage.setItem('searchType', 'top100');
  }
  if (this.$route.params.username) {
      this.localUsername = this.$route.params.username;
    }  
}
}
</script>