<template>
    <div class="db-default-container">

        <div class="db-default-container-chart">

            <div class="db-default-chart-heading">
                <div class="db-default-heading">Top 100 trending creators</div>
                <select v-model="activeSection" @change="setActiveSection(activeSection)" class="db-default-mobile-dropdown">
                    <option value="Engagement">Engagement</option>
                    <option value="Growth">Growth</option>
                    <option value="Followers">Followers</option>
                </select>
            </div>

            <div class="db-chart-container" ref="scrollableDiv">

                <div class="db-chartgroup-container" v-for="(user, index) in mockUsers" :key="user.id" 
                    :class="{ 'odd-row': index % 2 !== 0,
                        'unsubscribed-blur': !isSubscribed && index >= 5,
                                'unsubscribed-opacity': !isSubscribed && index >= 5   
                    }" 
                    @click="handleChartGroupClick(index, user.username)">
                    <div class="db-chartgroup-rank">
                        <div :class="['db-chart-rank', getRankColor(user.rank)]">{{ user.rank }}</div>
                        <div class="db-chart-avatar">
                            <img :src="user.profile_image || '/images/avatar-placeholder.jpg'" alt="Profile Image">
                        </div>
                        <div class="db-chart-namegroup">
                            <div class="db-chart-name">{{ user.name }}</div>
                            <div class="db-chart-handle">@{{ user.username }}</div>
                        </div>
                        
                        
                        <div :class="statClasses['movement']" class="db-chartgroup-stats-movement">
                            <span v-if="isNaN(user.movement) || user.movement === 0" class="null-number">-</span>
                            <span v-else :class="numberClass(user.movement)">
                                <span v-if="user.movement > 0">
                                    <span class="movement-arrow-up">&#9650;</span>&thinsp;{{ formatMovement(user.movement) }}
                                </span>
                                <span v-else-if="user.movement < 0">
                                    <span class="movement-arrow-down">&#9660;</span>&thinsp;{{ formatMovement(user.movement) }}
                                </span>
                                <span v-else>{{ formatMovement(user.movement) }}</span>
                            </span>
                        </div>
                    
                    
                    </div>
                    <div class="db-chartgroup-stats">
                        <div v-if="activeSection === 'Engagement'" :class="statClasses['engagement']" class="db-chartgroup-stats-engagement">{{ user.engagement }}%</div>
                        <div v-if="activeSection === 'Growth'" :class="statClasses['growth'](user.growth)" class="db-chartgroup-stats-growth">{{ user.growth != null ? user.growth + '%' : '-' }}</div>
                        <div v-if="activeSection === 'Followers'" :class="statClasses['followers']" class="db-chartgroup-stats-followers">{{ formatNumber(user.followers) }}</div>                        
                    </div>
                </div>

            </div>

        </div>
           
    </div>

    <div v-if="showSubscribeModal" class="alertmodal">
        <div class="alertmodal-content">
            <div class="alertmodal-heading">This is a subscriber feature</div>
            <div class="alertmodal-actions">
                <router-link to="/pricing" class="alertmodal-submit">Subscribe</router-link>
                <div @click="showSubscribeModal = false" class="alertmodal-cancel">OK</div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {

    name: 'DashboardDefaultM',

    data() {
        return {
            activeSection: '',
            mockUsers: [],
            profiles: [],
            showSubscribeModal: false,
            // Base URL for the DigitalOcean Spaces bucket
            spacesBaseUrl: 'https://cdn-crowdscore.nyc3.cdn.digitaloceanspaces.com/',
            apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
        };

    },

    watch: {
        '$route'(to) {
            if (to.path === '/top100') {
                this.setActiveSection('Engagement');
                this.fetchTop100Creators();
            }
        }
    },





    methods: {
        
        handleChartGroupClick(index, username) {
            if (index >= 5 && !this.isSubscribed) {
                this.toggleSubscribeModal();
            } else {
                this.handleProfileClick(username);
            }
        },

        toggleSubscribeModal() {
            this.showSubscribeModal = !this.showSubscribeModal;
        },
        
        handleScroll() {
            const scrollableDiv = this.$refs.scrollableDiv;
            if (scrollableDiv) {
                //console.log('Current scroll position:', scrollableDiv.scrollTop);
            }
        },

        formatMovement(movement) {
            return isNaN(movement) ? 0 : Math.abs(movement);
        },

        numberClass(movement) {
            return movement >= 0 ? 'positive-number' : 'negative-number';
        },

        isNaN(value) {
            return Number.isNaN(value);
        },

        async fetchTop100Creators() {
            try {
                const response = await axios.get(`${this.apiEndpoint}/top-100?s=${this.activeSection.toLowerCase()}`);

                // Assuming spacesBaseUrl is defined in data or as a computed property
                const baseUrl = this.spacesBaseUrl;
                this.mockUsers = response.data.map(creator => ({
                    id: creator.id,
                    rank: creator.rank,
                    name: creator.name,
                    username: creator.username,
                    // Prepend the base URL to the profile_image path
                    profile_image: creator.profile_image ? `${baseUrl}${creator.profile_image}` : '/images/avatar-placeholder.jpg',
                    engagement: creator.engagement,
                    growth: creator.growth,
                    followers: creator.followers,
                    movement: creator.movement != null ? parseFloat(creator.movement) : 0,
                }));

                this.$nextTick(() => {
            this.restoreScrollPosition();
        });
            } catch (error) {
                console.error('Error fetching top 100 creators:', error);
            }
        },
        setActiveSection(section) {
            this.activeSection = section;
            sessionStorage.setItem('activeSection', section); // Save to session storage
            sessionStorage.removeItem('top100ScrollPosition');
            this.fetchTop100Creators();
        },

        getRankColor(rank) {
            switch (rank) {
                case 1: return 'chart-position1';    // color for rank 1
                case 2: return 'chart-position2';  // color for rank 2
                case 3: return 'chart-position3';  // color for rank 3
                default: return 'chart-position4+'; // Default color for other ranks
            }
        },

        formatNumber(num) {
            if (num >= 1000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000) {
                return (num / 1000).toFixed(1) + 'K';
            } else {
                return num;
            }
        },

        // Method to construct the full image URL
        getImageUrl(relativePath) {
            return `${this.spacesBaseUrl}${relativePath}`;
        },

        handleProfileClick(username) {
            this.$router.push({ path: '/dashboard', query: { username: username } });
            this.saveScrollPosition();
        },

        saveScrollPosition() {
        const scrollableDiv = this.$refs.scrollableDiv;
        if (scrollableDiv) {
            sessionStorage.setItem('top100ScrollPosition', scrollableDiv.scrollTop);
            //console.log('Attempting to save scroll position:', scrollableDiv.scrollTop);
        }
    },

    restoreScrollPosition() {
        this.$nextTick(() => {
            const savedPosition = sessionStorage.getItem('top100ScrollPosition');
            //console.log('Attempting to restore scroll position:', savedPosition);
            if (savedPosition) {
                const scrollableDiv = this.$refs.scrollableDiv;
                if (scrollableDiv) {
                    scrollableDiv.scrollTop = Number(savedPosition);
                    //console.log('Restored scroll position to:', savedPosition);
                    //sessionStorage.removeItem('top100ScrollPosition'); // Clean up the saved position
                }
            }
        });
    },

        attachScrollListener() {
            this.$nextTick(() => {
                const scrollableDiv = this.$refs.scrollableDiv;
                if (scrollableDiv) {
                    //  console.log('Found scrollable div, attaching listener.');
                    scrollableDiv.removeEventListener('scroll', this.handleScroll);
                    scrollableDiv.addEventListener('scroll', this.handleScroll);
                } else {
                    //  console.error('Scrollable div not found, retrying...');
                    setTimeout(() => this.attachScrollListener(), 500); // Retry after some delay
                }
            });
        },
    },

    mounted() {
        this.attachScrollListener();
        this.restoreScrollPosition();
        // Attempt to restore the activeSection from sessionStorage
        const savedSection = sessionStorage.getItem('activeSection');
        if (savedSection) {
            this.activeSection = savedSection;
        } else {
            // If there's no saved section, default to 'Engagement' or any other default
            this.activeSection = 'Engagement';
            sessionStorage.setItem('activeSection', 'Engagement'); // Optional: Save the default to session
        }
        this.fetchTop100Creators();
},
beforeUnmount() {
    const scrollableDiv = this.$refs.scrollableDiv;
    if (scrollableDiv) {
      scrollableDiv.removeEventListener('scroll', this.handleScroll);
    }
},

    created() {
        this.fetchTop100Creators();
    },

    computed: {

        ...mapState(['isSubscribed', 'lastRoutePath']),

        statClasses() {
            return {
                engagement: {
                    'db-chartgroup-stats-engagement-selected': this.activeSection === 'Engagement',
                },
                growth: (userGrowth) => ({
                    'db-chartgroup-stats-growth-selected': this.activeSection === 'Growth',
                    'db-chartgroup-stats-growth-negative': userGrowth < 0,
                }),
                followers: {
                    'db-chartgroup-stats-followers-selected': this.activeSection === 'Followers',
                },
                movement: {
                    'db-chartgroup-stats-movement-selected': this.activeSection === 'Movement',
                },
            };
        },
    },

};

</script>