<template>
  
  

  <div class="guestsearch">
    <div class="texttitles">
      <div class="searchtitle">Top Rising Stars</div>
      <div v-if="isSubscribed"> 
        <router-link to="/top100" class="router-link"><div class="see-more-link">See more</div></router-link></div>
      <div v-else> 
        <router-link to="/pricing" class="router-link"><div class="see-more-link">See more</div></router-link></div>
    </div>

    <div v-if="isLoading" class="searchgrid">
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
      <div class="profile-card-loading"></div>
    </div>
    
    <div v-else class="searchgrid">
      <div class="profile-card" v-for="profile in profiles" :key="profile.ig_id" @click="handleProfileClick(profile.ig_username)" style="cursor: pointer;">
        <div class="creator-avatar">
          <!-- Image with overlaying content -->
          <img class="creator-info-avatar-displaynam-icon" :alt="profile.ig_full_name" :src="getImageUrl(profile.ig_profile_image_path)" />
          <div class="overlay-content">
            <div class="badge">{{ profile.ig_category }}</div>
            <div class="user-info">
              <h3 class="username">@{{ profile.ig_username }}</h3>
              <p class="followers">{{ formatNumber(profile.ig_followers) }} followers</p>
            </div>
            <!-- Instagram icon -->
            <div class="instagram-icon"> <img class="Instagram_Glyph_White" alt="Instagram Logo" src="images/Instagram_Glyph_White.png" /></div>
          </div>
        </div>
        <!-- <div class="profile-info">
          <p>Score: {{ profile.ig_score }}</p>
          <p>Cost Per Post: {{ profile.ig_cpp }}</p>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'HomeResults',
  data() {
    return {
      profiles: [],
      isLoading: true,
      // Base URL for the DigitalOcean Spaces bucket
      spacesBaseUrl: 'https://cdn-crowdscore.nyc3.cdn.digitaloceanspaces.com/',
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    };
  },

  setup() {
      const store = useStore(); // Access Vuex store
      const isSubscribed = computed(() => store.state.isSubscribed); // Use Vuex for isSubscribed
      
      return {
            isSubscribed,
      };
    },

  methods: {
    
    handleLoadingStateChanged(isLoading) {
      this.isLoading = isLoading;
    },
    
    formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num;
    }
},
    
    // Method to construct the full image URL
    getImageUrl(relativePath) {
      return `${this.spacesBaseUrl}${relativePath}`;
    },
      handleProfileClick(username) {
      //const usernameWithAt = '@' + username;
      //console.log(`Profile card clicked: ${usernameWithAt}`);
      //this.$router.push({ path: '/dashboard', query: { username: username } });
      this.$router.push({ path: `/${username}` });
      //this.$emit('profile-clicked', usernameWithAt);
    },
    // Fetch profiles from the API
    async fetchProfiles() {
      try {
        console.log('Endpoint:', this.apiEndpoint);
        const gridResponse = await axios.get(`${this.apiEndpoint}/home-grid`);
        const accountIds = gridResponse.data.map(item => item.id);

        const profilePromises = accountIds.map(id => axios.get(`${this.apiEndpoint}/profile-card/${id}`));
        const profileResponses = await Promise.all(profilePromises);

        this.profiles = profileResponses.map(response => {
          // Convert the array of key-value pairs to an object
          return response.data.reduce((obj, item) => {
            const key = Object.keys(item)[0]; // Get the key name
            obj[key] = item[key]; // Assign value to the key in obj
            return obj;
          }, {});
        });
        this.isLoading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  },
  created() {
    this.fetchProfiles();
  }
}
</script>