import { createStore } from 'vuex';

export default createStore({
  state: {
    isSubscribed: false,
    email: '', // Add this line
    brandId: null,
    lastRoutePath: '',
  },
  mutations: {
    setIsSubscribed(state, value) {
      state.isSubscribed = value;
    },
    setEmail(state, value) { // Add this mutation
      state.email = value;
    },
    setBrandId(state, value) { // Mutation to set brandId
      state.brandId = value;
    },
    updateLastRoutePath(state, path) { 
      state.lastRoutePath = path;
    },

    resetState(state) {
      state.isSubscribed = false;
      state.email = '';
      state.brandId = null; 
      state.lastRoutePath = '';
    },
  },
  actions: {
    updateIsSubscribed({ commit }, value) {
      commit('setIsSubscribed', value);
    },
    updateEmail({ commit }, value) {
      return new Promise((resolve) => {
        commit('setEmail', value);
        resolve();
      });
    },
    updateBrandId({ commit }, value) { // Action to update brandId
      commit('setBrandId', value);
    },
    logout({ commit }) {
      commit('resetState');
    },
  },
  getters: {
    isSubscribed: (state) => state.isSubscribed,
    email: (state) => state.email,
    brandId: (state) => state.brandId,
  },
});