<template>
      <div :class="['task-panel', { 'visible': isTaskPanelVisible }]">

            <div class="db-taskpanel-container" ref="taskPanelDefault">
                  
                  <div class="taskpanel-tabs">
                        
                        <!-- Discover Tab -->

                        <div :class="{ 'taskpanel-tab-active': activeTab === 'discover', 'taskpanel-tab-inactive': activeTab !== 'discover' }"
                              @click="setActiveTab('discover')">
                              <div class="taskpanel-tab-frame">
                                    <div
                                          :class="activeTab === 'discover' ? 'taskpanel-tab-textframe' : 'taskpanel-tab-textframe-inactive'">
                                          <img src="/images/icon-discover.png" class="tab-icon" />
                                          <div class="taskpanel-tab-textframe-text">Search</div>
                                    </div>
                              </div>
                              <div
                                    :class="activeTab === 'discover' ? 'taskpanel-tab-active-base' : 'taskpanel-tab-inactive-base'">
                              </div>
                        </div>

                        <div class="taskpanel-tab-flex"></div>

                        <!-- History Tab -->
                        <div :class="{ 'taskpanel-tab-active': activeTab === 'history', 'taskpanel-tab-inactive': activeTab !== 'history' }"
                              @click="setActiveTab('history')">
                              <div class="taskpanel-tab-frame">
                                    <div
                                          :class="activeTab === 'history' ? 'taskpanel-tab-textframe' : 'taskpanel-tab-textframe-inactive'">
                                          <img src="/images/icon-history.png" class="tab-icon" />
                                          <div class="taskpanel-tab-textframe-text">History</div>
                                    </div>
                              </div>
                              <div
                                    :class="activeTab === 'history' ? 'taskpanel-tab-active-base' : 'taskpanel-tab-inactive-base'">
                              </div>
                        </div>

                        <div class="taskpanel-tab-flex"></div>

                        <!-- Lists Tab -->

                        <div :class="{ 'taskpanel-tab-active': activeTab === 'lists', 'taskpanel-tab-inactive': activeTab !== 'lists' }"
                              @click="setActiveTab('lists')">
                              <div class="taskpanel-tab-frame">
                                    <div
                                          :class="activeTab === 'lists' ? 'taskpanel-tab-textframe' : 'taskpanel-tab-textframe-inactive'">
                                          <img src="/images/icon-lists.png" class="tab-icon" />
                                          <div class="taskpanel-tab-textframe-text">Lists</div>
                                    </div>
                              </div>
                              <div
                                    :class="activeTab === 'lists' ? 'taskpanel-tab-active-base' : 'taskpanel-tab-inactive-base'">
                              </div>
                        </div>
                  </div>


                  <div class="taskpanel-content-container">

                        <!-- -------------------------------- Discover Content -------------------------------------- -->


                        <div v-if="activeTab === 'discover'" class="db-taskpanel-default">

                              <div class="task-input">
                                    <div class="task-inputfield" ref="taskInputField">
                                          <label class="input-placeholder"
                                                v-show="!textAreaValue.trim().length && showPlaceholder">Search by @username, keyword or just tell us what 
                                                you&apos;re looking
                                                for…</label>
                                          <textarea :ref="'editInput' + index" class="task-input-text" v-model="textAreaValue"
                                                spellcheck="true" @input="handleInput" @keydown.enter="handleEnterKey"
                                                @mousedown="handleMouseDown" @focus="handleFocus" @blur="handleBlur"
                                                maxlength="200">
                                          </textarea>

                                          <div class="task-input-button" :class="{ disabled: !isInputFilled }"
                                                @click="submitTask" @touchstart="handleTap">
                                                <img class="task-input-button-icon" alt=""
                                                      src="/images/task-input-button.png" />
                                          </div>
                                    </div>
                              </div>

                              <div class="task-spacer"></div>

                              <div class="task-tabbed-content">

                                    <div class="task-tabs">

                                          <!-- Suggestions Tab -->

                                          <div :class="{ 'task-tab-active': activeSubTab === 'suggestions', 'task-tab-inactive': activeSubTab !== 'suggestions' }"
                                                @click="setActiveSubTab('suggestions')">
                                                <div
                                                      :class="{ 'task-tab-active-text': activeSubTab === 'suggestions', 'task-subtab-inactive-text': activeSubTab !== 'suggestions' }">
                                                      Suggested</div>
                                          </div>

                                          <!-- Vibes Tab -->
                                          <div :class="{ 'task-tab-active': activeSubTab === 'vibes', 'task-tab-inactive': activeSubTab !== 'vibes' }"
                                                @click="setActiveSubTab('vibes')">
                                                <div
                                                      :class="{ 'task-tab-active-text': activeSubTab === 'vibes', 'task-subtab-inactive-text': activeSubTab !== 'vibes' }">
                                                      Vibe</div>
                                          </div>

                                          <!-- Categories Tab -->
                                          <div :class="{ 'task-tab-active': activeSubTab === 'categories', 'task-tab-inactive': activeSubTab !== 'categories' }"
                                                @click="setActiveSubTab('categories')">
                                                <div
                                                      :class="{ 'task-tab-active-text': activeSubTab === 'categories', 'task-subtab-inactive-text': activeSubTab !== 'categories' }">
                                                      Category</div>
                                          </div>


                                          <div class="task-tab-flexend"></div>
                                    </div>




                                    <div v-if="activeSubTab === 'suggestions'" class="task-suggestions-frame">
                                          <div class="suggestions-text-frame" v-for="prompt in suggestedPrompts"
                                                :key="prompt.id" @click="handleSuggestionClick(prompt.prompt)">
                                                <div class="suggestions-text">{{ prompt.prompt }}</div>
                                          </div>
                                    </div>


                                    <div v-if="activeSubTab === 'vibes'" class="task-vibes-frame">
                                          <div class="vibes-text-frame" v-for="vibe in vibes" :key="vibe.tag"
                                                @click="handleVibeClick(vibe.tag)">
                                                <div class="vibes-text">#{{ vibe.tag }}</div>
                                          </div>
                                    </div>

                                    <div v-if="activeSubTab === 'categories'" class="task-categories-frame">

                                          <div class="task-tabs-grid-container">
                                                <div class="category-text-frame" v-for="category in categories"
                                                      :key="category.name" @click="handleCategoryClick(category.name)">
                                                      <b class="category-text">{{ category.name }}</b>
                                                </div>
                                          </div>

                                    </div>

                              </div>

                        </div>


                        <!-- History Content -->
                        <div v-if="activeTab === 'history'" class="db-taskpanel-default">
                              
                              <div v-if="isSubscribed" class="history-content-container">
                                    
                                    <div class="clear-history" @click="clearSearchHistory">Clear Search History</div>
                                    
                                    <div class="history-content">
                                          
                                          <div v-if="categorizedSearches.Today.length" class="history-section">
                                                <div class="history-heading">Today</div>
                                                <div class="history-item" v-for="search in categorizedSearches.Today" :key="search.id" @click="handleSearchClick(search.search_term)">
                                                      <div class="history-item-text">{{ search.search_term }}</div>
                                                </div>
                                          </div>
                                          
                                          <div v-if="categorizedSearches.Yesterday.length" class="history-section">
                                                <div class="history-heading">Yesterday</div>
                                                <div class="history-item" v-for="search in categorizedSearches.Yesterday" :key="search.id" @click="handleSearchClick(search.search_term)">
                                                      <div class="history-item-text">{{ search.search_term }}</div>
                                                </div>
                                          </div>
                                          
                                          <div v-if="categorizedSearches.ThisMonth.length" class="history-section">
                                                <div class="history-heading">This Month</div>
                                                <div class="history-item" v-for="search in categorizedSearches.ThisMonth" :key="search.id" @click="handleSearchClick(search.search_term)">
                                                      <div class="history-item-text">{{ search.search_term }}</div>
                                                </div>
                                          </div>
                                          
                                          <div v-for="(searches, monthYear) in categorizedSearches.PreviousMonths" :key="monthYear" class="history-section">
                                                <div class="history-heading">{{ monthYear }}</div>
                                                <div class="history-item" v-for="search in searches" :key="search.id" @click="handleSearchClick(search.search_term)">
                                                      <div class="history-item-text">{{ search.search_term }}</div>
                                                </div>
                                          </div>

                                    </div>

                              </div>

                              <div v-else class="history-content-container-guest">
                                    <div class="history-content-guest">
                                          <router-link to="/pricing">
                                                <div class="signup-message"><span class="inline-text-link">Upgrade</span> to see your search history!</div>
                                          </router-link>
                                    </div>
                              </div>

                        </div>

                  


                        <!-- -------------------------------- List Content -------------------------------------- -->

                        <div v-if="activeTab === 'lists'" class="db-taskpanel-default">

                              <div class="new-list-button">
                                    <div class="new-list-container" @click="openNewListModalOrSubscribe"> <img
                                                src="/images/icon-newlist.png" alt="New List"> New List</div>
                              </div>

                              <div v-if="!isMobile" class="lists-content-container">
                                    <div class="lists-content">
                                          <transition-group name="list" class="text-based-list" tag="div">
                                                <div v-for="list in savedLists" :key="list.list_id" class="list-item"
                                                      @click="handleListClick(list.list_id)"
                                                      :class="{ 'editing': list.isEditing }">
                                                      <div v-if="!list.isEditing" class="list-content">
                                                            <a href="#" class="list-link">{{ list.name }}</a>
                                                            <div class="list-actions">
                                                                  <div class="list-edit" @click.stop="enableEditing(list)">
                                                                        <img src="/images/icon-edit-textinput.png"
                                                                              alt="Edit List"></div>
                                                                  <div class="list-delete" @click.stop="confirmDelete(list)">
                                                                        <img src="/images/icon-delete-result.png"
                                                                              alt="Delete List">
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div v-else class="list-edit-container">
                                                            <!-- Editing input field -->
                                                            <input v-model="list.editText" class="list-edit-input"
                                                                  :ref="'editInput' + savedLists.indexOf(list)" @click.stop=""
                                                                  @keyup.enter.prevent.stop="submitEdit(list)">
                                                            <div class="list-edit-actions">
                                                                  <div class="list-edit-submit"
                                                                        @click.stop="submitEdit(list)"> <img
                                                                              src="/images/icon-editlist-submit.png"
                                                                              alt="Submit Edit"></div>
                                                                  <!-- <div class="list-edit-cancel" @click="cancelEdit(item)"> <img src="/images/icon-editlist-cancel.png" alt="Cancel Edit"></div> -->
                                                            </div>
                                                      </div>
                                                </div>
                                          </transition-group>
                                    </div>
                              </div>

                              <div v-else class="lists-content-container">
                                    <div class="lists-content">
                                          <transition-group name="list" class="text-based-list">
                                                <div v-for="list in savedLists" :key="list.list_id" class="list-item-mobile"
                                                      @click="handleListClick(list.list_id)">
                                                      <a href="#" class="list-link">{{ list.name }}</a>
                                                </div>
                                          </transition-group>
                                    </div>
                              </div>

                              <div v-if="showNewListModal" class="alertmodal">
                                    <div class="alertmodal-content">
                                          <div class="alertmodal-heading">Create a new list</div>
                                          <input class="alertmodal-input" type="text" v-model="newListName"
                                                placeholder="Enter a list name…" @focus="newListError = ''">
                                          <div class="error-message3" v-if="newListError">{{ newListError }}</div>
                                          <div class="alertmodal-actions">
                                                <div @click="addNewList" class="alertmodal-submit">Save</div>
                                                <div @click="closeNewListModal" class="alertmodal-cancel">Cancel</div>
                                          </div>
                                    </div>
                              </div>

                              <div v-if="showDeleteModal" class="alertmodal">
                                    <div class="alertmodal-content">
                                          <!-- Display the confirmation message with the item's text -->
                                          <div class="alertmodal-heading">Are you sure you want to delete {{
                                                currentItemToDelete.name }}?
                                          </div>
                                          <div class="alertmodal-actions">
                                                <div @click="deleteItem" class="alertmodal-submit-destructive">Delete</div>
                                                <div @click="showDeleteModal = false" class="alertmodal-cancel">Cancel</div>
                                          </div>
                                    </div>
                              </div>

                              <div v-if="showSubscribeModal" class="alertmodal">
                                    <div class="alertmodal-content">
                                          <div class="alertmodal-heading">This is a subscriber feature</div>
                                          <div class="alertmodal-actions">
                                                <router-link to="/pricing" class="alertmodal-submit">Subscribe</router-link>
                                                <div @click="showSubscribeModal = false" class="alertmodal-cancel">OK</div>
                                          </div>
                                    </div>
                              </div>

                        </div>

                  </div>

                  <div class="taskpanel-base">

                        <router-link to="/pricing" class="taskpanel-banner-container" v-if="!isSubscribed"> <img
                              class="taskpanel-banner" alt="Upgrade to Premium"
                              src="/images/upgrade-to-premium.png" />
                        </router-link>

                        <div class="taskpanel-base-link-group">
                              <span>© 2024 Crowdscore Inc.&nbsp;&nbsp;&nbsp;</span>
                              <span class="taskpanel-base-link"> <router-link to="/investors">Investors</router-link>&nbsp;&nbsp;&nbsp;</span>
                              <span class="taskpanel-base-link"> <router-link to="/privacy">Privacy</router-link>&nbsp;&nbsp;&nbsp;</span>
                              <span class="taskpanel-base-link"> <router-link to="/terms">Terms</router-link></span>
                        </div>

                  </div>

            </div>

      </div>

</template>

<script>
import axios from 'axios';
import { eventBus } from '@/eventBus';
import { inject, computed } from 'vue';
import { useStore, mapState } from 'vuex';

export default {

      name: 'TaskPanel',

      props: {
            isTaskPanelVisible: Boolean
      },

      setup() {
            const globalState = inject('globalState'); // Inject the global state
            const store = useStore(); // Vuex store for state management
            const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed

            return {
                  globalState, // For isLoggedIn
                  isSubscribed,
            };
      },

      // ---------------------------------------------------------------------------
      // Data
      // ---------------------------------------------------------------------------

      data() {
            return {
                  isInputFilled: false,
                  searchInput: '',
                  categories: [],
                  activeTab: 'discover',
                  selectedSuggestion: '',
                  activeSubTab: 'suggestions',
                  /* activeDropdown: null,
                  isDropdownActive: false,*/
                  showNewListModal: false,
                  newListName: '',
                  suggestedPrompts: [],
                  vibes: [],
                  showDeleteModal: false,
                  currentItemToDelete: null,
                  savedLists: [],
                  newListError: '',
                  showSubscribeModal: false,
                  showPlaceholder: true,
                  textAreaValue: '',
                  notSuggestedSearch: false,
                  apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
                  savedSearches: [],
                  windowWidth: window.innerWidth,
                  userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC', // Fallback to 'UTC'
            };
      },

      created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
      },

      unmounted() {
            window.removeEventListener('resize', this.handleResize);
      },

      // ---------------------------------------------------------------------------
      // Watchers
      // ---------------------------------------------------------------------------

      watch: {
            'savedLists': {
                  handler(newSavedLists) {
                        newSavedLists.forEach((list, index) => {
                              if (list.focus && this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                                    this.$refs[`editInput${index}`][0].focus();
                              }
                        });
                  },
                  deep: true
            },

            textAreaValue(newValue) {
                  this.showPlaceholder = !newValue || !newValue.trim();
            },

            activeTab(newVal) {
                  if (newVal === 'history') {
                        this.fetchSavedSearches();
                  }
            },

      },


      // ---------------------------------------------------------------------------
      // Computed
      // ---------------------------------------------------------------------------

      ...mapState(['isSubscribed', 'lastRoutePath']),

      isSubscribed() {
            // Replace with actual logic to determine if the user is subscribed
            return this.$store.getters.isSubscribed;
      },

      mounted() {


            if (this.$refs.taskPanelDefault) {
                  const taskPanel = this.$refs.taskPanelDefault;
                  taskPanel.addEventListener('wheel', this.preventScroll, { passive: false });
                  taskPanel.addEventListener('mousewheel', this.preventScroll, { passive: false });
            }

            // Really hacky way to get search working from top 100
            // This is a temporary solution until we can figure how to get the search to use the router

            // Store the search text from top 100
            const searchText = sessionStorage.getItem('searchText');
            const suggestedSearch = sessionStorage.getItem('suggestedSearch');

            console.log("searchText:", searchText);
            console.log("suggestedSearch:", suggestedSearch);


            if (window.location.pathname === '/dashboard' && (suggestedSearch !== searchText)) {

                  if (searchText) {
                        // Hide the placeholder if there's searchText in sessionStorage
                        this.showPlaceholder = false;
                        this.textAreaValue = searchText;
                        sessionStorage.removeItem('searchText');
                        sessionStorage.removeItem('suggestedSearch');
                        this.isInputFilled = true;
                  }

            }


            const taskPanel = this.$refs.taskPanelDefault;
            taskPanel.addEventListener('wheel', this.preventScroll, { passive: false });
            taskPanel.addEventListener('mousewheel', this.preventScroll, { passive: false });


            // Read the active tab state from localStorage and set it
            const savedActiveTab = localStorage.getItem('activeTab');
            if (savedActiveTab) {
                  this.activeTab = savedActiveTab;
            } else {
                  // Default to 'discover' tab if no saved state is found
                  this.activeTab = 'discover';
            }

            // Read the active tab state from localStorage and set it
            const savedActiveSubTab = localStorage.getItem('activeSubTab');
            if (savedActiveSubTab) {
                  this.activeSubTab = savedActiveSubTab;
            } else {
                  // Default to 'discover' tab if no saved state is found
                  this.activeSubTab = 'suggestions';
            }


            this.fetchCategories();
            this.fetchSuggestedPrompts();
            this.fetchVibes();
            this.fetchSavedLists();
            eventBus.on('list-updated', this.fetchSavedLists);

            // Attach event listeners to all suggestion elements
            const suggestionElements = document.querySelectorAll('.suggestions-text-frame .suggestions-text');
            if (suggestionElements.length) {
                  suggestionElements.forEach(element => {
                        element.addEventListener('click', this.handleSuggestionClick);
                  });
            }

            //My Lists event bus commented out until we change to stub page...

            //eventBus.on('my-lists-clicked', () => {
            //      this.setActiveTab('lists'); // Set the active tab to 'lists'
            //      if (this.savedLists && this.savedLists.length > 0) {
            //            const firstList = this.savedLists[0];
            //            this.handleListClick(firstList.list_id);
            //      } else {
            //            // Emit an event indicating no lists are available
            //            eventBus.emit('no-lists-available');
            //      }
            //});

            eventBus.on('set-active-tab', this.setActiveTabFromEvent);

      },


      beforeUnmount() {
            const taskPanel = this.$refs.taskPanelDefault;
            if (taskPanel) {
                  taskPanel.removeEventListener('wheel', this.preventScroll);
                  taskPanel.removeEventListener('mousewheel', this.preventScroll);
            }
            eventBus.off('list-updated', this.fetchSavedLists);
            /* window.removeEventListener('click', this.closeDropdownIfClickedOutside); */

            //  eventBus.off('my-lists-clicked', this.selectListsTab);

            eventBus.off('set-active-tab', this.setActiveTabFromEvent);

      },

      computed: {
            brandId() {
                  // Logic to retrieve brandId
                  //return 369; // Placeholder, replace with actual logic
                  return this.$store.getters.brandId;
            },

            isMobile() {
                  return this.windowWidth <= 600;
            },

            categorizedSearches() {
    const categorized = {
      Today: [],
      Yesterday: [],
      ThisMonth: [],
      PreviousMonths: {}
    };

    const now = new Date();
    const todayStr = this.formatDateAsLocalYYYYMMDD(now, this.userTimezone);
    const thisMonthStartStr = `${todayStr.slice(0, 7)}-01`;

    this.savedSearches.forEach(search => {
      const searchDate = new Date(search.timestamp);
      const searchDateStr = this.formatDateAsLocalYYYYMMDD(searchDate, this.userTimezone);

      if (searchDateStr === todayStr) {
        categorized.Today.push(search);
      } else if (this.isYesterday(searchDate, this.userTimezone)) {
        categorized.Yesterday.push(search);
      } else if (searchDateStr >= thisMonthStartStr) {
        categorized.ThisMonth.push(search);
      } else {
        const monthYear = this.getMonthYearString(searchDate, this.userTimezone);
        if (!categorized.PreviousMonths[monthYear]) {
          categorized.PreviousMonths[monthYear] = [];
        }
        categorized.PreviousMonths[monthYear].push(search);
      }
    });

    return categorized;
  }
},

      // ---------------------------------------------------------------------------
      // Methods
      // ---------------------------------------------------------------------------

      methods: {

            formatDateAsLocalYYYYMMDD(date, timeZone = 'UTC') {
    return new Date(date.toLocaleString('en-US', { timeZone }))
      .toISOString()
      .split('T')[0];
  },

  isSameDay(date1, date2, timeZone = 'UTC') {
    return this.formatDateAsLocalYYYYMMDD(date1, timeZone) === this.formatDateAsLocalYYYYMMDD(date2, timeZone);
  },

  isYesterday(date, timeZone = 'UTC') {
    // eslint-disable-next-line no-unused-vars
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return this.isSameDay(date, yesterday, timeZone);
  },

  getMonthYearString(date, timeZone = 'UTC') {
    return date.toLocaleString('en-US', { timeZone, month: 'long', year: 'numeric' });
  },


            async clearSearchHistory() {
                  console.log("Attempting to clear search history...");
                  try {
                        const response = await axios.post(`${this.apiEndpoint}/delete-searches`, {}, {
                              headers: {
                                    'Authorization': `Bearer ${this.getJwtToken()}` // Assuming getJwtToken() method correctly retrieves the JWT
                              }
                        });
                        if (response.status === 200) {
                              console.log("Search history cleared successfully");
                              this.savedSearches = []; 
                        }
                  } catch (error) {
                        console.error("Failed to clear search history:", error);
                  }
            },

            handleSearchClick(searchTerm) {
                  if (searchTerm.startsWith("Vibe:")) {
                        const vibeTag = searchTerm.replace("Vibe:", "").trim();
                        this.handleVibeClick(vibeTag);
                  } else if (searchTerm.startsWith("Category:")) {
                        const categoryName = searchTerm.replace("Category:", "").trim();
                        this.handleCategoryClick(categoryName);
                  } else {
                        this.handleSuggestionClick(searchTerm);
                  }
            },

            // ---------------------------------------------------------------------------
            // Event handlers
            // ---------------------------------------------------------------------------

            async fetchSavedLists() {
                  // Check if brandId is valid (i.e., not null or undefined)
                  if (!this.brandId) {
                        // console.log("Brand ID is not set. Skipping fetch for saved lists.");
                        return;
                  }
                  try {
                        const response = await axios.get(`${this.apiEndpoint}/saved-lists/${this.brandId}`);
                        if (response.status === 200) {
                              // console.log("Fetched saved lists:", response.data); 
                              this.savedLists = response.data;
                        }
                  } catch (error) {
                        //   console.error('Error fetching saved lists:', error);
                  }
            },

            selectListsTab() {
                  this.setActiveTab('lists');
            },

            setActiveTabFromEvent(tabName) {
                  this.activeTab = tabName;
            },

            openNewListModalOrSubscribe() {
                  if (this.$store.getters.isSubscribed) {
                        this.showNewListModal = true;
                  } else {
                        this.showSubscribeModal = true;
                  }
            },

            async addNewList() {


                  if (this.newListName.trim() === '') {
                        this.newListError = 'Please enter a name for the list.';
                        return;
                  }

                  // Check if the list name already exists in savedLists
                  const isListNameExist = this.savedLists.some(list => list.name.toLowerCase() === this.newListName.toLowerCase());
                  if (isListNameExist) {
                        this.newListError = 'List name already in use.';
                        return;
                  }

                  try {
                        const response = await axios.post(`${this.apiEndpoint}/create-list`, {
                              brand_id: this.brandId,
                              list_name: this.newListName
                        });

                        if (response.data && response.data.list_id) {
                              const newList = {
                                    list_id: response.data.list_id,
                                    name: this.newListName,
                                    // Assuming the list was created "now", for display purposes
                                    created_date: new Date().toISOString(),
                                    // Add any additional properties you expect for lists here
                              };

                              // Insert the new list at the beginning of the array to respect descending sort by created_date
                              this.savedLists.unshift(newList);

                              // Reset the modal state
                              this.newListName = '';
                              this.newListError = '';
                              this.showNewListModal = false;
                        } else {
                              throw new Error('List ID not returned from the server');
                        }
                  } catch (error) {
                        // console.error('Error creating new list:', error);
                        this.newListError = 'An unexpected error occurred while creating the list.';
                  }
            },

            // Method to close the modal and clear errors
            closeNewListModal() {
                  this.newListName = '';
                  this.newListError = '';
                  this.showNewListModal = false;
            },


            confirmDelete(list) {
                  //  console.log("Confirm delete list_id:", list.list_id);
                  this.currentItemToDelete = list;
                  this.showDeleteModal = true;
            },

            async deleteItem() {
                  const listId = this.currentItemToDelete.list_id;
                  //  console.log("Deleting list_id:", listId);

                  if (listId) {
                        try {
                              const response = await axios.post(`${this.apiEndpoint}/delete-list`, {
                                    brand_id: this.brandId,
                                    list_id: listId,
                              });

                              if (response.status === 200) {
                                    // Filter out the deleted list using list_id
                                    this.savedLists = this.savedLists.filter(list => list.list_id !== listId);
                                    // Reset the currentItemToDelete and hide the modal
                                    this.currentItemToDelete = null;
                                    this.showDeleteModal = false;
                              } else {
                                    // Handle non-successful responses
                                    //    console.error('Failed to delete the list:', response.status, response.statusText);
                                    // Optionally, show an error message to the user
                              }
                        } catch (error) {
                              //   console.error('Error deleting the list:', error);
                              // Handle error here, potentially show an error message to the user
                        }
                  } else {
                        //  console.error('Attempted to delete a list without a valid id.');
                        // Optionally, show an error message to the user
                  }
            },

            enableEditing(list) {
                  this.savedLists.forEach(l => {
                        l.isEditing = false; // Ensure only one item is editable at a time
                        l.focus = false; // Reset focus for all items
                  });
                  list.isEditing = true;
                  list.editText = list.name; // Assuming 'name' is the property holding the list name
                  this.$nextTick(() => {
                        const index = this.savedLists.indexOf(list);
                        if (this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                              this.$refs[`editInput${index}`][0].focus();
                        }
                  });
            },

            async submitEdit(list) {
                  try {
                        // Replace 'yourBrandIdHere' with the actual brand ID.
                        const brandId = this.brandId; // Assuming this.brandId is already defined and holds the current brand ID
                        const response = await axios.post(`${this.apiEndpoint}/update-list`, {
                              brand_id: brandId,
                              list_name: list.editText,
                              list_id: list.list_id
                        });

                        if (response.status === 200) {
                              // Update the list name in the UI only if the server update was successful
                              list.name = list.editText;
                              //  console.log("List name updated successfully:", response.data);
                        } else {
                              // Handle server responses other than 200 OK
                              //   console.error("Failed to update the list name:", response.status, response.statusText);
                              // Optionally, show an error message to the user
                        }
                  } catch (error) {
                        //   console.error('Error updating the list name:', error);
                        // Handle error, potentially show an error message to the user
                  }

                  // Exit editing mode
                  list.isEditing = false;
            },
            cancelEdit(list) {
                  list.isEditing = false; // Exit editing mode without saving changes
                  // No need to reset editText as it's no longer shown
            },


            handleSuggestionClick(search) {
                  localStorage.setItem('search', search);
                  localStorage.setItem('searchType', 'keyword');


                  this.$router.push({ path: '/dashboard?searchinput=', query: { search } })


                  this.selectedSuggestion = search;
                  this.performSearch();
                  this.$emit('suggestedSearch', search);
                  this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
                  sessionStorage.setItem('suggestedSearch', search);

                  if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                        window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(search)}`;
                  }

            },

            handleCategoryClick(categoryId) {
                  localStorage.setItem('category', categoryId);
                  localStorage.setItem('searchType', 'category');


                  this.$router.push({ path: '/dashboard', query: { category: categoryId } }).catch(err => console.error(err));


                  this.$emit('categorySearch', categoryId);
                  this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
                  if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                        window.location.href = `${window.location.origin}/dashboard?category=${encodeURIComponent(categoryId)}`;
                  }

            },
            handleVibeClick(vibeId) {
                  localStorage.setItem('vibe', vibeId);
                  localStorage.setItem('searchType', 'vibe');

                  this.$router.push({ path: '/dashboard', query: { vibe: vibeId } }).catch(err => console.error(err));


                  this.$emit('vibeSearch', vibeId);
                  this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
                  if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                        window.location.href = `${window.location.origin}/dashboard?vibe=${encodeURIComponent(vibeId)}`;
                  }

            },

            handleListClick(listId) {
                  localStorage.setItem('list', listId);
                  localStorage.setItem('searchType', 'list');

                  this.$router.push({ path: '/dashboard', query: { list: listId } }).catch(err => console.error(err));

                  const listName = this.savedLists.find(list => list.list_id === listId)?.name || 'Unknown List';
                  this.$emit('listSearch', listId, listName);
                  this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
                  localStorage.setItem('listname', listName);
                  if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                        window.location.href = `${window.location.origin}/dashboard?list=${encodeURIComponent(listId)}`;
                  }



            },

            async fetchSuggestedPrompts() {
                  try {
                        const response = await fetch(`${this.apiEndpoint}/suggested-prompts`);
                        if (!response.ok) {
                              throw new Error('Network response was not ok');
                        }
                        const prompts = await response.json();
                        this.suggestedPrompts = prompts;
                  } catch (error) {
                        //  console.error('Error fetching suggested prompts:', error);
                  }
            },

            handleEnterKey(event) {
                  event.preventDefault(); // Prevents the default line break in textarea
                  let sanitizedInput = this.textAreaValue.replace(/\n/g, " "); // Replace line breaks with spaces
                  this.performSearch(sanitizedInput); // Calls performSearch with the current input text
            },

            performSearch(search = this.selectedSuggestion) {
                  let query = search.trim();

                  if (query.length > 0) {

                        this.$router.push({ path: '/dashboard?searchinput=', query: { search } })

                        this.$emit('search', query);
                        this.$emit('search-performed');

                        if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                              window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(query)}`;
                              sessionStorage.setItem('searchText', query);
                        }
                  } else {
                        //console.error('Search input is empty.');
                  }
            },

            //This is a hack to use the keyboard's enter key method to trigger the touch event on mobile devices
            handleTap() {
                  // Call handleEnterKey with a dummy event object
                  this.handleEnterKey({ preventDefault: () => { } });
            },

            handleResize() {
                  this.windowWidth = window.innerWidth;
            },

            // ---------------------------------------------------------------------------
            // Tab section toggle
            // ---------------------------------------------------------------------------

            setActiveTab(tabName) {
                  this.activeTab = tabName;
                  localStorage.setItem('activeTab', tabName);

                  if (tabName === 'history') {
                        this.fetchSavedSearches();
                  }
            },

            async fetchSavedSearches() {
                  try {
                        const token = this.getJwtToken();
                        const response = await axios.get(`${this.apiEndpoint}/saved-searches`, {
                              headers: {
                                    'Authorization': `Bearer ${token}` // Implement getJwtToken method or replace with actual token
                              }
                        });
                        if (response.data) {
                              this.savedSearches = response.data;
                        }
                  } catch (error) {
                        console.error('Error fetching saved searches:', error);
                        // Handle errors (e.g., token expired, network issues, etc.)
                  }
            },

            getJwtToken() {
                  // Implement logic to retrieve JWT token stored in localStorage, Vuex store, or however you manage authentication state
                  return localStorage.getItem('jwtToken');
            },

            setActiveSubTab(subTabName) {
                  this.activeSubTab = subTabName;
                  localStorage.setItem('activeSubTab', subTabName);
            },

            // ---------------------------------------------------------------------------
            // Handle input
            // ---------------------------------------------------------------------------

            handleInput(event) {
                  this.searchInput = event.target.value; // Update the searchInput data property
                  this.isInputFilled = this.searchInput.length > 0;
                  const textarea = event.target;
                  textarea.style.height = 'auto';
                  textarea.style.height = `${textarea.scrollHeight}px`;

                  // Adjust task-inputfield height
                  const inputField = this.$refs.taskInputField;
                  inputField.style.height = 'auto';
                  inputField.style.height = event.target.scrollHeight + 'px';
            },
            handleFocus() {
                  const inputField = this.$refs.taskInputField;
                  inputField.classList.add('task-inputfield-focus-glow');
            },
            handleBlur() {
                  const inputField = this.$refs.taskInputField;
                  inputField.classList.remove('task-inputfield-focus-glow');
            },
            handleMouseDown(event) {
                  if (!this.isInputFilled) {
                        // Prevent default behavior
                        event.preventDefault();
                        // Focus the textarea
                        this.$nextTick(() => {
                              event.target.focus();
                              event.target.setSelectionRange(0, 0);
                        });
                  }
            },

            // ---------------------------------------------------------------------------
            // Fetch vibes
            // ---------------------------------------------------------------------------

            async fetchVibes() {
                  try {
                        const response = await fetch(`${this.apiEndpoint}/vibe-list`);
                        if (!response.ok) {
                              throw new Error('Network response was not ok');
                        }
                        const vibes = await response.json();
                        this.vibes = vibes;
                  } catch (error) {
                        //      console.error('Error fetching vibes:', error);
                  }
            },

            // ---------------------------------------------------------------------------
            // Fetch categories
            // ---------------------------------------------------------------------------

            async fetchCategories() {
                  try {
                        const response = await fetch(`${this.apiEndpoint}/category-list`);
                        if (!response.ok) {
                              throw new Error('Network response was not ok');
                        }
                        const allCategories = await response.json();
                        // Filter out 'Misc' and 'Celebrity' categories and limit to 18 items
                        this.categories = allCategories
                              .filter(category => category.name !== 'Misc' && category.name !== 'Celebrities')
                              .slice(0, 18);
                  } catch (error) {
                        //    console.error('Error fetching categories:', error);
                  }
            },

            // ---------------------------------------------------------------------------
            // Submit task
            // ---------------------------------------------------------------------------

            submitTask() {
                  let sanitizedInput = this.searchInput.replace(/\n/g, " "); // Replace line breaks with spaces
                  if (this.searchInput.length > 0) {
                        console.log("Emitting search input:", sanitizedInput); // Debug log
                        this.$emit('search', sanitizedInput);
                        this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile

                        if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                              window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(this.searchInput)}`;
                              sessionStorage.setItem('searchText', this.searchInput);
                        }



                  } else {
                        //    console.error('Search input is empty.');
                  }

                  // ---------------------------------------------------------------------------
                  // Lifecycle hooks
                  // ---------------------------------------------------------------------------

                  this.$nextTick(() => {
                        if (this.$refs.textarea) {
                              this.$refs.textarea.blur();
                        }
                  });

            }
      }

}
</script>
<style scoped>
.error-message3 {
      color: red;
      font-size: 10px;
      text-align: left;
      margin-top: -20px;
}

</style>