<template>
  <div id="app">
    <component :is="headerComponent" /> <!-- Use the dynamic header component -->

      <router-view v-if="$route.path === '/mylists'" :key="$route.fullPath" /> <!-- This is where the current route's component will be displayed -->
      <router-view v-else/>
    <component :is="footerComponent" /> <!-- Use the dynamic footer component -->
  </div>
</template>

<script>
import { computed, onMounted, provide, reactive } from 'vue';
import { useStore } from 'vuex';
import DashboardHeader from './components/DashboardHeader.vue';
import DashboardHeaderM from './components/DashboardHeaderM.vue';
import HomeHeader from './components/HomeHeader.vue';
import HomeHeaderM from './components/HomeHeaderM.vue';
import HomeFooter from './components/HomeFooter.vue';


export default {
  name: 'App',
  components: {
    DashboardHeader,
    DashboardHeaderM,
    HomeHeader,
    HomeHeaderM,
    HomeFooter
  },

  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.getElementById('app').style.display = 'block';
    });

    window.addEventListener('resize', this.onResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  setup() {
    const store = useStore();

    const globalState = reactive({

      isLoggedIn: false,

      updateLoginStatus: function(status, isSubscribed = false) {
        this.isLoggedIn = status;
        store.commit('setIsSubscribed', isSubscribed);
      }
    });

    // Computed property to reactively access isSubscribed from Vuex
    const isSubscribed = computed(() => store.state.isSubscribed);

    // Debugging: Watch for changes in isSubscribed and isLoggedIn
   // watch(isSubscribed, (newValue) => {
   // console.log(`isSubscribed changed to: ${newValue}`);
   // });
    
   // watch(() => globalState.isLoggedIn, (newValue) => {
   // console.log(`isLoggedIn changed to: ${newValue}`);
   // });

    // Debug: Log the initial value of isSubscribed
  //  console.log("Initial isSubscribed in App.vue:", isSubscribed.value);
  //  console.log("Initial isLoggedIn in App.vue:", globalState.isLoggedIn);

    // Provide the global state to all child components
    provide('globalState', globalState);

    // Decode JWT to update isSubscribed status
    onMounted(() => {
  //  console.log("isSubscribed in App.vue on mounted:", isSubscribed.value);
  //  console.log("isLoggedIn in App.vue on mounted:", globalState.isLoggedIn);
    
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    console.log("Access Token:", accessToken);
    console.log("Dashboard Token from localStorage:", accessToken);
    console.log("Refresh Token from localStorage:", refreshToken);

    const storedEmail = localStorage.getItem('userEmail');
    
    if (storedEmail) {
        store.dispatch('updateEmail', storedEmail);
      }
    if (accessToken) {
           // Check if the access token is in a valid JWT format
           const parts = accessToken.split('.');
        if (parts.length === 3 && parts.every(part => part.trim() !== '')) {
            try {
                const payload = JSON.parse(atob(parts[1]));
                console.log("test Decoded JWT Payload:", payload);
            //  console.log("isSubscribedValue:", payload.sub.is_subscribed);
                const isSubscribedValue = payload.sub && payload.sub.is_subscribed;
                const brandId = payload.sub.brand_id;
                globalState.updateLoginStatus(true, isSubscribedValue);
                store.commit('setBrandId', brandId);
                const storedEmail = localStorage.getItem('userEmail');
                if (storedEmail) {
                    store.dispatch('updateEmail', storedEmail);
                }
            } catch (error) {
               console.error("Error decoding access token:", error);
            }
        } else {
            console.error("Access token is not in a valid JWT format:", accessToken);
        }
    } else {
        console.log("No access token found in local storage.");
    }
    
  });



    return { globalState, isSubscribed };
  },

  computed: {

    isMobile() {
      return this.windowWidth < 600;
    },

    headerComponent() {
      

      // Check if current route is /tools
      if (this.$route.path === '/tools') {
        return null; // Do not show any header for /tools route
      }

    // Check screen size first
      if (this.isMobile) {
        // Then, decide based on the current route for mobile headers
        return this.$route.path === '/dashboard' || this.$route.path === '/top100' || this.$route.path === '/investors' || this.$route.path === '/mylists' || this.$route.path === '/search' || this.$route.path === '/settings'
           ? 'DashboardHeaderM' 
           : 'HomeHeaderM';
      } else {
        // For non-mobile screens, use the existing route-based logic
        return this.$route.path === '/dashboard' || this.$route.path === '/top100' || this.$route.path === '/investors' || this.$route.path === '/mylists' || this.$route.path === '/search' || this.$route.path === '/settings'
           ? 'DashboardHeader' 
           : 'HomeHeader';
      }
    },
    footerComponent() {
      // Check the current route's path to decide which footer to display
      if (this.$route.path.includes('/top100')) {
        return null;
      } else if (this.$route.path.includes('/dashboard')) {
        return null;
      } else if (this.$route.path.includes('/search')) {
        return null;
      } else if (this.$route.path.includes('/investors')) {
        return null;
      } else if (this.$route.path.includes('/mylists')) {
        return null;
      } else if (this.$route.path.includes('/tools')) {
        return null;
      } else {
        return HomeFooter;
      }
    }
  }
  
}
</script>