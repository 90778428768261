<template>
    
    <svg :width="isMainView ? '20vw' : '400'" :height="isMainView ? '16vw' : '350'" :viewBox="isMainView ? '0 50 400 350' : '0 -5 400 350'">
        
        <!-- Horizontal lines -->
        <line v-for="n in numberOfLines" :key="n" 
            :x1="0" :y1="getYPosition(n)" 
            :x2="width" :y2="getYPosition(n)" 
            :stroke="isMainView ? '#a36cc7' : '#dac4e8'" 
            :stroke-width="isMainView ? '5' : '0.75'" />
  
        <!-- Graph plot lines -->
        <polyline :points="linePoints" fill="none" stroke="#853bb4" :stroke-width="isMainView ? '5' : '1.75'" />

        <!-- Conditional group for non-mainView elements -->
        <g v-if="!isMainView">
            
            <!-- Data points -->
            <g v-for="(point, index) in points" :key="index">
                <circle :cx="point.x" :cy="point.y" :r="5" fill="#853bb4" />

                <!-- Value label for the data point -->
                <text :x="point.x" :y="point.y - 13" text-anchor="middle" class="label-stroke">
                    {{ formatLabel(mockData[index]) }}
                </text>
                <text :x="point.x" :y="point.y - 13" text-anchor="middle" class="label-fill">
                    {{ formatLabel(mockData[index]) }}
                </text>
            </g>
    
            <!-- Month labels -->
            <text v-for="(month, index) in months" 
                  :key="month" :x="getMonthXPosition(index)" 
                  :y="height - margin.bottom/2" text-anchor="middle">
                {{ month }}
            </text>

        </g>

        <!-- Conditional group for mainView-specific elements -->
        <g v-else>
            
            <!-- Draw the data points with increased radius -->
            <g v-for="(point, index) in points" :key="index">
                <circle :cx="point.x" :cy="point.y" :r="13" fill="#853bb4" />
            </g>
        </g>

    </svg>
    
</template>
  

<script>
import axios from 'axios';
    export default {
        
        props: {
            isMainView: {
                type: Boolean,
                default: false
            },
            profileId: { 
            type: String,
            required: true
        }
        },
        
        data() {
            return {
                width: 400,
                height: 350,
                margin: { top: 37, right: 20, bottom: 135, left: 20 },
                months: this.getLastSixMonthsData(), 
                mockData: [],
                apiEndpoint: process.env.VUE_APP_API_ENDPOINT
            };
        },

        computed: {
        
            numberOfLines() {
                return 6; // The number of horizontal lines to be drawn
            },

            minValue() {
                return Math.min(...this.mockData);
            },
        
            maxValue() {
                return Math.max(...this.mockData);
            },
        
            dynamicScaleY() {
                let totalHeight = this.height - this.margin.top - this.margin.bottom;
                if (this.isMainView) {
                    // This adjustment reduces the compression effect by lessening the impact of lineSpacingFactor
                    let effectiveLines = this.numberOfLines - (this.lineSpacingFactor - 0.7); // Adjust this formula as needed
                    return totalHeight / (this.maxValue - this.minValue) * (this.numberOfLines / effectiveLines);
                } else {
                    return totalHeight / (this.maxValue - this.minValue);
                }
            },
        
            points() {
                // This computes the points with the new y-coordinates adjusted by margins
                return this.mockData.map((value, index) => ({
                x: this.getMonthXPosition(index),
                y: this.getYPositionForValue(value)
                }));
            },
        
            linePoints() {
                return this.points.map(point => `${point.x},${point.y}`).join(' ');
            },

            lineSpacingFactor() {
                // Increase spacing in the main view, normal spacing otherwise
                return this.isMainView ? 2.75 : 1;
            }
        },

        methods: {
            getLastSixMonthsData() {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const currentMonth = new Date().getMonth(); // Get the current month (0-11)
            return Array.from({length: 6}, (_, i) => monthNames[(currentMonth - i + 12) % 12]).reverse();
        },
                    
            getMonthXPosition(index) {
                let usableWidth = this.width - this.margin.left - this.margin.right;
                let spacing = usableWidth / (this.months.length - 1);
                return this.margin.left + index * spacing;
            },
            
            getYPositionForValue(value) {
                let adjustedValue = value - this.minValue;
                let yPosition = this.height - this.margin.bottom - (adjustedValue * this.dynamicScaleY);

                // vertical offset
                if (this.isMainView) {
                    const additionalOffset = 112;
                    yPosition += additionalOffset;
                }

                return yPosition + this.margin.top;
            },
            
            getYPosition(lineNumber) {
                let totalHeight = this.height - this.margin.top - this.margin.bottom;
                let spacing = totalHeight / (this.numberOfLines - this.lineSpacingFactor);
                return this.margin.top + lineNumber * spacing;
            },
            

            async generateMockData() {
            try {
                console.log('Account ID:', this.profileId);
                const response = await axios.get(`${this.apiEndpoint}/graph-data?type=rank&account_id=${this.profileId}`);
                console.log('response:', response);
                const data = response.data;
                // Prepare the data, filling missing months
                const filledData = this.fillMissingMonths(data);
                this.mockData = filledData.map(entry => parseInt(entry.rank));
            } catch (error) {
                console.error('Failed to fetch graph data:', error);
                this.mockData = new Array(6).fill(0); // Fallback to zeros on failure
            }
        },

        fillMissingMonths(data) {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const lastSixMonths = this.getLastSixMonths(monthNames);
            return lastSixMonths.map(month => {
                const entry = data.find(d => d.month.toUpperCase() === month.toUpperCase());
                return {
                    month: month,
                    rank: entry ? entry.rank : '0'
                };
            });
        },

        getLastSixMonths(monthNames) {
            const currentMonth = new Date().getMonth();
            return Array.from({length: 6}, (_, i) => monthNames[(currentMonth - i + 12) % 12]).reverse();
        },
    

        formatLabel(value) {
    if (value === 0) {
        return ''; // Return an empty string for zero values
    } else if (value >= 1000000) {
        // For values in the millions
        return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
        // For values in the thousands
        return (value / 1000).toFixed(1) + 'K';
    } else {
        // For values less than 1000
        return value.toString();
    }
}
            
        },
    
        mounted() {
        
            this.generateMockData();

        }

    };

</script>

<style>

    line {
        stroke-width: 0.75;
    }

    text {
        font-size: 14px;
        fill: #19112dbf;
        font-weight: 600;
    }

    /*polyline {
        stroke-width: 1.5;
    }*/

    svg {
        width: 95%;
        height: auto;
        overflow: visible;
    }

    .label-stroke {
        fill: none;
        stroke: #faf7fc;
        stroke-width: 5px;
        font-size: 15px;
        font-weight: 700;
    }

    .label-fill {
        fill: #853bb4;
        stroke: none;
        font-size: 15px;
        font-weight: 700;
    }

</style>