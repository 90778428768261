<template>
  <div class="temp-class" id="forgot-password">
    <div v-if="!tokenIsValid">
      <label class="forgot-password">Forgot Password<br><br></label>
      <form @submit.prevent="submitEmail">
        <div>
          <label for="email">Email:<br><br></label>
          <input type="email" id="email" v-model="email" required>
          <!-- Display success message for email submission -->
          <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
          <br><br>
        </div>
        <button type="submit">Send Email</button>
      </form>
    </div>
    <div v-else>
      <label class="reset-password">Reset Your Password<br><br></label>
      <form @submit.prevent="submitNewPassword">
        <div>
          <label for="newPassword">New Password:<br><br></label>
          <input type="password" id="newPassword" v-model="newPassword" required>
          <!-- Display success message for password update -->
          <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
          <br><br>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: "",
      newPassword: "",
      tokenIsValid: false,
      resetToken: this.$route.query.token || null,
      successMessage: "", // Added for success messages
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT
    };
  },
  mounted() {
    if (this.resetToken) {
      this.verifyToken();
    }
  },
  methods: {
    submitEmail() {
      const payload = { email: this.email };
      
      fetch(`${this.apiEndpoint}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(() => {
          this.successMessage = "A password reset link has been sent!";
        })
        .catch(error => {
          console.error('Error:', error);
          this.successMessage = ""; // Optionally clear or set an error message
        });
    },
    submitNewPassword() {
      const payload = { new_password: this.newPassword, token: this.resetToken };
      fetch(`${this.apiEndpoint}/update-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(() => {
          this.successMessage = "Your password has been updated successfully!";
          // Reset fields after successful update
          this.newPassword = "";
          this.resetToken = null;
          this.tokenIsValid = false;
        })
        .catch(error => {
          console.error('Error:', error);
          this.successMessage = "An error occurred while updating your password.";
        });
    },
    verifyToken() {
      fetch(`${this.apiEndpoint}/verify-password-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: this.resetToken })
      })
        .then(response => response.json())
        .then(data => {
          if (data.message === "Token is valid.") {
            this.tokenIsValid = true;
          } else {
            this.successMessage = "The password reset link is invalid or has expired.";
            this.tokenIsValid = false;
          }
        })
        .catch(error => {
          console.error('Error verifying token:', error);
          this.successMessage = "An error occurred while verifying the token.";
          this.tokenIsValid = false;
        });
    }
  }
};
</script>

<style scoped>
.temp-class {
  text-align: center;
  padding: 50px;
}
.forgot-password, .reset-password {
  text-align: center;
  font-weight: bold;
}
.success-message {
  color: #853bb4;
  font-size: 12px;
  margin-top: 5px;
}
</style>
