<template>
    
  <div class="docs-content-container">
    
    <div class="docs-content">
      
      <div class="docs-heading">PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</div>
      <div class="docs-bodytext">This notice addresses the specific disclosure requirements under the California Consumer Privacy Act of 2018 
        (Cal. Civ. §§ 1798.100-1798.199) and the California Consumer Privacy Act Regulations by the Attorney General (collectively, "CCPA").<br><br>
        
        If you have a disability, you may access this Privacy Policy in an alternative format by contacting support@crowdscore.ai.<br><br>
        
        In the 12 preceding months, we have collected and disclosed the following categories of Personal Information:
      </div>

      <table class="table-style">
        <tr>
          <th>Category of Personal Information Collected</th>
          <th>Personal Information Collected</th>
          <th>Categories of service providers to whom Personal Information was disclosed</th>
        </tr>
        <tr>
          <td>A. Identifiers.</td>
          <td>B. Full name, email address, device identifiers, including certain software and hardware information 
            (e.g. IP address, internet service provider, domain server, type of computer, browser type and language and operating system).</td>
          <td>C. Cloud services.<br>D. Website analysis.<br>E. Affiliated companies.</td>
        </tr>
        <tr>
          <td>F. Personal Information Categories listed in the California Customer Records Statute (Cal. Civ. Code § 1798.80(e)).</td>
          <td>G. Address, telephone number, financial information (e.g. credit card number).</td>
          <td>H. Cloud services.<br>I. Affiliated companies.</td>
        </tr>
        <tr>
          <td>J. Commercial Information.</td>
          <td>K. Feedback of users.</td>
          <td>L. Cloud services.<br>M. Affiliated companies.</td>
        </tr>
        <tr>
          <td>N. Internet or Other Electronic Network Activity Information.</td>
          <td>O. Information regarding the user's interaction with the website.</td>
          <td>P. Cloud services.<br>Q. Website analysis.<br>R. Affiliated companies.</td>
        </tr>
        <tr>
          <td>S. Geolocation Data.</td>
          <td>T. Geolocation data based on device identifiers.</td>
          <td>U. Cloud services.<br>V. Affiliated companies.</td>
        </tr>
        <tr>
          <td>W. Professional or Employment-related Information.</td>
          <td>X. Current or past job history.</td>
          <td>Y. Cloud services.<br>Z. Affiliated companies.</td>
        </tr>
      </table>

      <div class="docs-section-heading">Sources of Personal Information</div>
      <div class="docs-bodytext">In the 12 preceding months, we have collected the above-mentioned categories of Personal Information from the 
        following categories of sources:<br><br>
        
        • Consumers directly, through our Services;<br><br>
        
        • Operating systems.<br><br>
        
        • Publicly available information collected from online sources.
      </div>

      <div class="docs-section-heading">Selling Personal Information</div>
      <div class="docs-bodytext">In the preceding twelve (12) months, we may have sold the following categories of Personal Information to 
        third-parties or businesses for commercial purposes:<br><br>
        
        Identifiers;
      </div>

      <br><br>

      <div class="docs-heading">User Rights under the CCPA</div>
      
      <div class="docs-section-heading">Access to Personal Information</div>
      <div class="docs-bodytext">You may request, up to two times each year, that we disclose to you the categories and specific pieces of 
        Personal Information that we have collected about you, the categories of sources from which your Personal Information is collected, 
        the business or commercial purpose for collecting your Personal Information, the categories of Personal Information that we disclosed 
        for a business purpose, any categories of Personal Information about you that we sold, the categories of third-parties with whom we 
        have shared your Personal Information, and the business or commercial purpose for selling your Personal Information, if applicable.
      </div>

      <div class="docs-section-heading">Deletion Requests</div>
      <div class="docs-bodytext">You have the right to request that we delete any Personal Information collected from you and retained, 
        unless an exception applies.<br><br>

        Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers, subcontractors, and 
        consultants to delete) your Personal Information, unless an exception applies.
      </div>

      <div class="docs-section-heading">Exercising Your Rights</div>
      <div class="docs-bodytext">You can exercise your rights (such as access and deletion) by submitting a verifiable consumer request to our 
        email address: support@crowdscore.ai. You can also send us a mail to our physical address specified in this policy.<br><br>

        Only you or a person authorized to act on your behalf may make a consumer request related to your Personal Information.<br><br>
        
        The request must:<br><br>
        
        • Provide sufficient information to allow us to reasonably verify you are the person about whom we collected Personal Information or 
        an authorized representative.<br><br>
        
        • Describe your request with sufficient details to allow us to properly understand, evaluate, and respond to it.<br><br>
        
        • We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the 
        request and confirm the Personal Information relates to you. Making a verifiable consumer request does not require you to create an account 
        with us. We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity or authority 
        to make the request. We will only use Personal Information provided in a verifiable consumer request to verify the requestor's identity 
        or authority to make the request.<br><br>
        
        You may only request a copy of your data twice within a 12-month period.<br><br>
        
        If you have any general questions about the Personal Information that we collect about you how we use it, please contact us at support@crowdscore.ai.
      </div>

      <div class="docs-section-heading">Response Timing and Format</div>
      <div class="docs-bodytext">Our goal is to respond to a verifiable consumer request within 45 days of its receipt. If we require more time, we will inform 
        you of the reason and extension period in writing within the first 45 days period. We will deliver our written response, by mail or electronically, 
        at your option. Any disclosures we provide will cover only the 12-month period preceding the request. If reasonably possible, we will provide your 
        Personal Information in a format that is readily useable and should allow you to transmit the information without hindrance.<br><br>
        
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. 
        If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before 
        completing your request.<br><br>
        
        In case of rejection, the response we provide will explain the reasons for which we cannot comply with your request.<br><br>
        
        Please note that these CCPA rights are not absolute and requests are subject to any applicable legal requirements, including legal and ethical 
        reporting or document retention obligations.
      </div>

      <div class="docs-section-heading">Designating Agents</div>
      <div class="docs-bodytext">You can designate an authorized agent to make a request under the CCPA on your behalf if:<br><br>
        
        • The authorized agent is a natural person or a business entity registered with the Secretary of State of California; and<br><br>
        
        • You sign a written declaration that you authorize the authorized agent to act on your behalf.<br><br>
        
        If you use an authorized agent to submit a request to exercise your right to know or your right to request deletion, please mail a 
        certified copy of your written declaration authorizing the authorized agent to act on your behalf using the contact information below.<br><br>
        
        If you provide an authorized agent with power of attorney pursuant to Probate Code sections 4000 to 4465, it may not be necessary to 
        perform these steps and we will respond to any request from such authorized agent in accordance with the CCPA.
      </div>

      <div class="docs-section-heading">Non-Discrimination</div>
      <div class="docs-bodytext">We will not discriminate against you if you exercise any of your privacy rights as a California Consumer. 
        Unless permitted by the CCPA, we will not:<br><br>
        
        • Deny you goods or services.<br><br>
        
        • Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.<br><br>
        
        • Provide you a different level or quality of goods or services.<br><br>
        
        • Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
      </div>

      <div class="docs-section-heading">Contact Us</div>
      <div class="docs-bodytext">For any questions, comments, inquiries, or complaints relating to your privacy, please contact us at:<br><br>
          
          Crowdscore Inc.,<br> 
          P.O. Box 77593<br>
          Toronto RPO Sheppard Plaza ON <br>
          M3H 6A7<br>

          support@crowdscore.ai<br><br>
      </div>

    </div>
  
  </div>
  
</template>

<script>

  export default {
    
    name: 'PrivacyNotice'
  
  }

</script>
