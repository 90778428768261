<template>
  <div class="content-area">
    <div v-if="isLoggedIn">
      <div class="header">
        <div class="header-guest-logo">
          <router-link to="/"><img class="crowdscore-logo-icon" :src="require('@/assets/crowdscore-logo.png')"
              alt="Logo"></router-link>
        </div>
        <h1><button @click="logout">Logout</button></h1>
      </div>
      <table class="summary-report">
        <thead>
          <tr>
            <th>Brands</th>
            <th>Subs</th>
            <th>Creators</th>
            <th>Backlog</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ summary.brands }}</td>
            <td>{{ summary.subscribers }}</td>
            <td>{{ summary.creators }}</td>
            <td>{{ summary.updateBacklog }}</td>
          </tr>
        </tbody>
      </table>
      <div><br></div>
      <!-- Sales Report Table -->
      <div class="table-label"><b>Sales</b></div>
      <table class="sales-report">
        <thead>
          <tr>
            <th>Date</th>
            <th>Signups</th>
            <th>Subs</th>
            <th>Conv</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in paginatedSalesReports" :key="report.day">
            <td>{{ formatDate(report.day) }}</td>
            <td>{{ report.signups }}</td>
            <td>{{ report.subscriptions }}</td>
            <td>{{ report.conversion_rate }}</td>
          </tr>
        </tbody>
      </table>
              <!-- Pagination Controls -->
<div class="pagination">
  <button class="logout" @click="currentPage = Math.max(1, currentPage - 1)">&lt;</button>&nbsp;
  <span>Page {{ currentPage }} of {{ totalPages }}</span>
  &nbsp;<button class="logout" @click="currentPage = Math.min(totalPages, currentPage + 1)">&gt;</button>
</div>
      <div><br></div>
      <!-- Brand Signup Report Table -->
<div class="table-label"><b>Signups</b></div>
<table class="brand-signup-report">
  <thead>
    <tr>
      <th>Date</th>
      <th>Signups</th>
      <th>Email</th>
      <th>Gmail</th>
      <th>Countries</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="report in paginatedBrandSignupReports" :key="report.day">
      <td>{{ formatDate(report.day) }}</td>
      <td>{{ report.signups }}</td>
      <td>{{ report.email }}</td>
      <td>{{ report.gmail }}</td>
      <td>{{ report.topCountries }}</td>
    </tr>
  </tbody>
</table>

<!-- Pagination Controls for Brand Signup Report -->
<div class="pagination">
  <button @click="currentBrandSignupPage = Math.max(1, currentBrandSignupPage - 1)">&lt;</button>&nbsp;
  <span>Page {{ currentBrandSignupPage }} of {{ totalBrandSignupPages }}</span>
  &nbsp;<button @click="currentBrandSignupPage = Math.min(totalBrandSignupPages, currentBrandSignupPage + 1)">&gt;</button>
</div><br>
    </div>
    <div v-else>
      <div><br></div>
      <!-- Login form for unauthenticated users -->
      <form class="form" @submit.prevent="login">
        <input v-model="credentials.username" type="text" autocomplete="off" /><br>
        <input v-model="credentials.password" type="password" autocomplete="off" /><br>
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      credentials: { username: '', password: '' },
      isLoggedIn: false, // Moved isLoggedIn here from setup()
      summary: { brands: 0, subscribers: 0, creators: 0, updateBacklog: 0 },
      salesReports: [],
      currentPage: 1,
      itemsPerPage: 7, 
      totalPages: 0,
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
      brandSignupReports: [],
      currentBrandSignupPage: 1,
      brandSignupPerPage: 7,
      totalBrandSignupPages: 0,

    };
  },

  computed: {
  paginatedSalesReports() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.salesReports.slice(start, end);
  },
  totalItems() {
    return this.salesReports.length;
  },

  paginatedBrandSignupReports() {
      const start = (this.currentBrandSignupPage - 1) * this.brandSignupPerPage;
      const end = start + this.brandSignupPerPage;
      return this.brandSignupReports.slice(start, end);
    },
    totalBrandSignupItems() {
      return this.brandSignupReports.length;
    }

},

watch: {
  isLoggedIn(newValue) {
    if (newValue) {
      this.fetchSummary();
      this.fetchSalesReports();
      this.fetchBrandSignupReports();
    }
  }
},

  methods: {

    fetchBrandSignupReports() {
  axios.get(`${this.apiEndpoint}/dashboard-signups`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
  .then(response => {
    console.log("Raw signup data:", response.data);
    // Process each signup record
    this.brandSignupReports = response.data.map(signup => {
      return {
        ...signup,
        day: this.formatDate(signup.day), // Formatting the date
        topCountries: signup.countries // Assuming 'countries' is the key for the countries data
      };
    });
    this.totalBrandSignupPages = Math.ceil(this.brandSignupReports.length / this.brandSignupPerPage);
  })
  .catch(error => {
    console.error('Failed to fetch brand signup reports:', error.response?.data?.msg || error.message);
  });
},




    formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  },

    fetchSalesReports() {
      return axios.get(`${this.apiEndpoint}/dashboard-sales`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          // Assuming the response data format matches your salesReports array structure
          this.salesReports = response.data;
        })
        .catch(error => {
          console.error('Failed to fetch sales reports:', error.response?.data?.msg || error.message);
        });
    },


    fetchSummary() {
      return axios.get(`${this.apiEndpoint}/dashboard-summary`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(response => {
          // Ensure data structure matches expected; might need to adjust based on actual response
          this.summary = response.data;
        })
        .catch(error => {
          console.error('Failed to fetch summary:', error.response?.data?.msg || error.message);
        });
    },

    logout() {
      localStorage.removeItem('access_token');
      this.isLoggedIn = false;
      window.location.reload();
    },

    login() {
      axios.post(`${this.apiEndpoint}/tools-auth`, this.credentials, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          localStorage.setItem('access_token', response.data.access_token);

          Promise.all([this.fetchSummary(), this.fetchSalesReports(), this.fetchBrandSignupReports()])
        .then(() => {
          this.isLoggedIn = true; // Update isLoggedIn only after all data is fetched
        });

        })
        .catch(error => {
          console.error('Login Failed:', error.response?.data?.msg || error.message);
        });
    },
  },
  created() {
    this.isLoggedIn = !!localStorage.getItem('access_token');
    if (this.isLoggedIn) {
      this.fetchSummary();
      this.fetchSalesReports();
      this.fetchBrandSignupReports();
    }
  },
};
</script>

<style scoped>
.content-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: right;
  padding: 10px;
}

.logout-btn {
  cursor: pointer;
  align-items: right;
}


table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #efefef;
  padding: 8px;
  text-align: left;
}

thead {
  background-color: #f9f9f9;
}

.summary-report {
  width: 60vw;
  border-collapse: collapse;
  font-size: 12px;
}

.sales-report {
  margin-top: 10px;
  width: 80vw;
  border-collapse: collapse;
  font-size: 10px;
}

.brand-signup-report {
  margin-top: 10px;
  width: 80vw;
  border-collapse: collapse;
  font-size: 10px;
}

.sales-report th,
.sales-report td {
  border: 1px solid #efefef;
  padding: 8px;
}

.sales-report th {
  background-color: #f3f3f3;
  text-align: left;
}

.header-guest-logo {
  top: 25px;
}
.pagination {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  align-items: center;
  font-size: 12px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
}
.table-label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}
</style>
