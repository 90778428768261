<template>
    <div class="settings-content-container">
        <div class="settings-content">

            <div class="section-heading">Settings</div>


            <!-------------- Email ----------------->

            <div class="settings-container">
                <div class="settings-container-heading">Email</div>
                <div class="settings-container-content">
                    <div class="settings-container-content-left">
                        <img v-if="accountType === 'google'" src="/images/google-signedin.png" alt="Google Signed In"
                            class="google-signedin-image">
                        <input type="email" class="settings-input-field" v-model="inputEmail"
                            :disabled="accountType === 'google'" />
                        <div class="settings-bodytext" v-if="!checkingEmailVerification && !isEmailVerified">
                            We sent you an email to verify your address. Click the link in the email to continue.
                            Didn't get it? <span class="settings-bodytext-link" @click="sendVerificationEmail">Send again</span>
                                <div class="success-message" v-if="verificationMessage">{{ verificationMessage }}</div>

                        </div>
                        <!--  <div class="success-message" v-else>Your email is verified.</div> -->
                    </div>
                    <button v-if="accountType !== 'google'" :class="{ 'save-button': true, 'inactive': !hasContentChanged }"
                        @click="saveEmail">Save</button>
                </div>
            </div>


            <!-------------- Change password ----------------->

            <div class="settings-container" v-if="accountType !== 'google'">
                <div class="settings-container-heading">Change password</div>
                <div class="settings-container-content">
                    <div class="settings-container-content-left">

                        <div class="input-field-title-settings">Current password</div>
                        <div class="password-container">
                            <input :type="isPasswordVisible ? 'text' : 'password'" class="settings-input-field"
                                v-model="currentPassword" autocomplete="new-password" @input="resetPasswordUpdateSuccess">
                            <div class="error-message1" v-if="formErrors.password">{{ formErrors.password }}</div>
                            <img :src="isPasswordVisible ? '/images/icon-password-displayed.png' : '/images/icon-password-hidden.png'"
                                class="toggle-password-icon-settings" @click="togglePasswordVisibility"
                                :alt="isPasswordVisible ? 'Hide password' : 'Show password'" />
                        </div>
                        <div class="success-message" v-if="invalidCurrentPassword">The current password is incorrect.</div>

                        <div class="input-field-title-settings">New password</div>
                        <div class="password-container">
                            <input :type="isPasswordVisible ? 'text' : 'password'" class="settings-input-field"
                                v-model="newPassword" autocomplete="new-password" @input="resetPasswordUpdateSuccess">
                            <div class="error-message1" v-if="formErrors.password">{{ formErrors.password }}</div>
                            <img :src="isPasswordVisible ? '/images/icon-password-displayed.png' : '/images/icon-password-hidden.png'"
                                class="toggle-password-icon-settings" @click="togglePasswordVisibility"
                                :alt="isPasswordVisible ? 'Hide password' : 'Show password'" />
                        </div>

                        <div class="input-field-title-settings">Retype new password</div>
                        <div class="password-container">
                            <input :type="isPasswordVisible ? 'text' : 'password'" class="settings-input-field"
                                v-model="retypePassword" autocomplete="new-password" @input="resetPasswordUpdateSuccess">
                            <div class="error-message1" v-if="formErrors.password">{{ formErrors.password }}</div>
                            <img :src="isPasswordVisible ? '/images/icon-password-displayed.png' : '/images/icon-password-hidden.png'"
                                class="toggle-password-icon-settings" @click="togglePasswordVisibility"
                                :alt="isPasswordVisible ? 'Hide password' : 'Show password'" />
                        </div>
                        <div v-if="passwordMismatch" class="success-message">Passwords don't match. Please try again.</div>
                        <div v-if="passwordUpdateSuccess" class="success-message">Password updated successfully.</div>
                        <div class="settings-bodytext-link"><router-link to="/forgot-password">Forgot password</router-link>
                        </div>

                    </div>
                    <button :class="{ 'save-button': true, 'inactive': !isPasswordMatching }"
                        @click="savePassword">Save</button>
                </div>
            </div>


            <!-------------- Subscription ----------------->

            <div v-if="subscriptionInfo && transactions.length > 0" class="settings-container">
                <div class="settings-container-heading">Subscription</div>
                <div class="settings-container-content">
                    <div class="settings-container-content-left">
                        <!-- Check if user has an active subscription -->
                        <div v-if="subscriptionInfo.status === 'active'" class="settings-content-heading">
                            Current plan: Crowdscore {{ subscriptionInfo.plan_name }} Subscription</div>
                        <div v-else class="settings-content-heading">
                            <!-- Check if the current date is past the renewal date for inactive subscription -->
                            <template v-if="isSubscriptionExpired">
                                Expired plan: Crowdscore Subscription
                            </template>
                            <template v-else>
                                Current plan: Crowdscore Subscription
                            </template>
                        </div>
                        <div class="settings-bodytext">
                            <!-- Show renewal or expiry message based on subscription status and dates -->
                            <template v-if="subscriptionInfo.status === 'active'">
                                Your plan renews on {{ subscriptionInfo.renewal_date }}.
                                <span class="settings-bodytext-link" @click="toggleCancelsubModal">Cancel anytime</span>
                            </template>
                            <template v-else-if="isSubscriptionExpired">
                                Your plan has expired. <router-link to="/pricing"><span class="settings-bodytext-link"
                                        @click="reactivateSubscription">Reactivate</span></router-link>
                            </template>
                            <template v-else>
                                Your plan expires on {{ subscriptionInfo.renewal_date }}.
                                <span class="error-message">Cancelled</span>
                                <!--    <router-link to="/pricing"><span class="settings-bodytext-link" @click="reactivateSubscription">Reactivate</span></router-link> -->
                            </template>
                        </div>
                        <!-- Invoice history -->
                        <div class="settings-content-heading-ul">Invoice history</div>
                        <div class="invoice-history-container" v-for="transaction in transactions"
                            :key="transaction.transaction_id">
                            <div class="invoice-history-content-left">
                                <div class="download-invoice-button" @click="downloadInvoice(transaction.brand_id, transaction.transaction_id)"><img src="/images/icon-download.png" alt="Download"></div>
                                <div class="settings-bodytext">{{ transaction.capture_date }} - {{ transaction.plan_name }}
                                    Subscription</div>
                            </div>
                            <div class="settings-bodytext">${{ transaction.amount }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-------------- Cancel subscription modal ----------------->

            <div v-if="showCancelsubModal" class="alertmodal">
                <div class="alertmodal-content">

                    <div class="alertmodal-heading">Are you sure you want to cancel your Crowdscore Premium Subscription?
                    </div>
                    <div class="alertmodal-subtitle">You'll lose access to unlimited features and any saved lists you
                        created.</div>
                    <div class="alertmodal-actions">
                        <div v-if="cancelSubscriptionError" class="error-message">{{ cancelSubscriptionError }}</div>
                        <div @click="cancelSubscription" class="alertmodal-submit-destructive">
                            <span v-if="!isCancellingSubscription">Cancel Subscription</span>
                            <span v-else>Cancelling...</span>
                        </div>
                        <div @click="toggleCancelsubModal" class="alertmodal-cancel">Don't Cancel</div>
                    </div>
                </div>
            </div>


            <!-------------- Account ----------------->

            <div class="settings-container">
                <div class="settings-container-heading">Account</div>
                <div class="settings-container-content">
                    <div class="invoice-history-content-left">
                        <span class="settings-bodytext-link" @click="toggleDeleteaccountModal">Delete account</span>
                    </div>
                </div>
            </div>

            <!-------------- Delete account modal ----------------->

            <div v-if="showDeleteaccountModal" class="alertmodal">
                <div class="alertmodal-content">

                    <div class="alertmodal-heading">Are you sure you want to delete your account?</div>
                    <div class="alertmodal-subtitle">This action is irreversible and all your saved data will be lost.</div>
                    <div v-if="deleteAccountError" class="error-message2">{{ deleteAccountError }}</div>
                    <div class="alertmodal-actions">
                        <div @click="deleteAccount" class="alertmodal-submit-destructive">Delete Account</div>
                        <div @click="toggleDeleteaccountModal" class="alertmodal-cancel">Cancel</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {

    name: 'SettingsContent',

    data() {
        return {
            originalEmail: 'email@domain.com',
            inputEmail: '',
            isPasswordVisible: false,
            currentPassword: '',
            newPassword: '',
            retypePassword: '',
            formErrors: {
                password: ''
            },
            showCancelsubModal: false,
            showDeleteaccountModal: false,
            verificationMessage: '',
            isEmailVerified: false,
            passwordUpdateSuccess: false,
            invalidCurrentPassword: false,
            passwordMismatch: false,
            checkingEmailVerification: true,
            subscriptionInfo: null,
            transactions: [],
            isCancellingSubscription: false,
            cancelSubscriptionError: "",
            accountType: null,
            deleteAccountError: '',
            apiEndpoint: process.env.VUE_APP_API_ENDPOINT
        };
    },

    async mounted() {
        this.inputEmail = this.$store.getters.email;
        this.originalEmail = this.inputEmail;
        this.checkVerificationStatus();
        console.log("Email from Vuex on mount:", this.$store.getters.email);
        console.log("Vuex store state:", this.$store.state);
        this.fetchSubscriptionAndTransactions();
        await this.fetchAccountType();
    },

    computed: {

        isSubscriptionExpired() {
            if (!this.subscriptionInfo || !this.subscriptionInfo.renewal_date) return false;
            const today = new Date();
            const renewalDate = new Date(this.subscriptionInfo.renewal_date);
            return today > renewalDate;
        },

        hasContentChanged() {
            return this.inputEmail !== this.originalEmail;
        },

        hasPasswordChanged() {
            return this.password !== this.originalPassword; // You might need to define originalPassword
        },

        // Computed property to check if new password and retype password match
        isPasswordMatching() {
            return this.newPassword && this.retypePassword && this.newPassword === this.retypePassword;
        },

        userEmail() {
            console.log("Accessing email from Vuex:", this.$store.getters.email);
            return this.$store.getters.email;
        }
    },


    methods: {


        async downloadInvoice(brandId, transactionId) {

        const token = localStorage.getItem('accessToken');
    try {
        const response = await axios.post(`${this.apiEndpoint}/generate-invoice`, {
            brand_id: brandId,
            transaction_id: transactionId,
            download: true
        },
        {
        headers: {
                'Authorization': `Bearer ${token}`, // Include the JWT in the request headers
            },
            responseType: 'blob', // Important to handle PDF binary data
        });


             // Extract invoice_number from the response headers or other parts where it's available
             const invoiceNumber = response.headers['invoice-number'];
             console.log("Invoice number:", invoiceNumber);

        // Create a new Blob object using the response data
        const file = new Blob(
            [response.data], 
            { type: 'application/pdf' }
        );

        // Generate a URL for the Blob object
        const fileURL = URL.createObjectURL(file);

        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `crowdscore_${invoiceNumber}.pdf`); // Name the download file
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the Blob URL and removing the temporary link
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error("Error downloading invoice:", error);
        // Handle error (e.g., by showing an error message to the user)
    }
},


        async fetchAccountType() {
            try {
                const brandId = this.$store.state.brandId; // Assuming you have the brand ID stored in Vuex
                const response = await axios.get(`${this.apiEndpoint}/account-type?brand_id=${brandId}`);
                this.accountType = response.data.account_type;
            } catch (error) {
                console.error("Error fetching account type:", error);
                // Handle error (e.g., by showing an error message or setting a default account type)
            }
        },

        reactivateSubscription() {
            console.log("Reactivating subscription...");
            // Implement the reactivation logic here, possibly navigating to a payment page or showing a modal
        },

        resetPasswordUpdateSuccess() {
            this.passwordUpdateSuccess = false;
        },

        async fetchSubscriptionAndTransactions() {
            try {
                const brandId = this.$store.state.brandId; // Assuming brandId is stored and accessible
                const accessToken = localStorage.getItem('accessToken'); // Get the stored token
                if (!brandId || !accessToken) {
                    console.error("Brand ID or access token is not available");
                    return;
                }

                const response = await axios.post(`${this.apiEndpoint}/list-transactions`, { brand_id: brandId }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                this.subscriptionInfo = response.data[0]; // Assuming the first item is the current subscription
                this.transactions = response.data;
            } catch (error) {
                console.error("Failed to fetch subscription and transactions:", error);
            }
        },

        async sendVerificationEmail() {
            try {
                const response = await axios.post(`${this.apiEndpoint}/verify-email`, {
                    brand_id: this.$store.state.brandId,
                    email: this.inputEmail,
                });
                console.log("Response:", response.data);
                // Use the verified state from the response
                if (response.data.verified) {
                    this.verificationMessage = 'Your email is already verified.';
                } else {
                    this.verificationMessage = response.data.message; // 'Verification link sent!'
                }
            } catch (error) {
                this.verificationMessage = 'Failed to send verification link.';
                console.error("Error sending verification email:", error.response ? error.response.data : error);
            }
        },

        async checkVerificationStatus() {
            try {
                const response = await axios.post(`${this.apiEndpoint}/verify-email-check`, {
                    brand_id: this.$store.state.brandId,
                    email: this.$store.state.email,
                });
                this.isEmailVerified = response.data.verified;
            } catch (error) {
                console.error("Failed to check email verification status:", error);
            } finally {
                this.checkingEmailVerification = false; // Set to false when check is complete
            }
        },

        checkEmail() {
            console.log(this.userEmail);
        },

        async saveEmail() {
            if (this.hasContentChanged) {

                this.isEmailVerified = false;

                try {
                    const payload = { // Update this part to match the expected structure
                        brand_id: this.$store.state.brandId,
                        new_email: this.inputEmail,
                    };
                    const response = await axios.post(`${this.apiEndpoint}/update-email`, payload);
                    console.log("Email updated successfully:", response.data);
                    this.originalEmail = this.inputEmail;
                    const email = this.inputEmail;
                    this.$store.dispatch('updateEmail', this.inputEmail);
                    localStorage.setItem('userEmail', email);
                    console.log("Email saved to vuex:", this.inputEmail);
                    console.log("Email saved to local:", email);
                    this.checkVerificationStatus();

                } catch (error) {
                    console.error("Failed to update email:", error.response ? error.response.data : error);
                    // Optionally, show an error message to the user
                }
            } else {
                console.log("No changes to save.");
            }
        },


        handleFocus() {
            // Clear the placeholder text when the input field is focused
            // if (this.inputEmail === this.placeholderText) {
            //   this.inputEmail = '';
            // }
        },

        handleBlur() {
            // Restore placeholder text if input is empty
            if (!this.inputEmail.trim()) {
                this.inputEmail = this.placeholderText;
            }
        },

        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible;
        },

        async savePassword() {
            this.passwordUpdateSuccess = false;
            this.invalidCurrentPassword = false;
            this.passwordMismatch = false;

            if (this.newPassword !== this.retypePassword) {
                // If passwords do not match, set the flag and exit the method
                this.passwordMismatch = true;
                console.log("Passwords do not match.");
                return;
            }

            if (this.isPasswordMatching) {
                try {
                    const payload = {
                        brand_id: this.$store.state.brandId,
                        password: this.currentPassword,
                        new_password: this.newPassword,
                    };
                    await axios.post(`${this.apiEndpoint}/update-password`, payload);
                    this.passwordUpdateSuccess = true;
                    this.currentPassword = '';
                    this.newPassword = '';
                    this.retypePassword = '';
                } catch (error) {
                    if (error.response && error.response.data.error === "Invalid brand ID or password") {
                        this.invalidCurrentPassword = true;
                    } else {
                        console.error("Failed to update password:", error.response ? error.response.data.error : "An unknown error occurred");
                    }
                }
            } else {
                console.log("Passwords do not match.");
            }
        },



        toggleCancelsubModal() {
            this.showCancelsubModal = !this.showCancelsubModal;
        },

        async cancelSubscription(skipModal = false) {
            this.isCancellingSubscription = true; // Flag to indicate loading state

            if (!skipModal) {
                this.showCancelsubModal = false;
            }

            const brandId = this.$store.state.brandId; // Assuming brandId is stored in Vuex
            const accessToken = localStorage.getItem('accessToken'); // Retrieve the JWT token

            if (!accessToken) {
                console.error("Access token is not available.");
                this.cancelSubscriptionError = "Failed to cancel subscription. Please try again.";
                this.isCancellingSubscription = false;
                return Promise.reject("Access token is not available.");
            }

            try {
                const response = await axios.post(`${this.apiEndpoint}/cancel-subscription`, { brand_id: brandId }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                console.log("Subscription cancelled successfully:", response.data);

                // If successful, close the modal
                this.showCancelsubModal = false;

                // Refresh subscription and transactions data to reflect the new state
                await this.fetchSubscriptionAndTransactions();
            } catch (error) {
                console.error("Failed to cancel subscription:", error);
                this.cancelSubscriptionError = "Failed to cancel subscription. Please try again.";
                return Promise.reject(error);
            } finally {
                this.isCancellingSubscription = false; // Reset loading state
            }
        },

        logout() {
            // Clear JWT tokens from localStorage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('brandId');

            // Update Vuex state for isSubscribed
            this.$store.commit('setIsSubscribed', false);
            this.$store.dispatch('logout');

            // Update global state to indicate the user is logged out
            if (this.globalState && this.globalState.updateLoginStatus) {
                this.globalState.updateLoginStatus(false, false);
            }

            window.scrollTo(0, 0);

            // Redirect to home page
            this.$router.push('/').then(() => {
                window.scrollTo(0, 0);
                window.location.reload();
            });
        },

        toggleDeleteaccountModal() {
            this.showDeleteaccountModal = !this.showDeleteaccountModal;
        },

        async deleteAccount() {
        const brandId = this.$store.state.brandId;
        const accessToken = localStorage.getItem('accessToken');

        if (!brandId || !accessToken) {
            this.deleteAccountError = "Brand ID or access token is not available.";
            return;
        }

        try {
            const deleteResponse = await axios.post(`${this.apiEndpoint}/delete-account`,
                { brand_id: brandId },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            if (deleteResponse.data.message === "Account deleted successfully") {


                // Logout user after successful deletion and subscription cancellation
                this.logout();
            } else {
                this.deleteAccountError = deleteResponse.data.error || "An unexpected error occurred.";
            }
        } catch (error) {
            if (error.response) {
                this.deleteAccountError = error.response.data.error || "An error occurred while deleting the account.";
            } else {
                this.deleteAccountError = "An error occurred while deleting the account.";
            }
        }
    },

    }

}

</script>
<style scoped>

.error-message {
    color: #e01414;
    font-size: 12px;
    margin-top: -10px;
}

.error-message2 {
    color: #e01414;
    font-size: 12px;
    margin-top: 80px;
}


</style>