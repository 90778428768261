<template>
  <div class="settings-content-container">
      <div class="verify-email">
      <h2>Email Verification</h2>
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VerifyEmail',
  data() {
    return {
      message: '',
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT
    };
  },
  created() {
    this.verifyEmailToken();
  },
  methods: {
    verifyEmailToken() {
      const token = this.$route.query.token; // Assuming you're using vue-router and token is a query parameter
      if (!token) {
        this.message = 'Verification token is missing.';
        return;
      }
      axios
        .post(`${this.apiEndpoint}/verify-email-token`, { token })
        .then((response) => {
          this.message = response.data.message;
        })
        .catch((error) => {
          this.message = error.response.data.message || 'An error occurred during email verification.';
        });
    },
  },
};
</script>