<template>
  
  <div class="docs-content-container">
    
    <div class="docs-content">

      <div class="docs-heading">Crowdscore Terms of Service</div>
      <div class="docs-bodytext">Last Updated: March 19, 2024
        <br>We may update these Terms of Service from time to time, so check this page regularly for updates.
      </div>
      
      <div class="docs-section-heading">1. Introduction; Agreement to These Terms of Service</div>
      <div class="docs-bodytext">PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING CONTRACT.<br><br>
        
        When we say "terms" we mean these Terms of Service located at the URL https://crowdscore.ai/terms.<br><br> 
        
        When we say "Crowdscore", "we", "us", and "our" in these terms, we mean Crowdscore, its subsidiaries, 
        and its related companies.<br><br>
        
        When we say "services", "Crowdscore services", or "our services" in these terms, 
        we mean the services, apps, websites, and other products, owned and operated by Crowdscore.<br><br>

        When we say "you" or "your," we mean you. If you're accessing our services on behalf of a legal entity 
        (like your employer), you agree that you have the authority to bind that entity to these terms, and "you" 
        and "your" will refer to that entity. <br><br>
        
        When we say "user" or "users," we mean individuals, other than you, who access or otherwise use our services.<br><br> 
        
        When we say "Third-Party" or "Third-Parties" we mean individuals, groups, or entities, other than you, us, or other Crowdscore users.<br><br> 
        
        These terms set forth our legal obligations to each other. They apply to your access and use of our services. If you do not wish to be bound 
        by these terms, or you do not agree to these terms, do not access or otherwise use our services.<br><br>

        BY ACCESSING, DOWNLOADING, USING, PURCHASING, PAYING TO USE AND/OR SUBSCRIBING TO OUR SERVICES, 
        YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY, THESE TERMS, AS THEY MAY BE AMENDED 
        FROM TIME TO TIME. IF YOU DO NOT AGREE WITH THESE TERMS IN THEIR ENTIRETY, YOU MAY NOT USE THE CROWDSCORE SERVICES. 
        YOUR CONTINUED USE OF OUR SERVICES AFTER WE POST REVISED TERMS MEANS THAT YOU AGREE TO THE REVISIONS.<br><br> 
        
        We also have a <span class="inline-text-link"><router-link to="/privacy">Privacy Policy</router-link></span> 
        that applies to your use of our services and are incorporated into these terms. You should read these policies as 
        they contain important information about your use of our services.
      </div>

      <div class="docs-section-heading">2. Who We Are</div>
      <div class="docs-bodytext">We are Crowdscore, located at:<br><br>
          Crowdscore Inc.,<br>
          P.O. Box 77593<br>
          Toronto RPO Sheppard Plaza ON<br>
          M3H 6A7<br><br>

          support@crowdscore.ai<br><br>

          We provide services that allow you to create a unique personal profile and interact with other users based on location. 
          Our services may also include access to certain features and content that you can purchase from us.
      </div>

      <div class="docs-section-heading">3. Account Eligibility</div>
      <div class="docs-bodytext"><strong>The Crowdscore services are not available to anyone under the age of 18.</strong> By accessing or using our 
        services, you confirm that you're at least 18 years old, that you are capable of lawfully entering into a binding contract with 
        Crowdscore, and that you are able to perform all of the obligations set forth in these terms. You also represent and warrant that 
        you have never been convicted of an indictable offense, felony, or any criminal offense involving sexual misconduct or otherwise. 
        You further confirm that you have not previously been suspended, removed, deactivated, terminated or blocked from the 
        Crowdscore services; and that you are not a competitor of Crowdscore and are not using the Crowdscore services for reasons that are 
        in competition with Crowdscore, or other than for its intended purpose.
      </div>

      <div class="docs-section-heading">4. Privacy</div>
      <div class="docs-bodytext">Your privacy is important to us. 
        Please see our <span class="inline-text-link"><router-link to="/privacy">Privacy Policy</router-link></span>
        for information relating to how we collect, use, and disclose your personal information, and how you can manage your 
        privacy when you use our services.
      </div>

      <div class="docs-section-heading">5. What You Can Expect From Us</div>
      <div class="docs-bodytext">We are actively developing new features and products to improve our services and bring you 
        additional functionality that you will find useful. As part of these efforts, we may add or remove features or products, 
        start offering new features or products, or stop offering old features or products. We may also discontinue our services 
        all together. We may not provide you with notice before making any changes to our services or discontinuing our services. 
        While we try to avoid disruptions, we cannot guarantee that there will not be an outage or change to our services, 
        and your content may not be retrievable due to such outages or changes. We are not liable for any such outages, service changes, 
        or discontinuation of our services.
      </div>

      <div class="docs-section-heading">6. Your Responsibilities</div>
      <div class="docs-bodytext">You agree that you will only use our services, including posting any user content, in a manner 
        consistent with these terms and compliant with any and all applicable local, provincial, federal and international laws and regulations. 
        <br><br>You are responsible for making sure that you are eligible to use our services. See Section 3: Account Eligibility above for more details.
      </div>
        
      <div class="docs-subsection"><strong>1&#41; Content and Conduct.</strong> You agree to treat all other users with dignity and respect while using 
        or accessing our services.
      </div>
        
      <div class="docs-subsection"><strong>2&#41; Your Crowdscore Account.</strong> To access and use our services you will need to create a Crowdscore account. 
        You agree to only make one Crowdscore account for your own personal, non-commercial, use. You can provide an email address and password. 
        You may also use a Third-Party single sign on method to create your account (such as Google). By using a Third-Party single sign on method, 
        you are authorizing us to access certain information from your Third-Party account. The information you authorize us to access is based on the 
        permissions you give to us at the time of authorization. While you can revoke our access to such information later on (through the Third-Party account's 
        settings) the information we receive during the initial authorization will still be stored in our records. 
      </div>

      <div class="docs-subsection"><strong>3&#41; Account Security.</strong> You are responsible for the security of your account, and you agree to notify us 
        immediately if you believe your account has been compromised. You are responsible for maintaining the confidentiality of your login credentials. 
        If you use a password, it must be strong, and we strongly recommend that you do not use that password for any other accounts besides Crowdscore. 
        If your account is compromised, we may not be able to restore it to you.<br><br>
        You are responsible for all activities that occur on your account, including any purchases made. You agree to use particular caution when accessing 
        your account from a public or shared computer and/or mobile device so that others are not able to view or record your personal information or 
        login credentials. You acknowledge that Crowdscore is not, and will not, be liable or responsible for any losses or damages arising from the theft 
        or misappropriation of your Crowdscore account. You further acknowledge and agree that Crowdscore is not responsible for, and has no control over, 
        any security breaches experienced on any Third-Party platform or site.
      </div>

      <div class="docs-subsection"><strong>4&#41; Contact Information.</strong> You are also responsible for maintaining the accuracy of the contact 
        information associated with your account. If you get locked out of your account, we will need to contact you at the email address associated 
        with your account, and we may not be able to restore your account if you no longer have access to that email address. We may also assume that 
        any communications we've received from your account or the associated email address have been made by you.
      </div>

      <div class="docs-subsection"><strong>5&#41; Exclusive Use.</strong> You agree not to license, sell, rent, lease, share, or otherwise transfer your 
        Crowdscore account, unless expressly permitted in writing by Crowdscore. You will only use our services for your sole, personal use and not in 
        connection with any commercial activities. You will not authorize others to use our services, or gain access to your Crowdscore account. 
      </div>

      <div class="docs-subsection"><strong>6&#41; Data Usage Charges.</strong> The use of our services may require the use of your personal computer or 
        mobile device, as well as, communications with or use of space on, such devices. You are responsible for any Internet connection, mobile data, 
        or other fees and/or charges that you incur when accessing our services. Crowdscore is not responsible for your data usage and will not be responsible 
        for any charges that you may incur while using our services.
      </div>

      <div class="docs-subsection"><strong>7&#41; Interactions with Others.</strong> You assume all risk when using our services, including but not limited to, 
        all risks associated with any online or offline interactions with others. You acknowledge that Crowdscore does not routinely screen, inquire into 
        the background of, or attempt to verify information provided on Crowdscore. Crowdscore does not make any representations, warranties or guarantees as 
        to the information provided on Crowdscore. You alone are responsible for your involvement with other users. You agree that Crowdscore will not be 
        responsible for any losses or damages incurred as the result of any interactions with other users. Crowdscore reserves the right, but has no obligation, 
        to monitor disagreements between you and other users. 
      </div>

      <div class="docs-subsection"><strong>8&#41; Reporting Violations.</strong> If you wish to report any violation of these terms you may do so by using 
        emailing support@crowdscore.ai. While we will make reasonable efforts to review Crowdscore accounts that are reported in a timely manner, 
        we are under no obligation to remove the reported account, and in no way represent that we will remove or otherwise address reported accounts.
      </div>

      <div class="docs-subsection"><strong>9&#41; Enforcement.</strong> We reserve the right to disable, block, suspend, deactivate, or terminate your use 
        of or access to our services, or terminate your Crowdscore account, for any reason whatsoever and without notice. We also reserve all available 
        legal rights and remedies to prevent unauthorized use of our services, including, but not limited to, technological barriers, IP mapping, and, 
        in serious cases, directly contacting your Internet Service Provider (ISP) regarding such unauthorized use. You understand and agree that if 
        Crowdscore believes in its sole discretion that you have violated these terms, misused our services or behaved in a way that could be regarded 
        as inappropriate, unlawful, illegal or unsafe, we may, among other things, investigate, take legal action against you and/or terminate your 
        Crowdscore account without notice to you. We will not be liable to you or any Third-Party should we exercise such right.
      </div>

      <div class="docs-section-heading">7. Third-Party Content</div>
      <div class="docs-bodytext">Our services may allow you to access Third-Party websites, features, apps, services or other content. We provide you 
        access only as a convenience to you, and you agree that we are not responsible or liable in any way for the content or services available from 
        these Third-Parties. You agree that you will access Third-Party content at your own risk. Crowdscore does not control, endorse, review, or adopt 
        any Third-Party content and we expressly disclaim any and all representations, warranties or conditions of any kind regarding Third-Party content, 
        including without limitation regarding its accuracy or completeness.<br><br>The collection, use, and disclosure of your information from a 
        Third-Party will be subject to that Third-Party's privacy policies and practices and is not covered under Crowdscore's 
        <span class="inline-text-link"><router-link to="/privacy">Privacy Policy</router-link></span>.
      </div>

      <div class="docs-section-heading">8. Crowdscore's Content</div>
      <div class="docs-bodytext">When we say “Crowdscore's content” or “our content” in these terms, we mean our services and all materials and content on 
        our services, other than user content or Third-Party content, which includes but is not limited to software, images, text, graphics, designs, 
        illustrations, Crowdscore's logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, information, data, other files, 
        and the arrangement of such materials and content on our services.<br><br>
        
        Crowdscore's content and all intellectual property rights related to our content are the exclusive property of Crowdscore. Except as explicitly 
        provided in these terms, nothing in these terms shall be deemed to create a license in or under any such intellectual property rights of Crowdscore.<br><br>
        
        You are granted a limited, non-exclusive, non-transferable, non-sublicensable, revocable, license to access and use our services and our content, 
        for non-commercial use, subject to these terms.<br><br> 

        You agree that you will not:<br><br>
        
        1. copy, modify, disassemble, publish, adapt, sublicense, translate, sell, distribute, transmit, perform, display, reverse engineer, decipher, 
        decompile or otherwise disassemble any portion of our content or our services or cause others to do so;<br><br>
        
        2. "frame" or "mirror" any part of our services or our content, without our prior written authorization;<br><br>
        
        3. use meta tags or code or other devices containing any reference to Crowdscore or our services in order to direct any person to any other site 
        for any purpose;<br><br>
        
        4. resell, rent, lease, loan, sublicense, distribute or otherwise transfer or make any commercial use of our services or our content, 
        including provide time sharing or similar services to any Third-Party;<br><br>
        
        5.use any data mining, robots, spiders or similar data gathering or extraction methods or otherwise collect any pictures, descriptions, 
        data or other content from our services;<br><br>
        
        6. forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted through our services;<br><br>
        
        7. remove, circumvent, disable, damage or otherwise interfere with security related features of our services, features that prevent or restrict 
        use or copying of any content accessible through our services, or features that enforce limitations on use of our services;<br><br>
        
        8. delete the copyright and any other proprietary rights notices on our services;<br><br>
        
        9. use any automated methods or processes to create Crowdscore accounts or access our services or our content; or<br><br>
        
        10. use our content or our services other than for their intended purpose.<br><br>
        
        Any use of our content or our services other than as expressly authorized in these terms, without the prior written consent of Crowdscore, 
        is strictly prohibited and will violate and terminate the license granted in these terms. Such unauthorized use may also violate applicable laws, 
        including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated in 
        these terms, nothing in these terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication 
        or otherwise. This license is revocable at any time and for any reason whatsoever and without notice.<br><br>
        
        You acknowledge that Crowdscore may from time-to-time issue upgraded versions of our services, and may automatically electronically upgrade the 
        version of our services that you are using. You consent to such automatic upgrading, and agree that these terms will apply to all such upgrades. 
        You agree that Crowdscore will not be liable to you for any such upgrades.<br><br>
        
        The foregoing license granted under these terms is not a sale of the 
        Crowdscore services or any copy thereof and Crowdscore or its partners or suppliers retain all rights, title, and interest in the Crowdscore services 
        (and any copy thereof). Any attempt by you to transfer any of the rights, duties or obligations in these terms, except as expressly provided for in 
        these terms, is void. Crowdscore reserves all rights not expressly granted in these terms.
      </div>

      <div class="docs-section-heading">9. Trademarks</div>
      <div class="docs-bodytext">"CROWDSCORE", Crowdscore's logos and any other trade names or slogans contained in the Crowdscore services are trademarks or 
        service marks of Crowdscore, its partners or its licensors and may not be copied, imitated or used, in whole or in part, without the prior written 
        permission of Crowdscore or the applicable trademark holder. In addition, the look and feel of the Crowdscore services, including all page headers, 
        custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Crowdscore and may not be copied, imitated or used, 
        in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos 
        mentioned in the Crowdscore services are the property of their respective owners. Reference to any products, services, processes or other information, 
        by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship or recommendation by us.
      </div>

      <div class="docs-section-heading">10. Advertising</div>
      <div class="docs-bodytext">From time to time, we may place advertisements on our services and we reserve the right to do so. In some cases these 
        advertisements may be adjacent to your user content. You are not entitled to any compensation for such advertisements. The manner, mode, and 
        extent of such advertisements are subject to change without specific notice to you.
      </div>

      <div class="docs-section-heading">11. App Stores</div>
      <div class="docs-bodytext">You acknowledge and agree that the availability of the Crowdscore services is dependent on Third-Parties from whom you 
        downloaded the Crowdscore app, such as Google Play or the Apple app store. You acknowledge that these terms are between you and Crowdscore and not 
        with the Third-Party app store that you downloaded the Crowdscore app from. Each Third-Party app store may have its own terms and conditions to 
        which you must agree to before downloading the Crowdscore app. You agree to comply with, and your license to use the Crowdscore services is 
        conditioned upon your compliance with, all applicable terms and conditions of the particular app store where you downloaded the Crowdscore app from.
      </div>

      <div class="docs-section-heading">12. Respecting Copyright</div>
      <div class="docs-bodytext">We respect the intellectual property of others and expect our users to do the same. We may, in our sole discretion, disable 
        or terminate the Crowdscore accounts of users whom we believe may be infringing the intellectual property rights of others.<br><br>
        
        If you believe your work has been copied in a way that constitutes copyright infringement or that your intellectual property rights have been otherwise 
        violated, please provide us with the following information:<br><br>
        
        • an electronic or physical signature of the copyright or intellectual property owner or the person authorized to act on behalf of the owner of the 
        copyright or other intellectual property interest;<br><br>
        
        • a description of the copyrighted work or other intellectual property that you claim has been infringed;<br><br>
        
        • a description of where the material that you claim is infringing is located;<br><br>
        
        • your address, telephone number, and email address;<br><br>
        
        • a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and<br><br>
        
        • a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual 
        property owner or authorized to act on the copyright or intellectual property owner's behalf.<br><br>
        
        Please email claims of copyright or other intellectual property infringement to us at support@crowdscore.ai. We will consider the information you provide and other information we may obtain, and we may remove user content that we determine may infringe on your rights in our sole discretion.

      </div>

      <div class="docs-section-heading">13. Crowdscore's Paid Services</div>
      <div class="docs-bodytext">When we say “Crowdscore's Paid Services” or “our paid services” in these terms, we mean any additional functionality, features, 
        services or products that you pay for on or through our services, which includes but is not limited to Premium subscriptions.<br><br>

        Crowdscore subscriptions unlock additional features within Crowdscore. You may purchase a Crowdscore subscription for various recurring time periods 
        (For example, one-month, three-months, or twelve-months). For all subscriptions, full payment will be charged to your payment method (PayPal or Credit Card) 
        when you confirm your purchase. Your subscription will renew automatically for the same time period as the original subscription (i.e. one-month, three-months, 
        or twelve-months) unless you cancel the subscription at least 24 hours before the end of your current subscription period. You may cancel the subscription 
        through your Crowdscore account under the settings option.<br><br>

        Purchases of subscriptions are non-refundable and non-transferable, even if they expire or are discontinued. We may, at any time and without notice, 
        change the purchase price for subscriptions, as well as the features included in the subscriptions, and we reserve the right to do so. We also reserve the 
        right to stop offering subscriptions at any time and to set expiration dates for subscriptions. Subscriptions may not be redeemed for any sum of money or 
        monetary value. If you delete your Crowdscore account or your Crowdscore account is terminated for any reason, you will lose all unused subscription time 
        without refund or other compensation to you.
      </div>

      <div class="docs-section-heading">14. Updates to These Terms</div>
      <div class="docs-bodytext">We may decide to update these terms to reflect changes to our services or our business, for legal or regulatory reasons, 
        or to prevent abuse on or off our services, and we reserve the right to do so at any time. If these changes materially affect your use of our services 
        or your legal rights, we will give you reasonable advance notice (unless the updates are urgent). The terms will always show the "last updated" date at 
        the top of the page which will indicate when the last changes were made and when they take effect. You are responsible for regularly checking this page 
        for any changes. If you continue to use our services after the changes have taken effect, it means that you agree to the changes. If you don't agree to 
        the changes, you must stop using our services.
      </div>

      <div class="docs-section-heading">15. Indemnity By You</div>
      <div class="docs-bodytext">You agree, to the extent permitted under applicable law, to indemnify, defend, and hold harmless Crowdscore, our affiliates, 
        and our respective officers, directors, shareholders, employees, contractors and agents, from and against any and all complaints, demands, claims, 
        damages, losses, costs, liabilities, and expenses, including legal and accounting fees, due to, arising out of, or relating in any way to your access 
        to or use of our services, your user content, your conduct toward other users, or your violation of these terms.
      </div>

      <div class="docs-section-heading">16. Disclaimers</div>
      <div class="docs-bodytext">TO THE FULLEST EXTENT PERMITTED BY LAW, CROWDSCORE PROVIDES OUR SERVICES (INCLUDING ALL CONTENT CONTAINED THEREIN) ON AN "AS IS" 
        AND "AS AVAILABLE" BASIS. CROWDSCORE MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO OUR SERVICES 
        (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, ACCURACY, 
        QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.<br><br>
        
        CROWDSCORE DOES NOT REPRESENT OR WARRANT THAT (A) YOUR USE OF THE CROWDSCORE SERVICES (INCLUDING ALL CONTENT CONTAINED THEREIN) WILL BE SECURE, UNINTERRUPTED, 
        COMPLETE, ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, (B) ANY DEFECTS IN OUR SERVICES (INCLUDING ALL CONTENT CONTAINED THEREIN) WILL BE DISCOVERED OR CORRECTED, 
        (C) OUR SERVICES (INCLUDING ALL CONTENT CONTAINED THEREIN) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (D) THAT ANY CONTENT OR INFORMATION YOU OBTAIN 
        ON OR THROUGH OUR SERVICES WILL BE ACCURATE OR APPROPRIATE FOR YOUR PURPOSES.<br><br>
        
        CROWDSCORE DISCLAIMS LIABILITY FOR, AND NO WARRANTY IS MADE WITH RESPECT TO, THE CONNECTIVITY AND AVAILABILITY OF OUR SERVICES (INCLUDING ALL CONTENT CONTAINED 
        THEREIN) OR THE DELIVERY OF ANY COMMUNICATIONS.<br><br>
        
        CROWDSCORE HAS NO OBLIGATION TO VERIFY THE IDENTITY OF OR SCREEN THE PERSONS USING OUR SERVICES, NOR DO WE HAVE ANY OBLIGATION TO MONITOR THE USE OF OUR 
        SERVICES BY OTHER USERS. THEREFORE, CROWDSCORE DISCLAIMS ANY AND ALL LIABILITY FOR YOUR INTERACTIONS WITH AND THE CONDUCT OF OTHER USERS AND FOR IDENTITY 
        THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION.<br><br>
        
        THE INFORMATION PRESENTED ON OR THROUGH OUR SERVICES IS MADE AVAILABLE SOLELY FOR INFORMATIONAL PURPOSES. CROWDSCORE DOES NOT GUARANTEE THE ACCURACY, 
        COMPLETENESS OR USEFULNESS OF ANY INFORMATION ON OUR SERVICES, OR ANY RESULTS FROM YOUR USE OF OUR SERVICES. CROWDSCORE DOES NOT ADOPT, ENDORSE OR ACCEPT 
        RESPONSIBILITY OR LIABILITY FOR THE CONDUCT OF ANY USERS OR FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN 
        CROWDSCORE. UNDER NO CIRCUMSTANCES WILL CROWDSCORE BE RESPONSIBLE FOR ANY LOSSES, DAMAGES OR HARM OF ANY KIND RESULTING FROM ANY USER'S CONDUCT OR YOUR 
        USE OF OUR SERVICES OR FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON OUR SERVICES, OR TRANSMITTED TO OR BY ANY USERS.<br><br>
        
        CROWDSCORE IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, BUT WITHOUT 
        LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, HACKING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION 
        OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER DISRUPTION OR MALFUNCTION.<br><br>
        
        THE LAWS OF CERTAIN JURISDICTIONS OR STATES DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. TO THE EXTENT SUCH WARRANTIES CANNOT BE DISCLAIMED UNDER THE 
        LAWS OF YOUR JURISDICTION, WE LIMIT THE DURATION AND REMEDIES OF SUCH WARRANTIES TO THE FULL EXTENT PERMISSIBLE UNDER THOSE LAWS.<br><br>
        
        This warranty does not affect any consumer rights you might have under applicable law, including the legal guarantee in certain places such as the 
        European Union that products and services must comply with this agreement and your rights in case of non-conformity of a product or service.
      </div>

      <div class="docs-section-heading">17. Limitation of Liability</div>
      <div class="docs-bodytext">WE DO NOT EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE ILLEGAL TO DO SO. IN COUNTRIES WHERE THE BELOW TYPES OF 
        EXCLUSIONS ARE NOT ALLOWED, WE ARE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE 
        REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU. THIS SECTION DOES NOT AFFECT CONSUMER RIGHTS THAT CAN NOT BE WAIVED OR LIMITED BY ANY 
        CONTRACT OR AGREEMENT.<br><br>
        
        IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED, NEITHER CROWDSCORE, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, 
        CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS OR SERVICE PROVIDERS, INVOLVED IN CREATING, PRODUCING, OR DELIVERING OUR SERVICES WILL BE LIABLE, TO THE MAXIMUM 
        EXTENT PERMITTED UNDER APPLICABLE LAW, FOR ANY DIRECT, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, PUNATIVE, FIXED, ENHANCED, OR ANY OTHER 
        DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMTED TO DAMAGES FOR LOSS OF USE, PROFITS, DATA, GOODWILL, REVENUES, SAVINGS, BUSINESS OPPORTUNITY, OR OTHER 
        INTANGIBLE LOSSES OR OTHER ECONOMIC LOSS, COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION 
        WITH THESE TERMS; OR FROM YOUR ACCESS OR USE OF OR THE INABILITY TO ACCESS OR USE OUR SERVICES; OR FROM THE CONDUCT OR USER CONTENT OF ANY USERS OR ANY 
        CONTENT OF ANY THIRD-PARTY ON OR THROUGH ANY OF OUR SERVICES; OR FROM ANY UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR USER CONTENT, WHETHER BASED ON 
        WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT CROWDSCORE HAS 
        BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY PROVIDED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.<br><br>
        
        BESIDES THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS DESCRIBED IN THIS SECTION), CROWDSCORE LIMITS OUR LIABILITY TO YOU TO THE GREATER OF (A) THE 
        AMOUNTS YOU HAVE PAID US IN THE THREE MONTHS BEFORE YOU FIRST ASSERT A CLAIM; OR (B) $100 CANADIAN DOLLARS (OR THE EQUIVALENT IN YOUR LOCAL CURRENCY).<br><br> 
        
        THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND CROWDSCORE.
      </div>

      <div class="docs-section-heading">18. Dispute Resolution; Jurisdiction</div>
      <div class="docs-bodytext">All disputes, claims, or causes of action arising from or relating to your use of our services, our content, and these terms 
        will be governed by the provincial laws of Ontario, and the federal laws of Canada applicable therein, excluding the application of any conflicts of 
        laws rules.<br><br>
        
        If arbitration as described below is prohibited by applicable law, you agree that all disputes, claims, or causes of action will be heard and 
        resolved in a court of competent subject-matter jurisdiction, located in Toronto, Ontario, Canada. You consent to the personal jurisdiction of such courts 
        over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts.<br><br>
        
        Except where prohibited by applicable law, any disputes, claims, or causes of action (whether in contract or tort, pursuant to statute or regulation, 
        or otherwise, and whether pre-existing, present or future) arising out of or relating to these terms or our services (including all content contained therein), 
        will be referred to and determined by a sole arbitrator (to the exclusion of the courts) pursuant to the Arbitration Act, 1991, SO 1991, c 17 (Ontario, Canada) 
        as the same may be amended from time to time.<br><br>
        
        Accordingly, except where prohibited by applicable law, you hereby waive any right that you may have to commence or participate in any class action 
        against Crowdscore related to any disputes, claims, or causes of action and, where applicable, you also agree to opt out of any class proceedings against 
        Crowdscore.<br><br>
        
        If you have a dispute, claim, or cause of action, you should give written notice to arbitrate at the address specified in the Contact Us section of these 
        terms. If we have a dispute, claim, or cause of action, we will give you notice to arbitrate at your contact email address provided during registration of 
        your Crowdscore account.<br><br>
        
        YOU AND CROWDSCORE AGREE THAT ANY DISPUTES, CLAIMS, OR CAUSES OF ACTION ARISING OUT OF OR RELATED TO THE CROWDSCORE SERVICES MUST COMMENCE WITHIN ONE (1) 
        YEAR AFTER THE DISPUTE, CLAIM, OR CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH DISPUTE, CLAIM, OR CAUSE OF ACTION IS PERMANENTLY BARRED.<br><br>
        
        If you are a resident in any jurisdiction in which the provision in the section above is found to be unenforceable, then any disputes, claims, or causes 
        of action arising out of or in connection with these terms will be governed by and construed under the laws of your jurisdiction of residence, and shall 
        be resolved by competent civil courts within your jurisdiction of residence.<br><br>
      </div>

      <div class="docs-section-heading">19. Termination</div>
      <div class="docs-bodytext">Unless otherwise provided, these terms take effect upon your initial use of the Crowdscore services and shall remain in effect 
        until terminated as described below. <strong>Termination of your Crowdscore account does not cancel any recurring subscriptions or payments. You are responsible 
        for cancelling any recurring subscriptions or payments through your app store account. If your Crowdscore account is terminated by you or by Crowdscore 
        for any reason you will not be entitled to any refunds for purchases made.</strong>
      </div>

      <div class="docs-subsection"><strong>1&#41; Termination by Crowdscore.</strong> To the fullest extent permitted by applicable law, we reserve the right, 
        in our sole discretion, to disable, block, suspend, deactivate, or terminate your Crowdscore account and/or your access to some or all of our services 
        at any time, with or without notice, for any reason whatsoever, including but not limited to:<br><br>
        • your breach of these terms or any of our policies;<br><br>
        • your breach of any applicable laws or regulations;<br><br>
        • your fraudulent use or misuse of our services;<br><br>
        • if we are required to do so to comply with a legal requirement or court order;<br><br>
        • if we reasonably believe termination is necessary to prevent harm to you, us, other users, or Third-Parties;<br><br>
        • if we are unable to continue providing our services to you due to technical or legitimate business reasons.
      </div>

      <div class="docs-subsection"><strong>2&#41; Termination by You.</strong> You are free to stop using Crowdscore's services at any time and for any reason. 
        You can delete your Crowdscore account through the Crowdscore website or by contacting support@crowdscore.ai. 
      </div>

      <div class="docs-subsection"><strong>3&#41; Survival.</strong> Any part of these terms that by their nature should survive after termination of these terms 
        will survive.
      </div>

      <div class="docs-section-heading">20. Bug Reporting</div>
      <div class="docs-bodytext">We support the responsible reporting of security vulnerabilities and bugs. To report a security issue or bug, please email us at 
        support@crowdscore.ai. 
      </div>

      <div class="docs-section-heading">21. Waiver</div>
      <div class="docs-bodytext">If we fail to exercise or enforce any right or provision of these terms, it will not constitute a waiver of such right or provision. 
        Any waiver of any provision of these terms will be effective only if in writing and signed by the relevant party. 
      </div>

      <div class="docs-section-heading">22. Servability</div>
      <div class="docs-bodytext">If any provision of these terms is held to be unlawful, void, or for any reason unenforceable, then that provision will be limited 
        or eliminated from these terms to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions. 
      </div>

      <div class="docs-section-heading">23. Assignment</div>
      <div class="docs-bodytext">These terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by 
        Crowdscore without restriction. Any assignment attempted to be made in violation of these terms shall be void. 
      </div>

      <div class="docs-section-heading">24. Entire Agreement</div>
      <div class="docs-bodytext">These terms, and our <span class="inline-text-link"><router-link to="/privacy">Privacy Policy</router-link></span>, 
        cover the entire agreement between you and Crowdscore for your use of our services. 
      </div>

      <div class="docs-section-heading">25. Language of These Terms</div>
      <div class="docs-bodytext">The language of these terms is English. The English language version of these terms will govern your relationship with 
        Crowdscore and your use of our services. 
      </div>

      <div class="docs-section-heading">26. Contact Us</div>
      <div class="docs-bodytext">If you have any questions regarding these terms or your use of our services, please contact us at:<br><br>
        
        Crowdscore Inc.,<br>
        P.O. Box 77593<br>
        Toronto RPO Sheppard Plaza ON<br>
        M3H 6A7<br><br>
        
        support@crowdscore.ai 
      </div>
    
    </div>
  
  </div>

</template>

<script>

export default {
  
  name: 'TermsPage',
  
};
</script>