
<template>
  <div>
    <div> <HomeBanner /></div>
    <div class="content-frame"><HomeResults @profile-clicked="handleProfileSearch" /> </div>
    <div><HomeFeatures /></div>
    <!-- <div><HomeCTA /></div> -->
  </div>
</template>

<script>

window.onload = function() {
    document.body.style.visibility = 'visible'; // If you used visibility: hidden;
};

import router from '../router.js';
import HomeBanner from './Home/HomeBanner.vue'; // Import the Banner component
//import HomeBannerM from './Home/HomeBannerM.vue'; // Import the Banner component
import HomeResults from './Home/HomeResults.vue'; // Import the Results component
import HomeFeatures from './Home/HomeFeatures.vue'; // Import the Features component
//import HomeCTA from './Home/HomeCTA.vue'; // Import the CTA component

export default {
  name: 'App',
  components: {
    HomeBanner, // Register the Header component
    //HomeBannerM, // Register the Header component
    HomeResults, // Register the Results component
    HomeFeatures, // Register the Features component
    //HomeCTA // Register the CTA component
  },

  //data() {
  //  return {
  //    windowWidth: window.innerWidth
  //  };
  //},
  //computed: {
  //  isDesktop() {
  //    return this.windowWidth >= 600;
  //  }
  //},
  
  methods: {
    handleProfileSearch(username) {
      //console.log(`Handling profile search for: @${username}`);
      router.push({ name: 'Dashboard', query: { search: username } });
    },

    //updateWindowWidth() {
    //  this.windowWidth = window.innerWidth;
   // }
  },

  //mounted() {
  //  this.updateWindowWidth();
  //  window.addEventListener('resize', this.updateWindowWidth);
  //},
  //beforeUnmount() {
  //  window.removeEventListener('resize', this.updateWindowWidth);
 // }

}
</script>