<template>
    
  <div class="docs-content-container">
    
    <div class="docs-content">
      
      <div class="docs-heading">Crowdscore Privacy Policy</div>
      <div class="docs-bodytext">Last Updated: March 19, 2024
        <br>We may update this Privacy Policy from time to time, so check this page regularly for updates.
      </div>

      <div class="docs-section-heading">1. Introduction</div>
      <div class="docs-bodytext">This is the Privacy Policy of Crowdscore. (“Crowdscore”, “us”, “we”, or “our”) and is incorporated 
        into and is subject to our <span class="inline-text-link"><router-link to="/terms">Terms of Service</router-link></span>. This Privacy Policy explains how Crowdscore collects, uses, stores, protects, and 
        shares your personal information through our services. This Privacy Policy addresses both users who actively choose to use our 
        services, as described below and also to individuals whose personal data we may process as part of our operation.
      </div>

      <div class="docs-section-heading">2. Who We Are</div>
      <div class="docs-bodytext">The company that is responsible for your information under this Privacy Policy is:<br><br>

          Crowdscore Inc.,<br> 
          P.O. Box 77593<br>
          Toronto RPO Sheppard Plaza ON <br>
          M3H 6A7<br><br>
          
          support@crowdscore.ai<br><br>

          If you are located in the European Union (“EU”), European Economic Area (“EEA”) or the United Kingdom (“UK”), Crowdscore is the 
          “data controller” of your personal information collected through our services.

      </div>

      <div class="docs-section-heading">3. Where This Privacy Policy Applies</div>
      <div class="docs-bodytext">This Privacy Policy applies to websites, apps, and other services Crowdscore operates. For simplicity, 
        we refer to all of these as our “services” or the “Crowdscore services” in this Privacy Policy. To make it extra clear, we've 
        added links to this Privacy Policy on all applicable services.
      </div>

      <div class="docs-section-heading">4. Children's Privacy</div>
      <div class="docs-bodytext">IF YOU ARE UNDER 18 YEARS OF AGE, DO NOT USE OR ACCESS THE CROWDSCORE SERVICES AT ANY TIME OR IN ANY MANNER.<br><br>
        
        Crowdscore is restricted to individuals who are 18 years of age or older. For that reason, we do not knowingly collect or maintain personal 
        information from individuals under the age of 18. If we learn that personal information of individuals under 18 has been collected on or 
        through our services, we will promptly take appropriate steps to delete this information.<br><br>
        
        If you are the parent or legal guardian of an individual under the age of 18, who has become a Crowdscore member, please contact Crowdscore 
        Support at support@crowdscore.ai to have that individual's account terminated and personal information deleted.<br><br>
        
        For “data subjects”, as defined by the European Union General Data Protection Regulation (“EU-GDPR”) and the United Kingdom General Data 
        Protection Regulation (“UK-GDPR”), in the EU, EEA, or the UK, where the processing of personal information is based on consent, Crowdscore 
        will not knowingly engage in that processing for users under the age of consent established by applicable data protection law. If we learn 
        that we are engaged in that processing with such users, we will halt such processing and promptly take appropriate steps to delete their personal 
        information.

      </div>

      <div class="docs-section-heading">5. What Information We Collect</div>
      <div class="docs-bodytext">We collect the following types of data from you when you use our Services ("User Data"):<br><br>
        
        Non-Personal Information. This is information that is un-identified and non-identifiable, which is generated by a user's activity. 
        Such non-personal information may include the following information - device type, browser type, pages you visit, time spent on those pages, 
        access times and dates.<br><br>
        
        Personal Information. Personal information is information that identifies you or may identify you. The Personal Information we collect and retain 
        includes your IP address, your name and email address, payment and billing information or other information we may ask from time to time as will 
        be required for the on-boarding process. When you create an account on our Services you are able to do so by using your credentials with a 
        designated third-party website or service ("Third-Party Account") such as Google. Doing so will enable you to link your Crowdscore account and your 
        Third-Party Account. If you choose this option, a Third-Party Account pop-up box will appear that you will need to approve in order to proceed, 
        and which will describe the types of information that we will obtain. This information includes your Personal Information stored on your Third-Party 
        Account, such as your name, email address, and profile picture.<br><br> 
        
        If we combine non-personal information with personal information, we will treat the combined information as personal information.<br><br>
        
        We may also collect publicly posted personal data from various online sources. Such information will include, in most cases, basic contact information, 
        such as name, username, email, and publicly posted content, such as photos, comments, or profile photos ("Public Data").

      </div>

      <div class="docs-section-heading">6. How We Use Your Information</div>
      <div class="docs-bodytext">We use your User Data in order to provide you with our Services and to comply with our legal requirements. 
        This means that we will use the information to set up your account, provide you with support regarding our Services, communicate with you, 
        to provide you with marketing offers, and to conduct research to improve our Services.<br><br>
        
        We may use Public Data in order to provide our users with our Services.
      </div>

      <div class="docs-section-heading">7. How We Share & Disclose Your Information</div>
      <div class="docs-bodytext"><strong>We do not sell any User Data.</strong> We may share Public Data with our users in order to provide our Services.<br><br>
        
        <strong>With our vendors.</strong> We may share information with vendors we hire to carry out specific work for us. This includes payment 
        processors like PayPal that process transactions on our behalf, cloud providers like Amazon that host our data and our services, and 
        analytics providers like Google that provide us with statistical analysis of our services. We may also share limited information with advertising 
        platforms, like Facebook, to help us reach people that we think will like our product and to measure the performance of our ads shown on those 
        platforms. We do this to help bring more users to Crowdscore, and provide only the information required to facilitate these services. This may 
        include information like the fact that the app was installed or that you registered for a Crowdscore account.<br><br>
        
        <strong>To comply with the law.</strong> We may share information in response to a request for information if we believe disclosure is required by law, 
        including meeting national security or law enforcement requirements. Where allowed and feasible, we will attempt to provide you with prior notice 
        before disclosing your information in response to such a request.<br><br>
        
        <strong>In an emergency.</strong> We may share information if we believe in good faith that it's necessary to prevent serious harm to a person.<br><br>
        
        <strong>To enforce our policies and rights.</strong> We may share information if needed to enforce our <span class="inline-text-link"><router-link to="/terms">Terms of Service</router-link></span> or other policies, or to protect 
        the rights, property, and safety of ourselves and others.<br><br>
        
        <strong>With our related companies.</strong> We may share information with our related companies, including parents, affiliates, subsidiaries, 
        and other companies under common control and ownership.<br><br>
        
        <strong>Sale, Acquisition, or Transfer of Assets.</strong> We may share information if Crowdscore is evaluating and/or engaging in a merger, 
        acquisition, reorganization, bankruptcy, or sale, transfer, or change in ownership of Crowdscore or any of its assets.<br><br>
        
        <strong>Aggregated or de-identified information.</strong> We may share information about you that has been aggregated or anonymized such that it 
        cannot reasonably be used to identify you. For example, we may share aggregated user statistics in order to describe our business to partners or 
        the public.
      </div>

      <div class="docs-section-heading">8. How Long We Retain Your Information</div>
      <div class="docs-bodytext">We retain your information for as long as it is necessary, for example, to comply with our contractual obligations to you 
        or to provide you with our Services. Where we do not need your information in order to provide our Services to you, we retain it only for so long 
        as we have a legitimate business purpose in keeping such information, as may be allowed by applicable laws. However, there are occasions where we are 
        likely to keep this information for longer in accordance with our legal obligations or where it is necessary for the establishment, exercise or 
        defense of legal claims.<br><br>
        
        After your Crowdscore account has been deleted, we store some information in an aggregated and anonymized format for statistical purposes.
      </div>

      <div class="docs-section-heading">9. How We Protect Your Information</div>
      <div class="docs-bodytext">Security of your information is important to us. We use a variety of managerial, technical, and physical measures to 
        protect the integrity and security of your information. These measures are designed to protect your information from unauthorized access, theft, 
        disclosure, modification, or loss and we regularly review our security measures to consider any new available technologies and/or methods.<br><br>
        
        No security precautions or systems can be completely secure. We cannot ensure or warrant the security of any information you transmit to Crowdscore, 
        and you do so at your own risk. We cannot guarantee that such information may not be accessed, stolen, disclosed, altered, or destroyed by breach 
        of any of our physical, technical, or managerial safeguards. Should a breach occur, we will notify affected individuals as soon as feasible, 
        or as required under applicable laws.
      </div>

      <div class="docs-section-heading">10. Our Legal Bases For Processing Your Information</div>
      <div class="docs-bodytext">We rely on legal grounds to process User Data to the extent permitted by applicable law, which may include, without 
        limitation: your consent, to honor contractual commitments or to take steps in anticipation of entering into a contract, to fulfill legal 
        obligations, and Crowdscore's legitimate interests.<br><br>
        
        For greater clarity, our Legal Bases to process your personal information is generally one of the following four:<br><br>
        
        <strong>Consent.</strong> We process your personal information on the basis of your express, clear, and freely given consent for specific purposes. 
        You can revoke your consent through the Crowdscore services or through your device's permissions settings at any time.<br><br>
        
        <strong>To Honor Our Contractual Commitments.</strong> Much of our processing of your personal information is to meet our contractual obligations 
        to you, or to take steps at your request in anticipation of entering into a contract with you. For example, to create your Crowdscore account or 
        provide you with customer support.<br><br>
        
        <strong>Legal Obligations.</strong> We need to use and disclose personal information in certain ways to comply with our legal obligations, 
        e.g. we retain information where we are required by law or if we are compelled to do so by a court order or regulatory body.<br><br>
        
        <strong>Legitimate Interests.</strong> In many cases, we process your personal information on the basis that it furthers our legitimate interests 
        in ways that are not overridden by your interests or fundamental rights and freedoms. This includes:<br><br>
        
        • To provide a safe and enjoyable user experience;<br><br>
        
        • To prevent misuse, and to enforce our <span class="inline-text-link"><router-link to="/terms">Terms of Service</router-link></span> and other policies against users who violate them;<br><br>
        
        • To provide customer support;<br><br>
        
        • To engage in Marketing or Advertising, e.g. advertising on third-party services or sending emails or other communications to let you know about 
        new features or services;<br><br>
        
        • To track the fundamental metrics of our business, to perform financial reporting, and to respond to regulatory obligations;<br><br>
        
        • To analyze and improve our business, e.g. collecting information about how you use the Crowdscore services to optimize the design and 
        placement of certain features or add new features;<br><br>
        
        Processing of Public Data is done on the basis that it furthers our legitimate interests in ways that are not overridden by your interests 
        or fundamental rights and freedoms.

      </div>

      <div class="docs-section-heading">11. Where We Store Your Information</div>
      <div class="docs-bodytext">Information collected by Crowdscore may be stored and processed in your region, in the United States, in Canada, 
        or in any other country where Crowdscore or its affiliates, subsidiaries, partners, or service providers are located or maintain facilities. 
        If we provide any information about you to any such entities, we will take appropriate measures to ensure such companies protect your information 
        adequately in accordance with this Privacy Policy and applicable law. These data transfers allow us to provide the Crowdscore services to you. 
        By accessing or using Crowdscore or otherwise providing information to us, you understand that your information will be processed, 
        transferred, and stored in the United States, in Canada, and other countries, where different data protection standards may apply and/or you may 
        not have the same rights as you do under local law.<br><br>
        
        When transferring data outside of the EU, EEA, or the UK, we use “<span class="inline-text-link"> <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32010D0087">standard contractual clauses</a></span>” 
        (standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your 
        data), and we rely on the European Commission's “<span class="inline-text-link"> <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">adequacy decisions</a></span>” 
        (decisions regarding countries which have been deemed to offer an adequate level of data protection by the European Commission or other competent 
        governmental body) for such data transfers.
      </div>

      <div class="docs-section-heading">12. Your Rights & Choices</div>
      <div class="docs-bodytext">You have choices when it comes to what personal information you share with us. You can access and edit most of your 
        profile information by signing into your Crowdscore account and editing it through the “Settings” option. If you require help with managing 
        your account or content, please contact us at support@crowdscore.ai.<br><br>
        
        You may also be afforded certain rights under applicable privacy laws depending on the jurisdiction you reside in, or your geographical location.<br><br>
        
        If you are a data subject in the EU, EEA, or UK, you have the following rights with respect to your personal information:<br><br>
        
        • Right of access;<br><br>
        
        • Right to rectification;<br><br>
        
        • Right to erasure ('right to be forgotten');<br><br>
        
        • Right to restriction of processing;<br><br>
        
        • Right to data portability;<br><br>
        
        • Right to object to certain processing;<br><br>
        
        • Right not to be subject to a decision based solely on automated processing;<br><br>
        
        If you wish to exercise any of these rights above, please contact us at support@crowdscore.ai.<br><br>
        
        For your protection and the protection of other Crowdscore users, we may ask you to provide proof of identity before we can answer your 
        request to exercise any of the rights listed above.<br><br>
        
        Keep in mind, we may reject requests, including if we are unable to authenticate you, if the request is unlawful or invalid, or if it may 
        infringe on trade secrets or intellectual property, or the privacy or other rights of other Crowdscore users.<br><br>
        
        Also, we may not be able to accommodate certain requests to object to or restrict the processing of personal information where such requests 
        would not allow us to provide our services to you anymore. For example, we cannot provide our Services to you if we do not have your email 
        address because we need your email address to log you into your Crowdscore account. If we are unable to accommodate your request to object or 
        restrict the processing of your personal information you may still request to delete your Crowdscore account. Withdrawing your consent will not 
        affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your other personal information 
        where we rely on a legal basis other than consent to process that information.<br><br>
        
        You have a right to lodge a complaint with the data protection supervisory authority in the country where you are located if you have concerns about 
        how we process your personal information. If you are located within the UK, you may file a complaint with the Information Commissioner's Office (“ICO”).<br><br>
        
        We use automated decision-making processes to show Crowdscore profiles to our users. If you wish to remove yourself from this automated decision-making 
        process you contact us at support@crowdscore.ai.<br><br>
        
        You have the right to opt-out of email marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" 
        link in the marketing emails we send you.<br><br>
        
        If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. 
        With respect to California's "Shine the Light" law, Civil Code section 1798.83, we do not disclose personal information to third parties for the 
        third parties' direct marketing purposes. For more information on your rights under the California Consumer Privacy Act (CCPA), please visit the 
        <span class="inline-text-link" @click="goToPrivacyNotice"> <router-link to="/privacynotice_ca">PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</router-link></span>.
      </div>

      <div class="docs-section-heading">13. Third-Party Services</div>
      <div class="docs-bodytext">Crowdscore may link to third-party websites or services. The privacy practices of those third parties are not governed by 
        this Privacy Policy. We encourage you to review the privacy policies of those third-party websites and services to understand their privacy practices.
      </div>

      <div class="docs-section-heading">14. Cookies</div>
      <div class="docs-bodytext">Cookies are small bits of information that are transferred to and stored in separate files within your computer's browser. 
        While we do not use cookies on the Crowdscore website we do use Google Analytics. Google Analytics uses its own cookies to track website visitor 
        interactions. You can instruct your computer's browser to stop accepting cookies through your browser's settings. If you wish to stop Google Analytics 
        tracking on your web browser you will need to adjust your browser's cookie settings.
      </div>

      <div class="docs-section-heading">15. Do Not Track</div>
      <div class="docs-bodytext">Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform 
        websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. 
        However, Crowdscore does not recognize or respond to browser-initiated DNT signals at this time. You may wish to edit your browser's cookie settings instead.
      </div>

      <div class="docs-section-heading">16. Changes To This Privacy Policy</div>
      <div class="docs-bodytext">Crowdscore will update this Privacy Policy from time to time (for example to reflect updates to the Crowdscore services, 
        or to reflect changes in the law). We always indicate the date the last changes were published in the “Last Updated” date at the top of the page. 
        If changes are significant, we'll provide a more prominent notice as required by law, such as by emailing you. Please check this Privacy Policy 
        periodically for those changes. Your continued use of the Crowdscore services after the posting of changes constitutes your binding acceptance of 
        such changes.
      </div>

      <div class="docs-section-heading">17. How To Contact Us</div>
      <div class="docs-bodytext">For any questions, comments, inquiries, or complaints relating to your privacy, please contact us at:<br><br>
        Crowdscore Inc.,<br>
        P.O. Box 77593<br>
        Toronto RPO Sheppard Plaza ON <br>
        M3H 6A7<br><br>

        support@crowdscore.ai
      </div>

      <div class="docs-section-heading">18. Information Warranty Disclaimer</div>
      <div class="docs-bodytext">Notwithstanding anything else in this Policy, we are not responsible for the accuracy, correctness, and security of any of 
        the information we gather, store, and disclose to you or to anyone else.<br><br>
        
        Our policy only addresses the use and disclosure of information we collect from you. To the extent you disclose your information to other parties 
        or sites throughout the internet, different rules may apply to their use or disclosure of the information you disclose to them. Accordingly, 
        we encourage you to read the terms and conditions and privacy policy of each third-party that you choose to disclose information to.
      </div>

    </div>
  
  </div>
  
</template>

<script>

  export default {
    
    name: 'PrivacyPage',

    methods: {
      goToPrivacyNotice() {
        this.$router.push('/privacynotice_ca').then(() => {
          window.scrollTo(0, 0);
        });
      }
    }
  
  }

</script>
