
<template>
  <div>
    <div><PricingBanner /> </div>
    <div><PricingContent /></div>
  </div>
</template>

<script>
import PricingBanner from './Pricing/PricingBanner.vue';
import PricingContent from './Pricing/PricingContent.vue';

export default {
  name: 'App',
  components: {
    PricingBanner,
    PricingContent
  },
  mounted() {
    window.scrollTo(0, 0);
  }

}
</script>