import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router from router.js
import store from './store';

// Enable credentials for every request
axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use((config) => {
  // Check if this is a token refresh request by comparing the URL
  if (config.url.includes('/api/v1/token-refresh')) {
      const refreshToken = localStorage.getItem('refreshToken'); // Retrieve the refresh token
      if (refreshToken) {
          config.headers['Authorization'] = `Bearer ${refreshToken}`; // Use the refresh token for this request
      }
  } else {
      // For all other requests, use the access token
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


const siteUrl = process.env.VUE_APP_SITE_URL;

if (siteUrl === 'https://www.crowdscore.ai') {

const suppressLogging = () => {};
console.log = suppressLogging;
console.error = suppressLogging;
console.warn = suppressLogging;
console.info = suppressLogging;
console.debug = suppressLogging;

// eslint-disable-next-line no-unused-vars
window.onerror = function(message, source, lineno, colno, error) {
  // Handle the error or send it to a server
  console.log("An error occurred.");
  return true;  // Suppresses the default browser console error logging
};

}


// Add a response interceptor
axios.interceptors.response.use(response => {
  // Directly resolve the response if there's no error
  return response;
}, async error => {
  const originalRequest = error.config;

  // Check if the response was a 401 and the request has not already been retried
  if (error.response.status === 401 && !originalRequest._retry) {
    // Indicate that the request is being retried
    originalRequest._retry = true;

    const refreshToken = localStorage.getItem('refreshToken');
    //console.log('refreshToken:', refreshToken);

    // Proceed only if a refresh token is available
    if (refreshToken) {
      try {

        const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

        // Attempt to refresh the access token using the refresh token
        const res = await axios.post(`${apiEndpoint}/token-refresh`, {}, {
          headers: {
            'Authorization': `Bearer ${refreshToken}`,
          }
        });
        console.log('res.status:', res.status);

        if (res.status === 200) {
          // Store the new access token in localStorage
          localStorage.setItem('accessToken', res.data.access_token);
          //console.log('Token refreshed');

          // Update the default Authorization header with the new access token
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;

          // Update the Authorization header of the original request
          originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;

          // Retry the original request with the new token
          return axios(originalRequest);
        }
      } catch (refreshError) {
        // Log and reject the promise if the token refresh fails
        console.error('Error refreshing token:', refreshError);
        console.log('refreshError:', refreshError);
        return Promise.reject(refreshError);
      }
    } else {
      // Log and reject the promise if no refresh token is available
      console.error('Refresh token not available.');
      return Promise.reject(error);
    }
  }

  // Reject the promise for all other errors or if the request was already retried
  return Promise.reject(error);
});


const app = createApp(App);

app.directive('click-outside', {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function(event) {
        // Check that click was outside the el and its children
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event); // Invoke the provided method
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
});


// Helper function to remove old meta tags
function removeOldMetaTags() {
  // Define the attributes and their values that you want to remove
  const attributesToRemove = [
    { attr: 'name', values: ['description', 'keywords'] },
    { attr: 'property', values: ['og:title', 'og:description', 'og:keywords', 'og:url'] }
  ];

  // Iterate over each attribute type (name, property) and its values
  attributesToRemove.forEach(({ attr, values }) => {
    values.forEach(value => {
      const selector = `meta[${attr}="${value}"]`;
      const oldTag = document.querySelector(selector);
      if (oldTag) {
        oldTag.parentNode.removeChild(oldTag);
      }
    });
  });
}

router.beforeEach((to, from, next) => {
  store.commit('updateLastRoutePath', from.path);

  removeOldMetaTags();

  const headElement = document.head;
  const firstChild = headElement.firstChild;

// Handle the title tag
let titleTag = document.querySelector('title');
if (!titleTag) {
  titleTag = document.createElement('title');
  headElement.insertBefore(titleTag, firstChild);
}

  document.title = to.meta.title || 'Crowdscore: AI Powered Talent discovery. Find high quality micro influencers and creators.';

  headElement.insertBefore(titleTag, firstChild);

  // Add or update meta tags based on the current route's meta information
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      let tag = document.createElement('meta');
      if (tagDef.name) {
        tag.setAttribute('name', tagDef.name);
      }
      if (tagDef.property) {
        tag.setAttribute('property', tagDef.property);
      }
      tag.setAttribute('content', tagDef.content);
      tag.setAttribute('data-vue-router-controlled', '');

      // Prepend the tag to ensure it's closer to the top of <head>
      headElement.insertBefore(tag, firstChild);
    });
  }

  // Handle og:url separately
  let ogUrlTag = document.createElement('meta');
  ogUrlTag.setAttribute('property', 'og:url');
  ogUrlTag.setAttribute('content', window.location.href);
  ogUrlTag.setAttribute('data-vue-router-controlled', '');
  headElement.insertBefore(ogUrlTag, firstChild);

  // Handle the canonical link
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('data-vue-router-controlled', '');
    // Prepend to make it appear higher in <head>
    headElement.insertBefore(canonicalLink, firstChild);
  }
  canonicalLink.setAttribute('href', window.location.href);

  next();
});

// Mount the app
app.use(store).use(router).mount('#app');
