import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import DashboardHome from './components/DashboardHome.vue';
import PricingPage from './components/PricingPage.vue';
import InvestorsPage from './components/InvestorsPage.vue';
import LoginPage from './components/LoginPage.vue';
import SignupPage from './components/SignupPage.vue';
import TermsPage from './components/TermsPage.vue';
import PrivacyPage from './components/PrivacyPage.vue';
import PrivacyNotice from './components/PrivacyNotice.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import VerifyEmail from './components/VerifyEmail.vue';
import SettingsPage from './components/SettingsPage.vue';
import SearchHome from './components/SearchHome.vue';
import MyLists from './components/DashboardHome.vue';
import ToolsLogin from './components/Reporting/ToolsLogin.vue';

// ------------------------------------------------------------------
// Define the routes
// ------------------------------------------------------------------

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Crowdscore: AI Powered Talent discovery. Find high quality micro influencers and creators.',
      metaTags: [
        {
          name: 'description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          property: 'og:title',
          content: 'Crowdscore: AI Powered Talent discovery. Find high quality micro influencers and creators.'
        },
        {
          property: 'og:description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          name: 'keywords',
          content: 'top social media creators, top instagram creators, top youtube creators, top tiktok creators, top twitch creators, top twitter creators, top facebook creators, top linkedin creators, top pinterest creators, top snapchat creators, top reddit creators'
        }
      ]
    }
  },
  {
    path: '/tools',
    name: 'ToolsLogin',
    component: ToolsLogin,
  },
  {
    path: '/:username',
    name: 'UserProfile',
    component: DashboardHome,
    props: true
},
  {
    path: '/dashboard', 
    name: 'Dashboard',
    component: DashboardHome,
    meta: {
      title: 'Crowdscore AI - Search for the best micro creators in the world. Top Instagram Creators, Top Youtube Creators and more ...',
      metaTags: [
        {
          name: 'description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Search for the best micro creators in the world. Top Instagram Creators, Top Youtube Creators and more ...'
        },
        {
          property: 'og:description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          name: 'keywords',
          content: 'top social media creators, top instagram creators, top youtube creators, top tiktok creators, top twitch creators, top twitter creators, top facebook creators, top linkedin creators, top pinterest creators, top snapchat creators, top reddit creators'
        }
      ]
    }
  },
  {
    path: '/search', 
    name: 'Search',
    component: SearchHome,
    meta: {
      title: 'Crowdscore AI - Search for the best micro creators in the world. Top Instagram Creators, Top Youtube Creators and more ...',
      metaTags: [
        {
          name: 'description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Search for the best micro creators in the world. Top Instagram Creators, Top Youtube Creators and more ...'
        },
        {
          property: 'og:description',
          content: 'Use powerful AI to search for the best micro creators in the world. Find the top Instagram creators, top Youtube creators, top Tiktok creators, top Twitch creators, top Twitter creators, top Facebook creators, top Linkedin creators, top Pinterest creators, top Snapchat creators, top Reddit creators and more ...'
        },
        {
          name: 'keywords',
          content: 'micro influencers, micro creators, social media influencers, social media creators, instagram influencers, youtube influencers, tiktok influencers, twitch influencers, twitter influencers, facebook influencers, linkedin influencers, pinterest influencers, snapchat influencers, reddit influencers, top social media creators, top instagram creators, youtube creators'
        }
      ]
    }
  },
  {
    path: '/top100', 
    name: 'Top100',
    component: DashboardHome,
    meta: {
      title: 'Top Micro Creators, Top Instagram Creators, Top Youtube Creators, Top Tiktok Creators and more ...',
      metaTags: [
        {
          name: 'description',
          content: 'Use powerful AI to quicky find the best micro creators in the world. Find the top Instagram creators, top Youtube creators and more ...'
        },
        {
          property: 'og:title',
          content: 'Top Micro Creators, Top Instagram Creators, Top Youtube Creators, Top Tiktok Creators and more ...'
        },
        {
          property: 'og:description',
          content: 'Use powerful AI to quicky find the best micro creators in the world. Find the top Instagram creators, top Youtube creators and more ...'
        },
        {
          name: 'keywords',
          content: 'top social media creators, top instagram creators, top youtube creators, top tiktok creators, top twitch creators, top twitter creators, top facebook creators, top linkedin creators, top pinterest creators, top snapchat creators, top reddit creators'
        }
      ]
    }
  },
  {
    path: '/mylists', 
    name: 'MyLists',
    component: MyLists
  },
  {
    path: '/investors',
    name: 'Investors',
    component: InvestorsPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Crowdscore AI - Forgot Password',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore forgot password page. Reset your password here.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Forgot Password'
        },
        {
          property: 'og:description',
          content: 'Crowdscore forgot password page. Reset your password here.'
        },
        {
          name: 'keywords',
          content: 'crowdscore, forgot password,reset password,change password,lost password, password recovery'
        }
      ]
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingPage,
    meta: {
      title: 'Crowdscore AI - Signup and Pricing',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Signup and Pricing. Signup for Crowdscore and get access to the best micro influencers in the world.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Signup and Pricing'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Signup and Pricing. Signup for Crowdscore and get access to the best micro influencers in the world.'
        },
        {
          name: 'keywords',
          content: 'Crowdscore Pricing, How much is Crowdscore? Is Crowdscore worth it? Crowdscore signup, sign up, register for Crowdscore, how to create account on crowdscore?'
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      title: 'Crowdscore AI - Login to Crowdscore',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Login page. Login to Crowdscore. How to login to Crowdscore?'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Login to Crowdscore'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Login page. Login to Crowdscore. How to login to Crowdscore?'
        },
        {
          name: 'keywords',
          content: 'Crowdscore login, login to Crowdscore, how to login to Crowdscore, Crowdscore login page'
        }
      ]
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupPage,
    meta: {
      title: 'Crowdscore AI - Signup for Crowdscore AI',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Signup page. Signup for Crowdscore and get access to the best micro influencers in the world.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Signup for Crowdscore AI'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Signup page. Signup for Crowdscore and get access to the best micro influencers in the world.'
        },
        {
          name: 'keywords',
          content: 'Crowdscore Pricing, How much is Crowdscore? Is Crowdscore worth it? Crowdscore signup, sign up, register for Crowdscore, how to create account on crowdscore?'
        }
      ]
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage,
    meta: {
      title: 'Crowdscore AI - Terms of Service',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Terms of Service page. Our terms of service.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Terms of Service'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Terms of Service page. Our terms of service.'
        },
        {
          name: 'keywords',
          content: 'crowdscore, terms of service, terms, terms and conditions, terms of use, terms of agreement, terms of service agreement, terms of service for Crowdscore website and mobile app'
        }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage,
    meta: {
      title: 'Crowdscore AI - Privacy Policy',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Privacy Policy page. How we protect your privacy.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Privacy Policy'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Privacy Policy page. How we protect your privacy.'
        },
        {
          name: 'keywords',
          content: 'crowdscore, privacy policy, privacy, data protection, data privacy, data security'
        }
      ]
    }
  },
  {
    path: '/privacynotice_ca',
    name: 'PrivacyNotice',
    component: PrivacyNotice,
    meta: {
      title: 'Crowdscore AI - Privacy Notice for California Residents',
      metaTags: [
        {
          name: 'description',
          content: 'Crowdscore AI - Privacy Notice page. How we protect your privacy.'
        },
        {
          property: 'og:title',
          content: 'Crowdscore AI - Privacy Notice'
        },
        {
          property: 'og:description',
          content: 'Crowdscore AI - Privacy Notice page. How we protect your privacy.'
        },
        {
          name: 'keywords',
          content: 'crowdscore, privacy policy, privacy, CCPA, data protection, data privacy, data security'
        }
      ]
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage
  },

];

// ------------------------------------------------------------------
// Create the router instance
// ------------------------------------------------------------------

const router = createRouter({
  history: createWebHistory(), // Use HTML5 history mode
  routes, // short for `routes: routes`

});

router.beforeEach((to, from, next) => {
    // Check if navigating to the profile page and from the dashboard
    if (to.path.includes('/profile') && from.path === '/dashboard') {
      // Set a flag in sessionStorage to indicate navigation from dashboard
      //localStorage.setItem('navigatedFromDashboard', true);
    }
    localStorage.setItem('lastRoutePath', from.path);
  next(); 
});

export default router;
