<template>
        <div class="home-banner"> 
                <div class="banner-content">
                        <div class="banner-cta2">
                                <div class="banner-text2">
                                        <b class="heading">AI-powered talent discovery</b>
                                        <div class="subtitle">Discover high quality micro creators and amplify your brand.</div>
                                </div>
                                <div class="buttons2">
                                        <router-link to="/search" class="button-primary-style12"><div class="button-text12">Try Now</div></router-link>
                                        <a href="#section1" class="button-primary-style22"><div class="button-text22">Learn More</div></a>
                                </div>
                                
                        </div>
                </div>
        </div>
</template>

<script>
export default {
  name: 'HomeBanner'
}
</script>