<template>  
  
  <div class="pricing-body">
    
    <SignupCard ref="signupCard" /> 

    <div class="lowercontent-background"></div>

  </div>

</template>

<script>

import SignupCard from '@/components/SignupCard.vue';

export default {
  
  name: 'PricingContent',

  components: {
    SignupCard
  },
  
  methods: {
    
    openSignupCard() {
      this.$refs.signupCard.isVisible = true;
    },
  }
}
</script>