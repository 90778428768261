
<template>
  <div class="main-footer">
        <div class="footer-guest-menu">
          <div class="footer-menu-item">
                    <div class="footer-menu-text">© 2024 Crowdscore Inc.</div>
              </div>
          <div class="footer-menu-item">
                    <div class="footer-menu-text-secondary"><router-link to="/investors">Investors</router-link></div>
              </div>
          <div class="footer-menu-item">
                    <div class="footer-menu-text-secondary"><router-link to="/privacy">Privacy</router-link></div>
              </div>
          <div class="footer-menu-item">
                    <div class="footer-menu-text-secondary"><router-link to="/terms">Terms</router-link></div>
              </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>