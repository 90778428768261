<template>
        <div class="banner-premium"> 
                <div class="banner-content">

                        <div class="pricing-banner-header"> 
                                <img alt="Crowdscore Premium" src="/images/cs-premium-med.png" />
                        </div>
                </div>
        </div>
  </template>
  
  <script>
  export default {
    name: 'PricingBanner'
  }
  </script>