<template>

    <div class="search-body">
        
        <div class="search-content-container" ref="taskPanelDefault">
        
            <div class="search-content-main">
            
                <div class="search-content">
                    
                    <div class="search-headline">Discover high quality micro creators and amplify your brand</div>

                    <div class="task-inputfield" ref="taskInputField">
                        
                        <label class="input-placeholder"
                            v-show="!textAreaValue.trim().length && showPlaceholder">
                            Search by @username, keyword or just tell us what you&apos;re looking for…
                        </label>
                        
                        <textarea :ref="'editInput' + index" class="task-input-text" v-model="textAreaValue"
                            spellcheck="true" @input="handleInput" @keydown.enter="handleEnterKey"
                            @mousedown="handleMouseDown" @focus="handleFocus" @blur="handleBlur" maxlength="200">
                        </textarea>

                        <div class="task-input-button" :class="{ disabled: !isInputFilled }" @click="submitTask"
                            @touchstart="handleTap">
                            <img class="task-input-button-icon" alt="" src="/images/task-input-button.png" />
                        </div>
                    
                    </div>
                    
                    <div class="search-group">
                    
                        <div class="search-column-group">    
                            <div class="search-column-title">Suggested</div>
                            <div class="search-suggestions-frame">
                                <div class="search-grid-container">
                                    <div class="suggestions-text-frame" v-for="prompt in suggestedPrompts" :key="prompt.id" @click="handleSuggestionClick(prompt.prompt)">
                                        <div class="suggestions-text">{{ prompt.prompt }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        <div class="search-column-group">        
                            <div class="search-column-title">Vibe</div>
                            <div class="search-grid-container">
                                <div class="search-tag-badge" v-for="vibe in vibes" :key="vibe.tag" @click="handleVibeClick(vibe.tag)">
                                    <div class="search-tag-text">#{{ vibe.tag }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="search-column-group">
                            <div class="search-column-title">Category</div>
                            <div class="search-grid-container-category">
                                <div class="search-category-text-frame" v-for="category in categories" :key="category.name" @click="handleCategoryClick(category.name)">
                                    <div class="search-category-text">{{ category.name }}</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="lowercontent-background"></div>

            <HomeFooter />

        </div>

    </div>

</template>

<script>
import axios from 'axios';
import { eventBus } from '@/eventBus';
import { inject, computed } from 'vue';
import { useStore, mapState } from 'vuex';
import HomeFooter from '/crowdscore/main/frontend/src/components/HomeFooter.vue';

export default {

    name: 'SearchHome',
    components: {
        HomeFooter
  },

    props: {
        isTaskPanelVisible: Boolean
    },

    setup() {
        const globalState = inject('globalState'); // Inject the global state
        const store = useStore(); // Vuex store for state management
        const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed

        return {
            globalState, // For isLoggedIn
            isSubscribed,
        };
    },

    // ---------------------------------------------------------------------------
    // Data
    // ---------------------------------------------------------------------------

    data() {
        return {
            isInputFilled: false,
            searchInput: '',
            categories: [],
            activeTab: 'discover',
            selectedSuggestion: '',
            activeSubTab: 'suggestions',
            /* activeDropdown: null,
            isDropdownActive: false,*/
            showNewListModal: false,
            newListName: '',
            suggestedPrompts: [],
            vibes: [],
            showDeleteModal: false,
            currentItemToDelete: null,
            savedLists: [],
            newListError: '',
            showSubscribeModal: false,
            showPlaceholder: true,
            textAreaValue: '',
            notSuggestedSearch: false,
            apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
            savedSearches: [],
            windowWidth: window.innerWidth
        };
    },

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },

    // ---------------------------------------------------------------------------
    // Watchers
    // ---------------------------------------------------------------------------

    watch: {
        'savedLists': {
            handler(newSavedLists) {
                newSavedLists.forEach((list, index) => {
                    if (list.focus && this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                        this.$refs[`editInput${index}`][0].focus();
                    }
                });
            },
            deep: true
        },

        textAreaValue(newValue) {
            this.showPlaceholder = !newValue || !newValue.trim();
        },

        activeTab(newVal) {
            if (newVal === 'history') {
                this.fetchSavedSearches();
            }
        },

    },


    // ---------------------------------------------------------------------------
    // Computed
    // ---------------------------------------------------------------------------

    ...mapState(['isSubscribed', 'lastRoutePath']),

    isSubscribed() {
        // Replace with actual logic to determine if the user is subscribed
        return this.$store.getters.isSubscribed;
    },

    mounted() {


        if (this.$refs.taskPanelDefault) {
            const taskPanel = this.$refs.taskPanelDefault;
            taskPanel.addEventListener('wheel', this.preventScroll, { passive: false });
            taskPanel.addEventListener('mousewheel', this.preventScroll, { passive: false });
        }

        // Really hacky way to get search working from top 100
        // This is a temporary solution until we can figure how to get the search to use the router

        // Store the search text from top 100
        const searchText = sessionStorage.getItem('searchText');
        const suggestedSearch = sessionStorage.getItem('suggestedSearch');

        console.log("searchText:", searchText);
        console.log("suggestedSearch:", suggestedSearch);


        if (window.location.pathname === '/dashboard' && (suggestedSearch !== searchText)) {

            if (searchText) {
                // Hide the placeholder if there's searchText in sessionStorage
                this.showPlaceholder = false;
                this.textAreaValue = searchText;
                sessionStorage.removeItem('searchText');
                sessionStorage.removeItem('suggestedSearch');
                this.isInputFilled = true;
            }

        }


        const taskPanel = this.$refs.taskPanelDefault;
        taskPanel.addEventListener('wheel', this.preventScroll, { passive: false });
        taskPanel.addEventListener('mousewheel', this.preventScroll, { passive: false });


        // Read the active tab state from localStorage and set it
        const savedActiveTab = localStorage.getItem('activeTab');
        if (savedActiveTab) {
            this.activeTab = savedActiveTab;
        } else {
            // Default to 'discover' tab if no saved state is found
            this.activeTab = 'discover';
        }

        // Read the active tab state from localStorage and set it
        const savedActiveSubTab = localStorage.getItem('activeSubTab');
        if (savedActiveSubTab) {
            this.activeSubTab = savedActiveSubTab;
        } else {
            // Default to 'discover' tab if no saved state is found
            this.activeSubTab = 'suggestions';
        }


        this.fetchCategories();
        this.fetchSuggestedPrompts();
        this.fetchVibes();
        this.fetchSavedLists();
        eventBus.on('list-updated', this.fetchSavedLists);

        // Attach event listeners to all suggestion elements
        const suggestionElements = document.querySelectorAll('.suggestions-text-frame .suggestions-text');
        if (suggestionElements.length) {
            suggestionElements.forEach(element => {
                element.addEventListener('click', this.handleSuggestionClick);
            });
        }

        //My Lists event bus commented out until we change to stub page...

        //eventBus.on('my-lists-clicked', () => {
        //      this.setActiveTab('lists'); // Set the active tab to 'lists'
        //      if (this.savedLists && this.savedLists.length > 0) {
        //            const firstList = this.savedLists[0];
        //            this.handleListClick(firstList.list_id);
        //      } else {
        //            // Emit an event indicating no lists are available
        //            eventBus.emit('no-lists-available');
        //      }
        //});

        eventBus.on('set-active-tab', this.setActiveTabFromEvent);

    },


    beforeUnmount() {
        const taskPanel = this.$refs.taskPanelDefault;
        if (taskPanel) {
            taskPanel.removeEventListener('wheel', this.preventScroll);
            taskPanel.removeEventListener('mousewheel', this.preventScroll);
        }
        eventBus.off('list-updated', this.fetchSavedLists);
        /* window.removeEventListener('click', this.closeDropdownIfClickedOutside); */

        //  eventBus.off('my-lists-clicked', this.selectListsTab);

        eventBus.off('set-active-tab', this.setActiveTabFromEvent);

    },

    computed: {
        brandId() {
            // Logic to retrieve brandId
            //return 369; // Placeholder, replace with actual logic
            return this.$store.getters.brandId;
        },

        isMobile() {
            return this.windowWidth <= 600;
        }
    },

    // ---------------------------------------------------------------------------
    // Methods
    // ---------------------------------------------------------------------------

    methods: {

        // ---------------------------------------------------------------------------
        // Event handlers
        // ---------------------------------------------------------------------------

        async fetchSavedLists() {
            // Check if brandId is valid (i.e., not null or undefined)
            if (!this.brandId) {
                // console.log("Brand ID is not set. Skipping fetch for saved lists.");
                return;
            }
            try {
                const response = await axios.get(`${this.apiEndpoint}/saved-lists/${this.brandId}`);
                if (response.status === 200) {
                    // console.log("Fetched saved lists:", response.data); 
                    this.savedLists = response.data;
                }
            } catch (error) {
                //   console.error('Error fetching saved lists:', error);
            }
        },

        selectListsTab() {
            this.setActiveTab('lists');
        },

        setActiveTabFromEvent(tabName) {
            this.activeTab = tabName;
        },

        openNewListModalOrSubscribe() {
            if (this.$store.getters.isSubscribed) {
                this.showNewListModal = true;
            } else {
                this.showSubscribeModal = true;
            }
        },

        async addNewList() {


            if (this.newListName.trim() === '') {
                this.newListError = 'Please enter a name for the list.';
                return;
            }

            // Check if the list name already exists in savedLists
            const isListNameExist = this.savedLists.some(list => list.name.toLowerCase() === this.newListName.toLowerCase());
            if (isListNameExist) {
                this.newListError = 'List name already in use.';
                return;
            }

            try {
                const response = await axios.post(`${this.apiEndpoint}/create-list`, {
                    brand_id: this.brandId,
                    list_name: this.newListName
                });

                if (response.data && response.data.list_id) {
                    const newList = {
                        list_id: response.data.list_id,
                        name: this.newListName,
                        // Assuming the list was created "now", for display purposes
                        created_date: new Date().toISOString(),
                        // Add any additional properties you expect for lists here
                    };

                    // Insert the new list at the beginning of the array to respect descending sort by created_date
                    this.savedLists.unshift(newList);

                    // Reset the modal state
                    this.newListName = '';
                    this.newListError = '';
                    this.showNewListModal = false;
                } else {
                    throw new Error('List ID not returned from the server');
                }
            } catch (error) {
                // console.error('Error creating new list:', error);
                this.newListError = 'An unexpected error occurred while creating the list.';
            }
        },

        // Method to close the modal and clear errors
        closeNewListModal() {
            this.newListName = '';
            this.newListError = '';
            this.showNewListModal = false;
        },


        confirmDelete(list) {
            //  console.log("Confirm delete list_id:", list.list_id);
            this.currentItemToDelete = list;
            this.showDeleteModal = true;
        },

        async deleteItem() {
            const listId = this.currentItemToDelete.list_id;
            //  console.log("Deleting list_id:", listId);

            if (listId) {
                try {
                    const response = await axios.post(`${this.apiEndpoint}/delete-list`, {
                        brand_id: this.brandId,
                        list_id: listId,
                    });

                    if (response.status === 200) {
                        // Filter out the deleted list using list_id
                        this.savedLists = this.savedLists.filter(list => list.list_id !== listId);
                        // Reset the currentItemToDelete and hide the modal
                        this.currentItemToDelete = null;
                        this.showDeleteModal = false;
                    } else {
                        // Handle non-successful responses
                        //    console.error('Failed to delete the list:', response.status, response.statusText);
                        // Optionally, show an error message to the user
                    }
                } catch (error) {
                    //   console.error('Error deleting the list:', error);
                    // Handle error here, potentially show an error message to the user
                }
            } else {
                //  console.error('Attempted to delete a list without a valid id.');
                // Optionally, show an error message to the user
            }
        },

        enableEditing(list) {
            this.savedLists.forEach(l => {
                l.isEditing = false; // Ensure only one item is editable at a time
                l.focus = false; // Reset focus for all items
            });
            list.isEditing = true;
            list.editText = list.name; // Assuming 'name' is the property holding the list name
            this.$nextTick(() => {
                const index = this.savedLists.indexOf(list);
                if (this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                    this.$refs[`editInput${index}`][0].focus();
                }
            });
        },

        async submitEdit(list) {
            try {
                // Replace 'yourBrandIdHere' with the actual brand ID.
                const brandId = this.brandId; // Assuming this.brandId is already defined and holds the current brand ID
                const response = await axios.post(`${this.apiEndpoint}/update-list`, {
                    brand_id: brandId,
                    list_name: list.editText,
                    list_id: list.list_id
                });

                if (response.status === 200) {
                    // Update the list name in the UI only if the server update was successful
                    list.name = list.editText;
                    //  console.log("List name updated successfully:", response.data);
                } else {
                    // Handle server responses other than 200 OK
                    //   console.error("Failed to update the list name:", response.status, response.statusText);
                    // Optionally, show an error message to the user
                }
            } catch (error) {
                //   console.error('Error updating the list name:', error);
                // Handle error, potentially show an error message to the user
            }

            // Exit editing mode
            list.isEditing = false;
        },
        cancelEdit(list) {
            list.isEditing = false; // Exit editing mode without saving changes
            // No need to reset editText as it's no longer shown
        },


        handleSuggestionClick(search) {
            localStorage.setItem('search', search);
            localStorage.setItem('searchType', 'keyword');


            this.$router.push({ path: '/dashboard?searchinput=', query: { search } })


            this.selectedSuggestion = search;
            this.performSearch();
            this.$emit('suggestedSearch', search);
            this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
            sessionStorage.setItem('suggestedSearch', search);

            if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(search)}`;
            }

        },

        handleCategoryClick(categoryId) {
            localStorage.setItem('category', categoryId);
            localStorage.setItem('searchType', 'category');


            this.$router.push({ path: '/dashboard', query: { category: categoryId } }).catch(err => console.error(err));


            this.$emit('categorySearch', categoryId);
            this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
            if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                window.location.href = `${window.location.origin}/dashboard?category=${encodeURIComponent(categoryId)}`;
            }

        },
        handleVibeClick(vibeId) {
            localStorage.setItem('vibe', vibeId);
            localStorage.setItem('searchType', 'vibe');

            this.$router.push({ path: '/dashboard', query: { vibe: vibeId } }).catch(err => console.error(err));


            this.$emit('vibeSearch', vibeId);
            this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
            if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                window.location.href = `${window.location.origin}/dashboard?vibe=${encodeURIComponent(vibeId)}`;
            }

        },

        handleListClick(listId) {
            localStorage.setItem('list', listId);
            localStorage.setItem('searchType', 'list');

            this.$router.push({ path: '/dashboard', query: { list: listId } }).catch(err => console.error(err));

            const listName = this.savedLists.find(list => list.list_id === listId)?.name || 'Unknown List';
            this.$emit('listSearch', listId, listName);
            this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
            localStorage.setItem('listname', listName);
            if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                window.location.href = `${window.location.origin}/dashboard?list=${encodeURIComponent(listId)}`;
            }



        },

        // Fisher-Yates (Knuth) shuffle algorithm to randomize the array
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // swap elements
            }
            return array;
        },



        async fetchSuggestedPrompts() {
            try {
                const response = await fetch(`${this.apiEndpoint}/suggested-prompts`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const prompts = await response.json();
                this.prompts = this.shuffleArray(prompts);
                // Update here to only keep the first 3 prompts
                this.suggestedPrompts = prompts.slice(0, 3);
            } catch (error) {
                // Uncomment this line if you want to see error logs in the console
                // console.error('Error fetching suggested prompts:', error);
            }
        },

        handleEnterKey(event) {
            event.preventDefault(); // Prevents the default line break in textarea
            let sanitizedInput = this.textAreaValue.replace(/\n/g, " "); // Replace line breaks with spaces
            this.performSearch(sanitizedInput); // Calls performSearch with the current input text
        },

        performSearch(search = this.selectedSuggestion) {
            let query = search.trim();

            if (query.length > 0) {

                this.$router.push({ path: '/dashboard?searchinput=', query: { search } })

                this.$emit('search', query);
                this.$emit('search-performed');

                if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                    window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(query)}`;
                    sessionStorage.setItem('searchText', query);
                }
            } else {
                //console.error('Search input is empty.');
            }
        },

        //This is a hack to use the keyboard's enter key method to trigger the touch event on mobile devices
        handleTap() {
            // Call handleEnterKey with a dummy event object
            this.handleEnterKey({ preventDefault: () => { } });
        },

        handleResize() {
            this.windowWidth = window.innerWidth;
        },

        // ---------------------------------------------------------------------------
        // Tab section toggle
        // ---------------------------------------------------------------------------

        setActiveTab(tabName) {
            this.activeTab = tabName;
            localStorage.setItem('activeTab', tabName);

            if (tabName === 'history') {
                this.fetchSavedSearches();
            }
        },

        async fetchSavedSearches() {
            try {
                const token = this.getJwtToken();
                const response = await axios.get(`${this.apiEndpoint}/saved-searches`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Implement getJwtToken method or replace with actual token
                    }
                });
                if (response.data) {
                    this.savedSearches = response.data;
                }
            } catch (error) {
                console.error('Error fetching saved searches:', error);
                // Handle errors (e.g., token expired, network issues, etc.)
            }
        },

        getJwtToken() {
            // Implement logic to retrieve JWT token stored in localStorage, Vuex store, or however you manage authentication state
            return localStorage.getItem('jwtToken');
        },

        setActiveSubTab(subTabName) {
            this.activeSubTab = subTabName;
            localStorage.setItem('activeSubTab', subTabName);
        },

        // ---------------------------------------------------------------------------
        // Handle input
        // ---------------------------------------------------------------------------

        handleInput(event) {
            this.searchInput = event.target.value; // Update the searchInput data property
            this.isInputFilled = this.searchInput.length > 0;
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;

            // Adjust task-inputfield height
            const inputField = this.$refs.taskInputField;
            inputField.style.height = 'auto';
            inputField.style.height = event.target.scrollHeight + 'px';
        },
        handleFocus() {
            const inputField = this.$refs.taskInputField;
            inputField.classList.add('task-inputfield-focus-glow');
        },
        handleBlur() {
            const inputField = this.$refs.taskInputField;
            inputField.classList.remove('task-inputfield-focus-glow');
        },
        handleMouseDown(event) {
            if (!this.isInputFilled) {
                // Prevent default behavior
                event.preventDefault();
                // Focus the textarea
                this.$nextTick(() => {
                    event.target.focus();
                    event.target.setSelectionRange(0, 0);
                });
            }
        },

        // ---------------------------------------------------------------------------
        // Fetch vibes
        // ---------------------------------------------------------------------------

        async fetchVibes() {
            try {
                const response = await fetch(`${this.apiEndpoint}/vibe-list`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const vibes = await response.json();
                this.vibes = this.shuffleArray(vibes);
                this.vibes = vibes.slice(0, 6);
            } catch (error) {
                //      console.error('Error fetching vibes:', error);
            }
        },

        // ---------------------------------------------------------------------------
        // Fetch categories
        // ---------------------------------------------------------------------------

        async fetchCategories() {
            try {
                const response = await fetch(`${this.apiEndpoint}/category-list`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let allCategories = await response.json();

                // Filter out 'Misc' and 'Celebrity' categories
                allCategories = allCategories.filter(category => category.name !== 'Misc' && category.name !== 'Celebrities');

                // Shuffle the filtered categories
                this.shuffleArray(allCategories);

                // Limit to 8 random categories
                this.categories = allCategories.slice(0, 8);
            } catch (error) {
                // Uncomment this line if you want to see error logs in the console
                // console.error('Error fetching categories:', error);
            }
        },

        // ---------------------------------------------------------------------------
        // Submit task
        // ---------------------------------------------------------------------------

        submitTask() {
            let sanitizedInput = this.searchInput.replace(/\n/g, " "); // Replace line breaks with spaces
            if (this.searchInput.length > 0) {
                console.log("Emitting search input:", sanitizedInput); // Debug log
                this.$emit('search', sanitizedInput);
                this.$emit('search-performed'); //toggles off taskpanel when search is triggered on mobile
                this.performSearch(sanitizedInput);

                if ((window.location.pathname === '/top100' || window.location.pathname === '/mylists') && window.location.search === '') {
                    window.location.href = `${window.location.origin}/dashboard?search=${encodeURIComponent(this.searchInput)}`;
                    sessionStorage.setItem('searchText', this.searchInput);
                }



            } else {
                //    console.error('Search input is empty.');
            }

            // ---------------------------------------------------------------------------
            // Lifecycle hooks
            // ---------------------------------------------------------------------------

            this.$nextTick(() => {
                if (this.$refs.textarea) {
                    this.$refs.textarea.blur();
                }
            });

        }
    }

}
</script>