<template>
      
      <div class="header-guest">    
            
            <div class="header-container-left">
                  <div class="header-left-menuitem" @click="toggleTaskPanel"> 
                        <div class="header-taskpanel-icon"><img src="/images/icon-header-taskpanel.png" alt=""></div>
                  </div>

                  <div class="header-left-menuitem"></div> <!-- blank spacer -->
            </div>
            
            <div class="header-guest-logo">
                  <router-link to="/"><img class="db-crowdscore-logo-icon"
                              :src="require('@/assets/crowdscore-logo.png')" alt="Logo"></router-link>
            </div>

            <div class="header-container-right">
                  
                  <router-link to="/search">
                        <div class="header-search"> 
                              <div class="header-search-icon"><img src="/images/icon-header-search.png" alt=""></div>
                        </div>
                  </router-link>
                  
                  <div class="header-guest-menu">

                        
                        <!-- Dropdown: logged out -->
                        
                        <div v-if="!globalState.isLoggedIn" class="db-user-avatar2-guest" @click="toggleDropdown"><img src="/images/icon-loggedout-burger.png" alt=""></div>
                        
                        <div v-if="isDropdownVisible && !globalState.isLoggedIn" class="dropdown-menu-banner" @mouseleave="hideDropdown">
                        
                              <router-link to="/top100" @click="hideDropdown"> 
                                    <div class="dropdown-item-group"> 
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-top100.png" alt=""></div>
                                          <div class="dropdown-item-text">Top 100</div>
                                    </div>
                              </router-link>
                        
                              <!-- <router-link to="/dashboard" @click="hideDropdown"> 
                                    <div class="dropdown-item-group"> 
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-dashboard.png" alt=""></div>
                                          <div class="dropdown-item-text">Dashboard</div> 
                                    </div>
                              </router-link>  -->   
                              
                              <router-link to="/pricing" @click="hideDropdown"> 
                                    <div v-if="!isSubscribed" class="dropdown-item-group"> 
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-pricing.png" alt=""></div>
                                          <div class="dropdown-item-text">Pricing</div>
                                    </div>
                              </router-link>
                        
                              
                              <div class="dropdown-item-group">
                                    <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-login.png" alt=""></div>
                                    <div class="dropdown-item-text" @click="openLoginModal">Login</div>
                                    <LoginModal ref="loginModal" />
                              </div>
                              

                        </div>


                        <!-- Dropdown: logged in and subscribed -->

                        <div v-if="globalState.isLoggedIn || isSubscribed" class="db-user-avatar2" @click="toggleDropdown"><img
                                    src="/images/icon-avatar.png" alt=""></div>
                        <div v-if="isDropdownVisible && globalState.isLoggedIn" class="dropdown-menu-banner"
                              @mouseleave="hideDropdown">
                              
                              <div class="dropdown-header">
                                    <div class="dropdown-header-name">{{ emailPrefix }}</div>
                                    <div class="dropdown-header-email">{{ userEmail }}</div>
                              </div>
                              
                              <hr> <!-- Visual divider -->
                              
                              <router-link to="/top100" @click="hideDropdown"> 
                                    <div class="dropdown-item-group"> 
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-top100.png" alt=""></div>
                                          <div class="dropdown-item-text">Top 100</div>
                                    </div>
                              </router-link>

                              <router-link to="/mylists">
                                    <div class="dropdown-item-group">  
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-lists.png" alt=""></div>
                                          <div class="dropdown-item-text">My Lists</div>
                                    </div>
                              </router-link>

                              <router-link to="/settings" @click="hideDropdown">
                                    <div class="dropdown-item-group"> 
                                          <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-settings.png" alt=""></div>
                                          <div class="dropdown-item-text">Settings</div>
                                    </div>
                              </router-link>
                              
                              <hr> <!-- Visual divider -->
                              
                              <div class="dropdown-item-group" @click="logoutAndHide">  
                                    <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-logout.png" alt=""></div>
                                    <div class="dropdown-item-text">Logout</div>
                              </div>
                        
                        </div>

                  </div>

            </div>
      
      </div>

      <TaskPanel 
            :is-task-panel-visible="isTaskPanelVisible" 
            @search-performed="handleSearchPerformed"
      />
      <div class="page-blocker" v-if="isTaskPanelVisible" @click="toggleTaskPanel"></div> <!-- Darkening blocker -->

</template>

<script>
import { eventBus } from '@/eventBus';
import { inject, computed } from 'vue';
import { useStore } from 'vuex';
import LoginModal from './LoginPage.vue';
import TaskPanel from './Dashboard/TaskPanel.vue';

export default {
      name: 'DashboardHeaderM',
      components: {
            LoginModal,
            TaskPanel
      },

      data() {
            return {
                  isDropdownVisible: false,
                  isTaskPanelVisible: false,
                  // ... other data properties ...
            };
      },

      setup() {
            const globalState = inject('globalState'); // Assuming isLoggedIn is still managed here
            const store = useStore(); // Vuex store for state management
            const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed
            const userEmail = computed(() => store.getters.email);

            const emailPrefix = computed(() => {
                  const email = userEmail.value;
                  const prefix = email.split('@')[0];
                  return prefix.charAt(0).toUpperCase() + prefix.slice(1);
            });

            return {
                  globalState, // For isLoggedIn
                  isSubscribed,
                  userEmail,
                  emailPrefix,
            };
      },

      methods: {

            toggleDropdown() {
                  this.isDropdownVisible = !this.isDropdownVisible;
            },

            hideDropdown() {
                  this.isDropdownVisible = false;
            },

            handleOutsideClick(event) {
                  if (!this.$el.contains(event.target)) {
                        this.isDropdownVisible = false;
                        window.removeEventListener('click', this.handleOutsideClick);
                  }
            },

            logoutAndHide() {
                  this.logout();
                  this.hideDropdown();
            },

            // Login modal doesn't display anytime a method to dismiss the dropdown is implementd. Needs a fix.
            //loginAndHide() {
            //      this.openLoginModal();
            //      this.hideDropdown();
            //},

            logout() {
                  // Clear JWT tokens from localStorage
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('refreshToken');
                  localStorage.removeItem('userEmail');
                  localStorage.removeItem('brandId');

                  // Update Vuex state for isSubscribed
                  //this.$store.commit('setIsSubscribed', false);
                  // Dispatch the logout action to reset Vuex state
                  this.$store.dispatch('logout');

                  // Update global state to indicate the user is logged out
                  if (this.globalState.updateLoginStatus) {
                        this.globalState.updateLoginStatus(false, false);
                  }

                  // Redirect to home page or login page
                  //this.$router.push('/dashboard');
                  //this.$router.push('/dashboard').then(() => {
                  //      window.location.href = window.location.origin + '/dashboard';
                  //});
                  window.location.reload();

                  // Logic to handle user logout
                  this.isDropdownVisible = false;

            },

            //  openSignUpModal() {
            //  this.$refs.signUpModal.isVisible = true;
            // },

            openLoginModal() {
                  this.$refs.loginModal.isVisible = true;
            },

            beforeDestroy() {
                  window.removeEventListener('click', this.handleOutsideClick);
            },

            reloadPage() {
                  // Navigate to the route without any query parameters
                  this.$router.push({ path: '/dashboard', query: {} }).then(() => {
                        // Force reload the page to the base URL of '/dashboard'
                        window.location.href = window.location.origin + '/dashboard';
                  }).catch(err => {
                        // Handle navigation failure, ignore if it's a duplicate navigation error
                        if (err.name !== 'NavigationDuplicated' && err.name !== 'NavigationDuplicated') {
                              console.error(err);
                        }
                  });
                  // Reload the page
                  window.location.reload();
            },

            toggleTaskPanel() {
                  this.isTaskPanelVisible = !this.isTaskPanelVisible;

                  // Emit an event to set the active tab to 'discover' (i.e., the search tab)
                  if (this.isTaskPanelVisible) {
                        eventBus.emit('set-active-tab', 'discover');
                  }
            },

            //toggles off taskpanel when search is triggered on mobile
            handleSearchPerformed() {
                  this.isTaskPanelVisible = false;
            },

            // My Lists event bus commented out until we change to stub page...

            // handleMyListsClick() {
            //      this.toggleTaskPanel();
            //      eventBus.emit('my-lists-clicked');
            //      this.$nextTick(() => {
            //            this.isTaskPanelVisible = true;
            //      });
            //},
      }
};

</script>