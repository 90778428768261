<template>
    
    <div v-if="modalVisible" class="modal-backdrop" @click="closeModal">
      
        <div class="modal-content-graphs" @click.stop>
       
        <span @click="closeModal" class="close">&times;</span>
        
        <div class="modal-body-graphs">
            
            <div class="graph-container">
                

                <!-------------  Tabs  ---------------->
                
                <div class="graph-header">
                    <div :class="{'graph-header-item': true, 'active': activeTab === 'Followers'}" @click="selectTab('Followers', 0)">Followers</div>
                    <div :class="{'graph-header-item': true, 'active': activeTab === 'Estimated CPP'}" @click="selectTab('Estimated CPP', 1)">Estimated CPP</div>
                    <div :class="{'graph-header-item': true, 'active': activeTab === 'Engagement'}" @click="selectTab('Engagement', 2)">Engagement</div>
                    <div :class="{'graph-header-item': true, 'active': activeTab === 'Rank'}" @click="selectTab('Rank', 3)">Rank</div>
                </div>
                

                <!-------------  Graphs  ----------------------------------------------->

                <div class="graph-content"

                    @touchstart="handleTouchStart"
                    @touchmove="handleTouchMove"
                    @touchend="handleTouchEnd">
                    
                    <!-------------  Followers (guest)  ---------------->
                    <transition :name="slideDirection">
                        <div v-if="activeTab === 'Followers'" class="graph-content-item">
                            <GraphFollowers :profileId="profileId"></GraphFollowers>
                        </div>
                    </transition>
                    
                    <!-------------  Estimated CPP (subscriber)  ---------------->
                    <transition :name="slideDirection">
                        <div v-if="activeTab === 'Estimated CPP'" class="graph-content-item">
                            <template v-if="isSubscribed">
                                <GraphCPP :profileId="profileId"></GraphCPP>
                            </template>
                            <template v-else><router-link to="/pricing" class="inline-text-link">Upgrade to see this graph</router-link></template>
                        </div>
                    </transition>
                    
                    <!-------------  Engagement (subscriber)  ---------------->
                    <transition :name="slideDirection">
                        <div v-if="activeTab === 'Engagement'" class="graph-content-item">
                            <template v-if="isSubscribed">
                                <GraphEngagement :profileId="profileId"></GraphEngagement>
                            </template>
                            <template v-else><router-link to="/pricing" class="inline-text-link">Upgrade to see this graph</router-link></template>
                        </div>
                    </transition>
                    
                    <!-------------  Rank (subscriber)  ---------------->
                    <transition :name="slideDirection">
                        <div v-if="activeTab === 'Rank'" class="graph-content-item">
                            <template v-if="isSubscribed">
                                <GraphRank :profileId="profileId"></GraphRank>
                            </template>
                            <template v-else><router-link to="/pricing" class="inline-text-link">Upgrade to see this graph</router-link></template>
                        </div>
                    </transition>

                </div>


                <!-------------  Explainer text  ---------------->

                <div v-if="activeTab === 'Followers'" class="graph-basetext">Followers lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <div v-if="activeTab === 'Estimated CPP'" class="graph-basetext">Cost Per Post is the estimated value of each piece of content a creator posts 
                    to their channel based on several factors like engagement, growth, reach, competitive analysis, market conditions, etc.
                </div>

                <div v-if="activeTab === 'Engagement'" class="graph-basetext">Engagement lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam 
                    nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <div v-if="activeTab === 'Rank'" class="graph-basetext">Rank lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>
            
            </div>

        </div>

      </div>

    </div>
    
</template>
  
<script>
  
    import { useStore, mapState } from 'vuex';
    import { inject, computed } from 'vue';
    import GraphFollowers from './GraphFollowers.vue';
    import GraphCPP from './GraphCPP.vue';
    import GraphEngagement from './GraphEngagement.vue';
    import GraphRank from './GraphRank.vue';

    export default {
        
        name: "CreatorGraphs",
        
        components: {
            GraphFollowers,
            GraphCPP,
            GraphEngagement,
            GraphRank
    },
        
        props: {
            
            modalVisible: {
                type: Boolean,
                default: false
            },

            initialActiveTab: {
                type: String,
                default: 'Followers' // Default to 'Followers'
            },
            profileId: { 
            type: String,
            required: true
        }

        },

        setup() {
            const globalState = inject('globalState'); // Inject the global state
            const store = useStore(); // Vuex store for state management
            const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed

            return {
                  globalState, // For isLoggedIn
                  isSubscribed,
            };
        },

        watch: {
            
            initialActiveTab(newVal) {
                if (newVal) {
                    this.activeTab = newVal;
                }
            }

        },

        data() {
            return {
                activeTab: this.initialActiveTab,
                currentTabIndex: 0,
                previousTabIndex: 0,
                startX: 0, //the following 5 are for mobile swipe gesture on the graphs
                startY: 0,
                endX: 0,
                endY: 0,
                startTime: 0,
            };
        },
        
        emits: ['update:isVisible'],
        
        methods: {
        
            closeModal() {
                this.$emit('update:modalVisible', false);
            },

            selectTab(tabName, tabIndex) {
                this.previousTabIndex = this.currentTabIndex;
                this.currentTabIndex = tabIndex;
                this.activeTab = tabName;
            },

            handleTouchStart(event) {
                this.startX = event.touches[0].clientX;
                this.startY = event.touches[0].clientY;
                this.startTime = Date.now();
            },
            
            handleTouchMove(event) {
                this.endX = event.touches[0].clientX;
                this.endY = event.touches[0].clientY;
            },
            
            handleTouchEnd() {
                const deltaX = this.endX - this.startX;
                const deltaY = this.endY - this.startY;
                const deltaTime = Date.now() - this.startTime;

                const velocity = Math.sqrt(deltaX * deltaX + deltaY * deltaY) / deltaTime;
                const horizontalMove = Math.abs(deltaX) > Math.abs(deltaY);

                if (velocity > 0.3 && horizontalMove) { // Check if swipe was quick and mostly horizontal
                    if (deltaX > 50) { // Swipe right
                        this.previousTab();
                    } else if (deltaX < -50) { // Swipe left
                        this.nextTab();
                    }
                }
            },
            
            nextTab() {
                const tabs = ['Followers', 'Estimated CPP', 'Engagement', 'Rank'];
                if (this.currentTabIndex < tabs.length - 1) {
                    this.selectTab(tabs[this.currentTabIndex + 1], this.currentTabIndex + 1);
                }
            },
            
            previousTab() {
                const tabs = ['Followers', 'Estimated CPP', 'Engagement', 'Rank'];
                if (this.currentTabIndex > 0) {
                    this.selectTab(tabs[this.currentTabIndex - 1], this.currentTabIndex - 1);
                }
            }

        },

        computed: {
            
            slideDirection() {
                return this.currentTabIndex > this.previousTabIndex ? 'slide-right' : 'slide-left';
            },

            ...mapState(['isSubscribed', 'lastRoutePath']),

        },

    }
  
</script>