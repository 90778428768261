<template>

  <div class="signup-modal" v-if="isVisible">
      
    <div class="signup-modal-content">

      <div class="pricing-card-bullets-signup">
          
        <div class="pricing-card-content-heading-signup">Via sign up button heading</div>  
        
        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">Search our complete database of creators</div>
        </div>

        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">Unlimited AI-powered search prompts</div>
        </div>

        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">Build campaign rosters with saved lists</div>
        </div>

        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">Unlock contact info</div>
        </div>

        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">Unlock key insights, stats and data analysis</div>
        </div>

        <div class="pricing-bullet-item">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon">
          <div class="pricing-text-content-signup">View recently posted content</div>
        </div>

      </div>

    </div>  

    <SignupCard @close="closeModal" />

  </div>

</template>

<script>

import SignupCard from './SignupCard.vue';
export default {
  
  components: {
    SignupCard
  },

  data() {
    return {
      isVisible: false,
      currentPage: 1
    };
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    }
  }
};
</script>
