
<template>
    <div>
      <div><SettingsContent /> </div>
    </div>
  </template>
  
  <script>
  import SettingsContent from './Settings/SettingsContent.vue';
  
  export default {
    name: 'App',
    components: {
      SettingsContent,
    },
  
  }
  </script>