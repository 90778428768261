<template>
    
    <div class="db-lists-container">

        <div class="mylists-block-header">
            
            <div class="db-block-header-info">
                <div class="db-block-header-info-text">My Lists</div>
            </div>

            <div class="button-action-style2" @click="openNewListModalOrSubscribe">
                <img src="/images/icon-newlist.png" alt="New List">
            
            </div>

        </div>

        <transition-group name="list" class="lists-container" tag="div">
            
            <div v-for="list in savedLists" :key="list.list_id" class="mylist-box" @click="handleListClick(list.list_id)">
                    
                <div class="mylist-item" :class="{ 'editing': list.isEditing }">
                    <div v-if="!list.isEditing" class="mylist-header">
                        <a class="mylist-name">{{ list.name }}</a>
                        <div class="mylist-actions">
                            <div class="mylist-edit" @click.stop="enableEditing(list)">
                                <img src="/images/icon-edit-textinput.png" alt="Edit List">
                            </div>
                            <div class="mylist-delete" @click.stop="confirmDelete(list)">
                                <img src="/images/icon-delete-result.png" alt="Delete List">
                            </div>
                        </div>
                    </div>

                    <div v-else class="mylist-edit-container">
                        <!-- Editing input field -->
                        <input v-model="list.editText" class="mylist-edit-input"
                            :ref="'editInput' + savedLists.indexOf(list)" @click.stop="" @keyup.enter.prevent.stop="submitEdit(list)">
                        <div class="mylist-edit-actions">
                            <div class="mylist-edit-submit" @click.stop="submitEdit(list)"> <img
                                        src="/images/icon-editlist-submit.png" alt="Submit Edit"></div>
                            <!-- <div class="list-edit-cancel" @click="cancelEdit(item)"> <img src="/images/icon-editlist-cancel.png" alt="Cancel Edit"></div> -->
                        </div>
                    </div>

                </div>

                
                
                <!-- Creators Display -->
                
                <div v-if="isLoadingCreators" class="mylist-grid-placeholder">
                    <img class="mylist-loading-spinner" alt="Loading…" src="/images/loading-spinner.gif">
                </div>
                
                <div v-if="!isLoadingCreators && listCreators[list.list_id] && listCreators[list.list_id].creators.length > 0" class="mylist-grid">
                
                    <!-- Iterate over chunks to create rows -->
                    <div class="mylist-creator-row" v-for="(row, index) in listCreators[list.list_id].creators"
                        :key="index">
                        <div v-for="creator in row" :key="creator.ig_id" class="mylist-creator-info">
                            <img :src="`${spacesBaseUrl}${creator.ig_profile_image_path}`" alt="Profile Image"
                                class="mylist-creator-photo">
                        </div>
                    </div>

                </div>

                <div v-if="!isLoadingCreators && (!listCreators[list.list_id] || listCreators[list.list_id].creators.length === 0)" class="mylist-grid-placeholder">
                    <div class="mylist-grid-placeholder-text">No creators yet</div>
                </div>

                <div class="mylist-stats">    
            
                    <div class="total-count">
                        Creators: <span class="mylist-stat-result"> {{ listCreators[list.list_id]?.totalCount || '0' }} </span>
                    </div>

                    <div class="total-count">
                        Engagement: <span class="mylist-stat-result"> {{ listCreators[list.list_id]?.metrics ? listCreators[list.list_id].metrics[0].engagement : '-' }} </span>
                    </div>
                    
                    <div class="total-count">
                        Average CPP: <span class="mylist-stat-result"> {{ listCreators[list.list_id]?.metrics ? listCreators[list.list_id].metrics[1].cpp : '-' }} </span>
                    </div>
                    
                    <div class="total-count">
                        Reach: <span class="mylist-stat-result"> {{ listCreators[list.list_id]?.metrics ? listCreators[list.list_id].metrics[2].reach : '-' }} </span>
                    </div>

                </div>

            </div>
        
        </transition-group>

    </div>

    <div v-if="showNewListModal" class="alertmodal">
        <div class="alertmodal-content">
            <div class="alertmodal-heading">Create a new list</div>
            <input class="alertmodal-input" type="text" v-model="newListName"
                    placeholder="Enter a list name…" @focus="newListError = ''">
            <div class="error-message3" v-if="newListError">{{ newListError }}</div>
            <div class="alertmodal-actions">
                    <div @click="addNewList" class="alertmodal-submit">Save</div>
                    <div @click="closeNewListModal" class="alertmodal-cancel">Cancel</div>
            </div>
        </div>
    </div>

    <div v-if="showDeleteModal" class="alertmodal">
        <div class="alertmodal-content">
            <div class="alertmodal-heading">Are you sure you want to delete {{ currentItemToDelete.name }}?
            </div>
            <div class="alertmodal-actions">
                <div @click="deleteItem" class="alertmodal-submit-destructive">Delete</div>
                <div @click="showDeleteModal = false" class="alertmodal-cancel">Cancel</div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { eventBus } from '@/eventBus';

export default {

    name: 'MyLists',

    data() {
        return {
            savedLists: [],
            listCreators: {},
            // Base URL for the DigitalOcean Spaces bucket
            spacesBaseUrl: 'https://cdn-crowdscore.nyc3.cdn.digitaloceanspaces.com/',
            apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
            showNewListModal: false,
            newListName: '',
            showDeleteModal: false,
            currentItemToDelete: null,
            textAreaValue: '',
            newListError: '',
            isLoadingCreators: true,
        };

    },

    watch: {
        '$route'(to) {
            if (to.path === '/mylists') {
                this.fetchSavedLists();
            }
        },

        'savedLists': {
            handler(newSavedLists) {
                newSavedLists.forEach((list, index) => {
                        if (list.focus && this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                            this.$refs[`editInput${index}`][0].focus();
                        }
                });
            },
            deep: true
        },

        textAreaValue(newValue) {
            this.showPlaceholder = !newValue || !newValue.trim();
        },
    },

    computed: {
        brandId() {
            // Logic to retrieve brandId
            //return 369; // Placeholder, replace with actual logic
            return this.$store.getters.brandId;
        },
    },


    methods: {

        openNewListModalOrSubscribe() {
                  if (this.$store.getters.isSubscribed) {
                        this.showNewListModal = true;
                  } else {
                        this.showSubscribeModal = true;
                  }
            },

        chunkArray(myArray, chunk_size) {
            var results = [];

            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size));
            }

            return results;
        },

        async addNewList() {
            if (this.newListName.trim() === '') {
                this.newListError = 'Please enter a name for the list.';
                return;
            }

            if (this.savedLists.some(list => list.name.toLowerCase() === this.newListName.toLowerCase())) {
                this.newListError = 'List name already in use.';
                return;
            }

            try {
                const response = await axios.post(`${this.apiEndpoint}/create-list`, {
                        brand_id: this.brandId,
                        list_name: this.newListName
                });

                if (response.data && response.data.list_id) {
                    const newList = {
                        id: response.data.list_id,
                        name: this.newListName,
                        containsProfile: false,
                        // Optionally set created_date to now
                        created_date: new Date().toISOString()
                    };

                    // Instead of pushing, unshift to add the new list at the beginning
                    this.savedLists.unshift(newList);
                    eventBus.emit('list-updated');

                    // Clear the new list modal state
                    this.newListName = '';
                    this.newListError = '';
                    this.showNewListModal = false;
                } else {
                        throw new Error('List ID not returned from the server');
                }
            } catch (error) {
                this.newListError = 'An error occurred while creating the list.';
            }
        },

        // Method to close the modal and clear errors
        closeNewListModal() {
            this.newListName = '';
            this.newListError = '';
            this.showNewListModal = false;
        },

        confirmDelete(list) {
            //  console.log("Confirm delete list_id:", list.list_id);
            this.currentItemToDelete = list;
            this.showDeleteModal = true;
        },

        async deleteItem() {
            const listId = this.currentItemToDelete.list_id;
            //  console.log("Deleting list_id:", listId);

            if (listId) {
                try {
                        const response = await axios.post(`${this.apiEndpoint}/delete-list`, {
                            brand_id: this.brandId,
                            list_id: listId,
                        });

                        if (response.status === 200) {
                            // Filter out the deleted list using list_id
                            this.savedLists = this.savedLists.filter(list => list.list_id !== listId);
                            // Reset the currentItemToDelete and hide the modal
                            this.currentItemToDelete = null;
                            this.showDeleteModal = false;
                        } else {
                            // Handle non-successful responses
                            //    console.error('Failed to delete the list:', response.status, response.statusText);
                            // Optionally, show an error message to the user
                        }
                } catch (error) {
                        //   console.error('Error deleting the list:', error);
                        // Handle error here, potentially show an error message to the user
                }
            } else {
                //  console.error('Attempted to delete a list without a valid id.');
                // Optionally, show an error message to the user
            }
        },

        enableEditing(list) {
            this.savedLists.forEach(l => {
                l.isEditing = false; // Ensure only one item is editable at a time
                l.focus = false; // Reset focus for all items
            });
            list.isEditing = true;
            list.editText = list.name; // Assuming 'name' is the property holding the list name
            this.$nextTick(() => {
                const index = this.savedLists.indexOf(list);
                if (this.$refs[`editInput${index}`] && this.$refs[`editInput${index}`][0]) {
                        this.$refs[`editInput${index}`][0].focus();
                }
            });
        },

        async submitEdit(list) {
            try {
                // Replace 'yourBrandIdHere' with the actual brand ID.
                const brandId = this.brandId; // Assuming this.brandId is already defined and holds the current brand ID
                const response = await axios.post(`${this.apiEndpoint}/update-list`, {
                        brand_id: brandId,
                        list_name: list.editText,
                        list_id: list.list_id
                });

                if (response.status === 200) {
                        // Update the list name in the UI only if the server update was successful
                        list.name = list.editText;
                        //  console.log("List name updated successfully:", response.data);
                } else {
                        // Handle server responses other than 200 OK
                        //   console.error("Failed to update the list name:", response.status, response.statusText);
                        // Optionally, show an error message to the user
                }
            } catch (error) {
                //   console.error('Error updating the list name:', error);
                // Handle error, potentially show an error message to the user
            }

            // Exit editing mode
            list.isEditing = false;
        },
            
        cancelEdit(list) {
            list.isEditing = false; // Exit editing mode without saving changes
            // No need to reset editText as it's no longer shown
        },


        async fetchCreatorsForList(listId) {
            try {
                const response = await axios.get(`${this.apiEndpoint}/search-creators/l=${listId}`);
                const accountIds = response.data.results.slice(0, 6).map(creator => creator.id);
                const totalCount = response.data.total_count;

                const profilePromises = accountIds.map(id =>
                    axios.get(`${this.apiEndpoint}/profile-card/${id}`)
                );
                const profileResponses = await Promise.all(profilePromises);

                const creatorsInfo = profileResponses.map(response => {
                    const consolidatedInfo = response.data.reduce((acc, item) => {
                        const key = Object.keys(item)[0];
                        acc[key] = item[key];
                        return acc;
                    }, {});
                    return consolidatedInfo;
                });

                // Use chunkArray to split creators into chunks of 3
                const chunkedCreators = this.chunkArray(creatorsInfo, 3);

                // Fetching list metrics
                const metricsResponse = await axios.get(`${this.apiEndpoint}/list-metrics/${listId}`);
                const metrics = metricsResponse.data;

                this.listCreators[listId] = {
                    creators: chunkedCreators,
                    totalCount: totalCount,
                    metrics // Store the metrics data
                };
            } catch (error) {
                console.error(`Error fetching creators for list ${listId}:`, error);
            }
        },

        async fetchSavedLists() {
            // Check if brandId is valid (i.e., not null or undefined)
            if (!this.brandId) {
                // console.log("Brand ID is not set. Skipping fetch for saved lists.");
                return;
            }
            try {
                const response = await axios.get(`${this.apiEndpoint}/saved-lists/${this.brandId}`);
                if (response.status === 200) {
                    // console.log("Fetched saved lists:", response.data); 
                    this.savedLists = response.data;
                    // After successfully fetching the saved lists, fetch creators for each list
                    this.savedLists.forEach(list => {
                        this.fetchCreatorsForList(list.list_id);
                    });
                    // Collect all fetchCreatorsForList promises
                    const creatorsPromises = this.savedLists.map(list => this.fetchCreatorsForList(list.list_id));

                    // Wait for all fetchCreatorsForList calls to complete
                    await Promise.all(creatorsPromises);
                }
            } catch (error) {
                // console.error('Error fetching saved lists:', error);
            } finally {
                // Set isLoadingCreators to false when the API request is complete
                this.isLoadingCreators = false;
            }
        },

        // Method to construct the full image URL
        getImageUrl(relativePath) {
            return `${this.spacesBaseUrl}${relativePath}`;
        },

        handleListClick(listId) {
            localStorage.setItem('list', listId);
            localStorage.setItem('searchType', 'list');

            this.$router.push({ path: '/dashboard', query: { list: listId } }).catch(err => console.error(err));
            const listName = this.savedLists.find(list => list.list_id === listId)?.name || 'Unknown List';

            this.$emit('listSearch', listId, listName);
            localStorage.setItem('listname', listName);

            if (window.location.pathname === '/top100' && window.location.search === '') {
                //window.location.href = `${window.location.origin}/dashboard?list=${encodeURIComponent(listId)}`;
                this.$router.push({ path: '/dashboard', query: { list: listId } });
            }
            else if (window.location.pathname === '/mylists' && window.location.search === '') {
                //window.location.href = `${window.location.origin}/dashboard?list=${encodeURIComponent(listId)}`;
                this.$router.push({ path: '/dashboard', query: { list: listId } });
            }
        },


    },

    mounted() {
    this.$nextTick(() => {
        this.fetchSavedLists();
    });
},


    created() {
        this.$nextTick(() => {
        this.fetchSavedLists();
    });
    },


};

</script>