<template>
  
  <div class="signup-modal-card">  
    <!-- 
    <label class="toggle-switch">
      <input type="checkbox" @change="isAnnualSelected = $event.target.checked">
      <span class="slider">
        <span class="slider-text left-text">MONTHLY</span>
        <span class="slider-text right-text">ANNUAL</span>
      </span>
    </label>
    -->
    <div v-if="!isAnnualSelected" class="premium-offer-style1">
        <div class="premium-offer-heading">Unlock full access</div>
      <div class="premium-offer-price-box">    
        <div class="premium-offer-pricebox-left">$99</div>
        <div class="premimum-offer-pricebox-right">
          <div class="premium-offer-frequency">/mo</div>
        </div>
      </div>   
    </div>
<!-- 
    <div v-else class="premium-offer-style1">
      <div class="premium-offer-heading">Unlock full access</div>
      <div class="premium-offer-price-box">    
        <div class="premium-offer-pricebox-left">$89</div>
        <div class="premimum-offer-pricebox-right">
          <div class="premium-offer-banner">10% OFF!</div>
          <div class="premium-offer-frequency">/mo</div>
        </div>
      </div>   
    </div>
-->

    <!-- Premium Features Bullets -->
    <div v-show="!isSignupActive || (isSignupComplete || globalState.isLoggedIn)" class="premium-features">
      
      <div class="premium-features-bullets">
      
        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2">
          <div class="pricing-text-content2">Unlimited search</div>
        </div>

        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2b">
          <div class="pricing-text-content2b">Saved lists</div>
        </div>

        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2">
          <div class="pricing-text-content2">Contact info</div>
        </div>

        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2b">
          <div class="pricing-text-content2b">AI insights</div>
        </div>

        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2">
          <div class="pricing-text-content2">Profile analytics</div>
        </div>

        <div class="pricing-bullet-item2">
          <img src="/images/bullet-checkmark.png" alt="Bullet" class="bullet-icon2b">
          <div class="pricing-text-content2b">Recent content</div>
        </div>
      
      </div>
      
      <div class="button-primary-style6" v-if="!isSignupComplete && !globalState.isLoggedIn" @click="isSignupActive = true">Get Premium</div>
    
      <div class="button-form-text-action"></div> <!-- placeholder -->
      
      <div v-if="!isAnnualSelected" class="secondary-text">Monthly recurring charge is $99. Cancel any time.<br> View our
        <a :href="urls.termsOfService" class="inline-text-link" target="_blank">Terms of Service</a> for more details.
      </div>
      
      <div v-else class="secondary-text">Annual recurring charge is $1,068. Cancel any time.<br> View our
        <a :href="urls.termsOfService" class="inline-text-link" target="_blank">Terms of Service</a> for more details.
      </div>


    </div>
  
  
    <!-- Check if the signup is complete -->
   <div v-show="(isSignupComplete || globalState.isLoggedIn) && !isSubscribed" class="payment-card">
    
      <!-- PayPal Buttons for Subscriptions -->
      <div v-if="!isAnnualSelected" :id="'paypal-button-container-' + paypalMonthly"></div> <!-- Monthly Subscription Button -->
      <div v-else :id="'paypal-button-container-' + paypalAnnual">annual</div> <!-- Annual Subscription Button -->
    </div>
  


    
    <!-- Display the signup form if signup is not complete -->

    <div v-show="isSignupActive && !isSignupComplete && !globalState.isLoggedIn" class="signup-content">

      <div class="backbutton" @click="isSignupActive = false">
        <img class="backarrow-icon" alt="Back" src="/images/backarrow.png" />
      </div>


      <div id="google-signin-button"></div>

    
      <div class="separator-or">OR</div>

      <!-- Input fields here -->
      <form @submit.prevent="submitForm" class="input-form">
        
        <div class="input-field-title">Email</div>
        <input type="email" class="input-field" v-model="formData.email" autocomplete="email">
        <div class="error-message2" v-if="formErrors.email">{{ formErrors.email }}</div>
        <div v-if="isSignupFailed" class="error-message2" >{{ failureMessage }}</div>
        
        <div class="input-field-title">Password</div>
        <div class="password-container">
          <input :type="isPasswordVisible ? 'text' : 'password'" class="input-field" v-model="formData.password" autocomplete="new-password">
          <div class="error-message1" v-if="formErrors.password">{{ formErrors.password }}</div>
          <img 
            :src="isPasswordVisible ? '/images/icon-password-displayed.png' : '/images/icon-password-hidden.png'"
            class="toggle-password-icon"
            @click="togglePasswordVisibility"
            :alt="isPasswordVisible ? 'Hide password' : 'Show password'"
          />
        </div>
      
        <label class="checkbox-container">
          <input type="checkbox" name="exampleCheckbox" id="exampleCheckbox" v-model="emailOptIn">
          <span class="email-optin-text">I agree to receive emails from Crowdscore. I understand I can unsubscribe at any time. See our <a :href="urls.privacyPolicy" class="inline-text-link" target="_blank">Privacy Policy</a> for more info.</span>
        </label>

        <button type="submit" iv class="button-form-action">Create Account</button>
    
      </form>
    
      <div class="button-form-text">Already have an account?&nbsp;<span class="inline-text-link" @click="openLoginModal">Login</span></div> 
      <LoginModal ref="loginModal" />

      <div class="secondary-text2">By clicking on Create Account, you agree to our<br>
        <a :href="urls.termsOfService" class="inline-text-link" target="_blank">Terms of Service</a> and <a :href="urls.privacyPolicy" class="inline-text-link" target="_blank">Privacy Policy</a>
      </div>

    </div>

  </div>

</template>

<script>
  /* global google */
  
  import { inject, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import LoginModal from './LoginPage.vue';
  
  export default {
    
    components: {
            LoginModal
      },
    
    data() {
      return {
        formData: {
          email: '',
          password: '',
        },
        formErrors: {
          email: '',
          password: '',
        },
        isPasswordVisible: false,
        isSignupComplete: false,
        emailOptIn: false,
        successMessage: '',
        isSignupActive: false,
        isSignupFailed: false,
        failureMessage: '',
        googleAuth: null,
        isGoogleApiLoaded: false,
        submitAttempted: false,
        apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
        paypalClientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
        paypalMonthly: process.env.VUE_APP_PAYPAL_MONTHLY,
        paypaAnnual: process.env.VUE_APP_PAYPAL_ANNUAL,
      };
    },
    setup() {
      const store = useStore();
      const isSubscribed = computed(() => store.state.isSubscribed);
      const email = computed(() => store.state.email);

      const globalState = inject('globalState');
      
      const isAnnualSelected = ref(false);
      const isMonthSelected = computed(() => !isAnnualSelected.value);

      return { isSubscribed, email, globalState, isAnnualSelected, isMonthSelected };

    },

    mounted() {

        this.loadPaypalScript().then(() => {
            this.initializePaypalButtons();
        });

        document.body.style.visibility = 'visible'; // If you used visibility: hidden;

        google.accounts.id.initialize({
          client_id: '154747152337-ti4sfr4gfdls58464vknvlvlnnc6dcf9.apps.googleusercontent.com',
          callback: this.handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          { theme: "outline", size: "large", width: 318 } 
        );

        // Initialize PayPal Buttons
        if (window.paypal) {
          // Monthly Subscription Button
          window.paypal.Buttons({
            // ... PayPal button configuration for monthly subscription
          }).render(`#paypal-button-container-${this.paypalMonthly}`);

          // Annual Subscription Button
          window.paypal.Buttons({
            // ... PayPal button configuration for annual subscription
          }).render(`#paypal-button-container-${this.paypaAnnual}`);
        }

    },

    computed: {
      urls() {
        return {
          privacyPolicy: this.$router.resolve({ path: '/privacy' }).href,
          termsOfService: this.$router.resolve({ path: '/terms' }).href
        };
      },
    userEmail() {
      return this.$store.getters.email;
    },
    },


    watch: {
    isAnnualSelected(newVal) {
      if (newVal) {
        this.loadPaypalScript().then(() => {
          this.initializePaypalButtons();
        });
      }
    },
    isMonthSelected(newVal) {
      if (newVal) {
        this.loadPaypalScript().then(() => {
          this.initializePaypalButtons();
        });
      }
    },
    isModalOpen(newVal) {
      if (newVal) {
        this.loadPaypalScript().then(() => {
          this.initializePaypalButtons();
        });
      }
    },
    isSignupComplete(newVal) {
      if (newVal) {
        this.loadPaypalScript().then(() => {
          this.initializePaypalButtons();
        });
      }
    },
  },

    methods: {

      loadPaypalScript() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalClientId}&vault=true&intent=subscription`;
                script.addEventListener('load', resolve);
                script.addEventListener('error', () => reject('Error loading PayPal script'));
                document.head.appendChild(script);
            });
        },
        initializePaypalButtons() {
          this.$nextTick(() => {
            if (window.paypal) {
              // Use v-show in template for these containers to ensure they exist
              const monthlyContainer = `#paypal-button-container-${this.paypalMonthly}`;
              
              const annualContainer = `#paypal-button-container-${this.paypalAnnual}`;

              if (this.isAnnualSelected && document.querySelector(annualContainer)) {

                this.renderPaypalButton(`${this.paypalAnnual}`, annualContainer);
              } else if (!this.isAnnualSelected && document.querySelector(monthlyContainer)) {
 
                this.renderPaypalButton(`${this.paypalMonthly}`, monthlyContainer);
              }
            }
          });
        },

    renderPaypalButton(planId, containerId) {
        if (!document.querySelector(containerId)) return; // Check if element exists

        window.paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: ''
            },
            createSubscription: function(data, actions) {
                return actions.subscription.create({ plan_id: planId });
            },

        
        onApprove: (data) => {
          // Access the email from Vuex store using getter
          const email = this.$store.getters.email;
          console.log("Current email in Vuex before PayPal verification:", this.$store.getters.email);
          localStorage.setItem('userEmail', email);

          // Proceed with your logic using email
          const verifyData = {
            subscriptionId: data.subscriptionID,
            email: email, // Use the email from Vuex store
            price: 99,
          };

          console.log('Sending verification request', verifyData);
          console.log("Token being sent: ", localStorage.getItem('accessToken'));
          // Hit the verify endpoint
          axios.post(`${this.apiEndpoint}/verify-subscription`, verifyData)
            .then(response => {
              console.log('Verification success:', response.data);
              // Store JWT tokens
              localStorage.setItem('accessToken', response.data.access_token);
              localStorage.setItem('refreshToken', response.data.refresh_token);

              const decodedPayload = JSON.parse(atob(response.data.access_token.split('.')[1]));
              const isSubscribedValue = decodedPayload.sub.is_subscribed; 
              console.log('Decoded JWT Payload:', decodedPayload);


              const brand_id = decodedPayload.sub.brand_id;
              this.$store.dispatch('updateBrandId', brand_id);
              localStorage.setItem('brandId', brand_id);
              console.log('brand_id:', brand_id); 

              // Update Vuex store with subscription status
              this.$store.commit('setIsSubscribed', isSubscribedValue);

              this.globalState.updateLoginStatus(true, isSubscribedValue);

              // Redirect user to dashboard
              this.$router.push('/search?s=1');
            })
            .catch(error => {
              console.error('Verification failed:', error.response || error.message);

            });
        }

        }).render(containerId);
    },


      handleCredentialResponse(response) {
        this.googleSignIn(response.credential);
      },

    togglePasswordVisibility() {
          this.isPasswordVisible = !this.isPasswordVisible;
      },
  
    async googleSignIn(idToken) {
      try {
        const response = await axios.post(`${this.apiEndpoint}/google-signin`, { id_token: idToken });
        console.log('Signup successful:', response.data);

        // Update the Vuex store with the email
        const email = response.data.email;
        this.$store.dispatch('updateEmail', email);
        localStorage.setItem('userEmail', email);
        console.log('email:', email);

        const brand_id = response.data.brand_id;
        this.$store.dispatch('updateBrandId', brand_id);
        localStorage.setItem('brandId', brand_id);
        console.log('brand_id:', brand_id);     

        const isSubscribed = response.data.is_subscribed;
        this.$store.dispatch('updateIsSubscribed', isSubscribed);
        localStorage.setItem('IsSubscribed', isSubscribed);
        console.log('isSubscribed:', isSubscribed);

        if (isSubscribed) {
          // If the user is already subscribed, redirect them to the dashboard
          localStorage.setItem('accessToken', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);

          this.$router.push('/dashboard').then(() => {
          window.location.href = this.$router.resolve('/dashboard').href;
        });

        } else {
          this.isVisible = true; // For showing the modal, if needed
          this.isSignupComplete = true; // Assuming you want to complete the signup/subscription process
          this.globalState.isLoggedIn = true;
          this.successMessage = 'Signup successful! Please complete your subscription.';

          localStorage.setItem('accessToken', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          console.log('Google SSO accessToken:', response.data.access_token);

        }

      } catch (error) {
        this.isSignupFailed = true;
        this.failureMessage = "Test Signup failed: Unable to sign in with Google.";
      }
    },
  
      redirectToDashboard() {
      this.$router.push('/dashboard');
    },
  
      closeModal() {
          this.$emit('close');
      },

      openLoginModal() {
      this.$refs.loginModal.isVisible = true;
    },

    async submitForm() {
        // Reset form errors
        this.formErrors = { email: '', password: '' };
        this.failureMessage = '';
  
        // Validate email
        if (!this.formData.email) {
          this.formErrors.email = 'Email is required';
        } else if (!this.isEmailValid(this.formData.email)) {
          this.formErrors.email = 'Email is invalid';
        }
  
        // Validate password
        if (!this.formData.password) {
          this.formErrors.password = 'Password is required';
        }
  
        // If there are any errors, stop the form submission
        if (Object.values(this.formErrors).some(error => error !== '')) {
          return;
        }

        // Payload construction and API request
        const payload = {
          name: 'name', // Use actual name if available
          email: this.formData.email,
          phone: this.formData.phone,
          password: this.formData.password,
          company: 'company',
          industry: 'industry',
          subscriber: false,
          subscription_type: null,
          email_verified: 'no',
          email_optin: this.emailOptIn ? 'yes' : 'no',
        };     

      
        try {
          const response = await axios.post(`${this.apiEndpoint}/brand-signup`, payload);
          console.log('Signup successful:', response.data);
  
          // Set signup completion flag and display success message
          this.isSignupComplete = true;
          this.successMessage = 'Signup successful!'; 
          this.globalState.isLoggedIn = true;
          console.log("Signup is successful");

          // Update the Vuex store with the email
          const email = response.data.email;
          this.$store.dispatch('updateEmail', email);
          localStorage.setItem('userEmail', email);
          console.log('response email:', email);

          // Update the Vuex store with the brand_id
          const brand_id = response.data.brand_id;
          this.$store.dispatch('updateBrandId', brand_id);
          localStorage.setItem('brandId', brand_id);
          console.log('response brand_id:', brand_id);

          // Update the Vuex store with the is_subscribed
          const isSubscribed = response.data.is_subscribed;
          this.$store.dispatch('updateIsSubscribed', isSubscribed);
          localStorage.setItem('IsSubscribed', isSubscribed);
          console.log('isSubscribed:', isSubscribed);

          localStorage.setItem('accessToken', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          console.log('Email signup accessToken:', response.data.access_token);




      } catch (error) {
      let failureMessage = "Signup failed due to an unknown error.";
  
      if (error.response) {
        // The request was made and the server responded with a status code
        failureMessage = `${error.response.data.message || 'Server error'}`;
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
        failureMessage = "Signup failed: No response from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        failureMessage = `Signup failed: ${error.message}`;
      }
  
      console.error('Error config:', error.config);
      this.isSignupFailed = true;
      this.failureMessage = failureMessage;
    }
  },
  
      isEmailValid(email) {
          // Simplified regex for email validation
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
      },
    }
  }
  </script>