<template>
      
      <div class="creator-profile-header">
            <div v-if="showBackButton" class="profile-backbutton" @click="goBack">
                  <img class="profile-backarrow-icon" alt="" src="/images/backarrow.png" />
                  <div class="back">Back</div>
            </div>

      </div>

      <!--<div v-if="isLoading"> <img class="loading-spinner" alt="Loading…" src="/images/loading-spinner.gif"></div>-->
      
      <div class="creator-profile" v-if="profileData">

            <div class="profile-banner">

                  <div class="profile-banner-content-left">

                        <div class="profile-banner-avatar-creatori">

                              <div class="profile-banner-avatar">
                                    <img class="profile-banner-avatar-icon" :alt="profileData.ig_full_name"
                                          :src="getImageUrl(profileData.ig_profile_image_path)"
                                          :class="bordered - image - white"
                                          @click="openImageModal(getImageUrl(profileData.ig_profile_image_path))" />
                              </div>

                              <div class="profile-banner-creatorinfo">

                                    <div class="creatorinfo-name-flag">

                                          <b class="creatorinfo-name">{{ profileData.ig_full_name }}</b>

                                    </div>

                                    <!-- Handle -->
                                    <div class="creatorinfo-handle">
                                          <a :href="`https://instagram.com/${profileData.ig_username}`" target="_blank">@{{
                                                profileData.ig_username }}</a>
                                    </div>

                                    <!-- Bio -->
                                    <div class="profile-biotext">{{ profileData.ig_bio }}</div>

                                    <!-- Badge -->
                                    <router-link :to="{ path: '/dashboard', query: { category: profileData.ig_category } }">
                                          <div class="badge-profile">{{ profileData.ig_category }}</div>
                                    </router-link>

                              </div>
                        </div>
                  </div>

                  <div class="profile-banner-content-right">

                        <!-- Lists -->
                                          
                        <div class="profile-actions">
                                                
                              <img ref="icon" class="profile-actions-list-icon" :class="{ 'active': showDropdownMenu }" alt="Add to list"
                                    src="/images/profile-actions-list.png" @click="toggleDropdown" />
                              
                              
                              <!-- Lists dropdown -->

                              <div ref="dropdownMenu" v-if="showDropdownMenu" class="dropdown-menu-lists"
                                    @mouseleave="closeDropdown">

                                    <!-- Top section for saved lists -->
                                    <div class="dropdown-section" @click="showSubscribeModal">
                                          <a href="#" class="dropdown-item" v-for="list in savedLists" :key="list.id"
                                                :class="{ 'selected': list.containsProfile }" @click.prevent="handleItemClick(list)"
                                                @mouseover="hoveredListId = list.id" @mouseleave="hoveredListId = null">
                                                {{ list.name }}

                                                <span class="dropdown-item-clicked" v-if="list.containsProfile"></span>
                                                <span class="plus-icon" v-else>&#43;</span>
                                                <span class="dropdown-item-minus-icon"
                                                      v-if="list.containsProfile && hoveredListId === list.id">&#8722;</span>
                                          </a>
                                    </div>

                                    <div class="dropdown-stickybottom">

                                          <hr> <!-- Visual divider -->

                                          <!-- Bottom section for 'New List' -->
                                          <div class="dropdown-section-single">
                                                <a href="#" class="dropdown-newlist" @click="showNewListModal = true">
                                                      <img src="/images/icon-newlist.png" alt="New List Icon">New List</a>
                                          </div>

                                    </div>
                                    
                              </div>

                        </div>

                        <!-- Contact -->
                                          
                        <!-- Contact subscribed -->
                        <div v-if="isSubscribed && (profileData.ig_email || profileData.ig_phone)">
                              <div class="profile-actions"> 
                                    <img class="profile-actions-contact-icon" alt="Contact"
                                    src="/images/profile-actions-contact.png" 
                                    @click="openEmail(profileData.ig_email)" />
                              </div>
                        </div>

                        <!-- Contact guest -->
                        <div v-if="!isSubscribed && (profileData.ig_email || profileData.ig_phone)">
                              <div class="profile-actions"> 
                                    <img class="profile-actions-contact-icon" alt="Contact"
                                    src="/images/profile-actions-contact.png" 
                                    @click="toggleDropdown" />
                              </div>
                        </div>

                  </div>

            </div>

            

            <div class="profile-content">
                  
                  <div class="profile-info">
                        
                        <div class="profile-stats-container">
                        
                              <div class="profile-stats">

                                    
                                    <!--------- STAT BLOCKS --------->
                                    
                                    <!-- Followers -->

                                    <div class="profile-creator-stat" @click="openCreatorGraphsModal('Followers')">
                                          <div class="profile-creator-stat-container">
                                                <div class="profile-subtitle1">Followers</div>
                                                <div class="profile-stat">{{ formatNumber(profileData.ig_followers) }}</div>
                                                <div v-if="!isNegativeGrowth && !isNullGrowth" class="profile-stat-growth" 
                                                      :class="{'positive-growth': !isNegativeGrowth && !isNullGrowth}">
                                                      <span class="stats-arrow-up">&#9650;&#8201;&#8201;</span>{{ formattedGrowth }}
                                                </div>
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <GraphFollowers :profileId="profileId" :isMainView="true"/>
                                          </div>
                                    </div>

                                    
                                    <!-- CPP subscribed -->
                                    
                                    <div v-if="isSubscribed" class="profile-creator-stat" @click="openCreatorGraphsModal('Estimated CPP')">
                                          <div class="profile-creator-stat-container">
                                                <div class="profile-subtitle1">Estimated CPP</div>
                                                <div class="profile-stat">{{ formatNumber2(profileData.ig_cpp) }}</div>
                                                <!--<div v-if="!isNegativeEngagementGrowth" class="profile-stat-growth" 
                                                      :class="{'positive-growth': !isNegativeEngagementGrowth}">
                                                      <span class="stats-arrow-up">&#9650;&#8201;&#8201;</span>{{ formattedEngagementGrowth }}
                                                </div>
                                                <div v-if="isNegativeEngagementGrowth" class="profile-stat-growth" 
                                                      :class="{'negative-growth': isNegativeEngagementGrowth}">
                                                      <span class="stats-arrow-down">&#9660;&#8201;&#8201;</span>{{ formattedEngagementGrowth }}
                                                </div>-->
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <GraphCPP :isMainView="true" :profileId="profileId" />
                                          </div>
                                    </div>


                                    <!-- CPP guest -->

                                    <div v-if="!isSubscribed" class="profile-creator-stat" @click="openCreatorGraphsModal('Estimated CPP')">
                                          <div class="profile-creator-stat-container">
                                                <div class="subtitle-icon-lo">
                                                      <div class="profile-subtitle1">Esimated<br>CPP</div>
                                                      <div class="lo-lockicon"> <img class="lockicon" alt="" src="/images/lockicon.png" /></div>
                                                </div>
                                                <div class="profile-stat-blr"> <img class="cpp-blr" alt="" src="/images/cpp-blr.png" /></div>
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <div class="profile-chart-blr"> <img class="chart-blr" alt="" src="/images/chart-blr.png" /></div>
                                          </div>
                                    </div>


                                    <!-- Engagement Rate subscribed -->

                                    <div v-if="isSubscribed" class="profile-creator-stat" @click="openCreatorGraphsModal('Engagement')">
                                          <div class="profile-creator-stat-container">
                                                <div class="profile-subtitle1">Engagement</div>
                                                <div class="profile-stat">{{ profileData.ig_engagement }}</div>
                                                <div v-if="!isNegativeEngagementGrowth && !isNullEngagementGrowth" class="profile-stat-growth" 
                                                      :class="{'positive-growth': !isNegativeEngagementGrowth && !isNullEngagementGrowth}">
                                                      <span class="stats-arrow-up">&#9650;&#8201;&#8201;</span>{{ formattedEngagementGrowth }}
                                                </div>
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <GraphEngagement :profileId="profileId" :isMainView="true"/>
                                          </div>
                                    </div>
                                

                                    <!-- Engagement Rate guest -->

                                    <div v-if="!isSubscribed" class="profile-creator-stat" @click="openCreatorGraphsModal('Engagement')">
                                          <div class="profile-creator-stat-container">
                                                <div class="subtitle-icon-lo">
                                                      <div class="profile-subtitle1">Engagement</div>
                                                      <div class="lo-lockicon"> <img class="lockicon" alt="" src="/images/lockicon.png" /></div>
                                                </div>
                                                <div class="profile-er-blr"> <img class="er-blr" alt="" src="/images/er-blr.png" /></div>
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <div class="profile-chart-blr"> <img class="chart-blr" alt="" src="/images/chart-blr.png" /></div>
                                          </div>
                                    </div>

                                    
                                    <!-- Rank subscribed -->

                                    <div v-if="isSubscribed && !isNullRank" class="profile-creator-stat" @click="openCreatorGraphsModal('Rank')">
                                          <div class="profile-creator-stat-container">
                                                <div class="profile-subtitle1">Rank</div>
                                                <div class="profile-stat">{{ profileData.ig_rank }}</div>
                                                <!--<div v-if="!isNegativeEngagementGrowth" class="profile-stat-growth" 
                                                      :class="{'positive-growth': !isNegativeEngagementGrowth}">
                                                      <span class="stats-arrow-up">&#9650;&#8201;&#8201;</span>{{ formattedEngagementGrowth }}
                                                </div>
                                                <div v-if="isNegativeEngagementGrowth" class="profile-stat-growth" 
                                                      :class="{'negative-growth': isNegativeEngagementGrowth}">
                                                      <span class="stats-arrow-down">&#9660;&#8201;&#8201;</span>{{ formattedEngagementGrowth }}
                                                </div>-->
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <GraphRank :profileId="profileId" :isMainView="true"/>
                                          </div>
                                    </div>
                                    

                                    <!-- Rank guest -->

                                    <div v-if="!isSubscribed && !isNullRank" class="profile-creator-stat" @click="openCreatorGraphsModal('Rank')">
                                          <div class="profile-creator-stat-container">
                                                <div class="subtitle-icon-lo">
                                                      <div class="profile-subtitle1">Rank</div>
                                                      <div class="lo-lockicon"> <img class="lockicon" alt="" src="/images/lockicon.png" /></div>
                                                </div>
                                                <div class="profile-scr-blr"> <img class="scr-blr" alt="" src="/images/scr-blr.png" /></div>
                                          </div>
                                          <div class="profile-creator-graph-container">
                                                <div class="profile-chart-blr"> <img class="chart-blr" alt="" src="/images/chart-blr.png" /></div>
                                          </div>
                                    </div>

                              </div>

                        </div>

                        <!-- Insights subscribed desktop -->

                        <div v-if="isSubscribed" class="profile-insights">
                              <div class="profile-subtitle">Insights</div>
                              <div class="profile-insight-text">{{ profileData.ig_insights }}</div>
                        </div>

                        <!-- Insights subscribed mobile -->
                        
                        <div v-if="isSubscribed" class="profile-insights-m">
                              <div class="profile-subtitle">Insights</div>
                              <div class="profile-insight-container">
                                    <div class="profile-insight-text-m" :class="{ 'collapsed': isCollapsed }">
                                          {{ profileData.ig_insights }}
                                    </div>
                                    <div @click="toggleText" class="toggle-button"> <img src="/images/expandlist.png" alt="" :class="{ 'animate-expand': isExpanded }"></div>
                              </div>
                        </div>

                        <!-- Insights guest -->

                        <div v-if="!isSubscribed" class="profile-insights-lo" @click="showSubscribeModal=true">
                              <div class="subtitle-icon-lo">
                                    <div class="profile-subtitle">Insights</div>
                                    <div class="lo-lockicon"> <img class="lockicon" alt="" src="/images/lockicon.png" />
                                    </div>
                              </div>
                              <div class="profile-insight-blr"> 
                                    <img class="insight-blr" alt="" src="/images/insight-blr.jpg" /> 
                              </div>
                              <div class="profile-insight-blr-m"> 
                                    <img class="insight-blr" alt="" src="/images/insight-blr-m.jpg" /> 
                              </div>
                        </div>

                        <div class="profile-tags-container">
                              <div class="profile-subtitle">Vibe</div>
                              <div class="profile-tags">
                                    <div class="profile-tag-badge" v-for="tag in tags" :key="tag.tag"
                                          @click="handleVibeClick(tag.tag)">
                                          <div class="profile-tag-text">#{{ tag.tag }}</div>
                                    </div>
                              </div>
                        </div>
                        
                  </div>

            </div>

            <div class="profile-posts" v-if="contentData && contentData.posts">

                  <div class="profile-subtitle">Recent Posts</div>

                  <div class="profile-postsgrid">
                        <div v-for="(post, index) in contentData.posts" :key="post.content_id" class="post-container">
                              <div v-if="isSubscribed || (!isSubscribed && index === 0)" class="profile-posts-card"
                                    @click="handlePostClick(post, index)">
                                    <div class="creator-post-thumb">
                                          <img :src="getImageUrl(post.thumbnail_path)" alt="Post thumbnail" class="creator-info-avatar-displaynam-icon">
                                                <div v-if="post.video_path" class="play-button-overlay"></div>
                                                <!-- Overlay content -->
                                                <div class="post-overlay-content">
                                                      <div class="user-info">
                                                            <p class="profile-content-caption">{{ truncateText(post.caption, 50) }}</p>
                                                      </div>
                                                </div>
                                    </div>
                              </div>

                              <!-- Grey div for remaining posts when logged out -->
                              <div v-else class="profile-posts-card-lo" @click="showSubscribeModal=true"> 
                                    <div class="creator-post-thumb">
                                          <img :src="getImageUrl(post.thumbnail_path)" alt="Post thumbnail" class="creator-info-avatar-displaynam-icon-blr">
                                                
                                                <!-- Overlay content -->
                                                <div class="post-overlay-content-lo">
                                                      <div class="lo-lockicon"> <img class="lockicon" alt="" src="/images/lockicon.png" /></div>
                                                </div>
                                    </div>
                              </div>

                              <div class="profilecontent-likes-comments">
                                    <div class="profilecontent-likes">
                                          <img src="/images/icon-profilecontent-likes.png" alt="Likes"> {{
                                                formatNumber(post.likes) }}
                                    </div>

                                    <div class="profilecontent-comments">
                                          <img src="/images/icon-profilecontent-comments.png" alt="Comments"> {{
                                                formatNumber(post.comments) }}
                                    </div>
                              </div>
                        </div>
                  </div>

                  <SignUpModal ref="signUpModal" />

                  <div v-if="!isSubscribed" class="premium-cta">

                        <div class="home-cta">
                              <div class="text">
                                    <b class="home-cta-headline">Upgrade now to unlock full access!</b>
                                    <div class="home-cta-subtitle">
                                          <div class="cta-subtitle">• Search our complete database of high quality creators</div>
                                          <div class="cta-subtitle">• Unlock key insights, user data and content</div>
                                          <div class="cta-subtitle">• Access all features including AI-powered search, lists and more</div>
                                    </div>
                              </div>

                              <router-link to="/pricing"> 
                                    <div class="button-primary-style3">
                                          <div class="button-text">Upgrade Now</div>
                                    </div>
                              </router-link>

                              <div class="button-form-text-action"><router-link to="/pricing">Learn More</router-link></div>

                        </div>

                  </div>

            </div>

      </div>

      <div v-if="showSubscribeModal" class="alertmodal">
            <div class="alertmodal-content">
                  <div class="alertmodal-heading">This is a subscriber feature</div>
                  <div class="alertmodal-actions">
                        <router-link to="/pricing" class="alertmodal-submit">Subscribe</router-link>
                        <div @click="showSubscribeModal = false" class="alertmodal-cancel">OK</div>
                  </div>
            </div>
      </div>

      <!-- Modal Backdrop for videos or images -->
      
      <div v-if="isModalOpen" class="modal-backdrop" @click="backdropClick">
            <div class="modal-content" @click.stop>
                  <span @click="closeModal" class="close">&times;</span>
                  <div class="modal-body">
                  <video v-if="modalContentType === 'video'" :src="selectedImage" controls autoplay class="modal-video"></video>
                  <img v-else :src="selectedImage" alt="Selected content" class="content-avatar-modal">
                  <div class="modal-caption-style">{{ selectedPost.caption }}</div>
                  <!-- You can add additional modal content here -->
                  </div>
            </div>
      </div>

      <!-- Image Modal  -->
      <div v-if="isImageModalOpen" class="modal-backdrop" @click="closeImageModal">
            <div class="modal-content" @click.stop>
                  <span @click="closeImageModal" class="close">&times;</span>
                  <div class="modal-body">
                        <img :src="selectedImage" alt="Profile Image" class="profile-banner-avatar-modal">
                        
                  </div>
            </div>
      </div>

      <SignUpModal ref="signUpModal" />

      <div v-if="showNewListModal" class="alertmodal">
            <div class="alertmodal-content">
                  <div class="alertmodal-heading">Create a new list</div>
                  <input ref="listNameInput" class="alertmodal-input" type="text" v-model="newListName"
                        placeholder="Enter a list name…" @focus="newListError = ''" @keyup.enter="addNewList">
                  <div class="error-message3" v-if="newListError">{{ newListError }}</div>
                  <div class="alertmodal-actions">
                        <div @click="addNewList" class="alertmodal-submit">Save</div>
                        <div @click="closeNewListModal" class="alertmodal-cancel">Cancel</div>
                  </div>
            </div>
      </div>

      <CreatorGraphs :profileId="profileId" :isMainView="false" v-model:modalVisible="isCreatorGraphsVisible" :initialActiveTab="activeTabForModal" />

</template>
  
<script>

import axios from 'axios';
import { inject, computed } from 'vue';
import { useStore, mapState } from 'vuex';
import { eventBus } from '@/eventBus';
import SignUpModal from './SignupPage.vue';
import CreatorGraphs from './Profile/CreatorGraphs.vue';
import GraphFollowers from './Profile/GraphFollowers.vue';
import GraphCPP from './Profile/GraphCPP.vue';
import GraphEngagement from './Profile/GraphEngagement.vue';
import GraphRank from './Profile/GraphRank.vue';


export default {
      name: 'ProfileView',

      components: {
            SignUpModal,
            CreatorGraphs,
            GraphFollowers,
            GraphCPP,
            GraphEngagement,
            GraphRank
      },



      // ------------------------------------------------------------------
      // Props
      // ------------------------------------------------------------------

      props: {
            profileId: {
                  type: [String, Number],
                  required: true
            }
      },


      setup() {
            const globalState = inject('globalState'); // Inject the global state
            const store = useStore(); // Vuex store for state management
            const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed

            return {
                  globalState, // For isLoggedIn
                  isSubscribed,
            };
      },

      // ------------------------------------------------------------------
      // Watchers
      // ------------------------------------------------------------------

      watch: {

            showNewListModal(newVal) {
                  if (newVal) {
                        this.$nextTick(() => {
                              this.$refs.listNameInput.focus();
                        });
                  }
            },

            profileData: {
                  immediate: true,
                  handler(newVal) {
                        this.updateMeta(newVal);
                  }
            },

            profileId: {
                  immediate: true,
                  handler(newVal) {
                        if (newVal) {
                              this.fetchProfileData(newVal);
                              this.fetchContentData(newVal);
                        } else {
                              // console.error("Profile ID is undefined in watcher");
                        }
                  }
            }
      },

      // ------------------------------------------------------------------
      // Data
      // ------------------------------------------------------------------

      data() {
            return {
                  savedLists: [],
                  profileData: null,
                  contentData: null,
                  spacesBaseUrl: 'https://cdn-crowdscore.nyc3.cdn.digitaloceanspaces.com/',
                  isModalOpen: false,
                  selectedPost: null, // Store the whole post object
                  tags: [],
                  isImageModalOpen: false,
                  selectedImage: null,
                  showDropdownMenu: false,
                  clickedItems: [],
                  showNewListModal: false,
                  newListName: '',
                  newListError: '',
                  showSubscribeModal: false,
                  hoveredListId: null,
                  modalContentType: 'image',
                  apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
                  isCollapsed: true,
                  isExpanded: false,
                  isLoading: false,
                  isCreatorGraphsVisible: false,
                  activeTabForModal: 'Followers', //default fallback only
            };
      },

      // ------------------------------------------------------------------
      // Lifecycle hooks
      // ------------------------------------------------------------------

      created() {
            this.fetchProfileData();
            this.fetchContentData();
            this.fetchTags();
            this.fetchSavedLists();
      },


      computed: {
            brandId() {
                  //return this.$store.state.brandId;
                  //return 369;
                  return this.$store.getters.brandId;
            },

            ...mapState(['isSubscribed', 'lastRoutePath']),

            showBackButton() {
                  // Check if lastRoutePath is not '/' or if navigated from dashboard
                  //return localStorage.getItem('onsite') === 'true'; // ;
                  return sessionStorage.getItem('onsite') === 'true' || this.lastRoutePath !== '/';

            },

            formattedGrowth() {
                  return this.profileData && this.profileData.ig_growth !== null ? this.profileData.ig_growth : '-';
            },

            isNegativeGrowth() {
                  return this.formattedGrowth.startsWith('-');
            },

            formattedEngagementGrowth() {
                  return this.profileData && this.profileData.ig_engagement_growth !== null ? this.profileData.ig_engagement_growth : '-';
            },

            isNegativeEngagementGrowth() {
                  return this.formattedEngagementGrowth.startsWith('-');
            },

            isNullRank() {
                  return !this.profileData.ig_rank || this.profileData.ig_rank === '-';
            },

            isNullGrowth() {
                  return this.formattedGrowth === '-' || this.formattedGrowth === '0' || this.formattedGrowth === '0.00';
            },

            isNullEngagementGrowth() {
                  return this.formattedEngagementGrowth === '-';
            },

      },

      mounted() {

            // Hacky way to decide to fix back button showing on profile from homepage or not
            if (this.lastRoutePath === '/')
                  sessionStorage.setItem('onsite', 'false');
            else
                  sessionStorage.setItem('onsite', 'true');
     
     
     
            },

      // ------------------------------------------------------------------
      // Methods
      // ------------------------------------------------------------------

      methods: {

            
            updateMeta(profileData) {
                  if (profileData) {
                        document.title = `${profileData.ig_full_name} ${profileData.ig_username} Instagram Stats & ${profileData.ig_username} Social Media Rank on Crowdscore AI`;
                        this.setMetaTag('description', `Find ${profileData.ig_full_name} ${profileData.ig_username} Instagram Stats & ${profileData.ig_username} Social Media Rank and much more on Crowdscore.`);
                        this.setMetaTag('keywords', `${profileData.ig_username} Instagram, ${profileData.ig_full_name}, ${profileData.ig_username} stats, rank, follower growth, ${profileData.ig_username} followers`);
                  }
            },
            setMetaTag(name, content) {
                  let element = document.head.querySelector(`meta[name="${name}"]`);
                  if (!element) {
                        element = document.createElement('meta');
                        element.setAttribute('name', name);
                        document.head.appendChild(element);
                  }
                  element.setAttribute('content', content);
            },


            openEmail(email) {
                  if (email) {
                        window.location.href = `mailto:${email}`;
                  }
            },

            closeNewListModal() {
                  // Optionally reset the new list name and error message when the modal is closed
                  this.newListName = '';
                  this.newListError = '';
                  this.showNewListModal = false;
            },

            async addNewList() {
                  if (this.newListName.trim() === '') {
                        this.newListError = 'Please enter a name for the list.';
                        return;
                  }

                  if (this.savedLists.some(list => list.name.toLowerCase() === this.newListName.toLowerCase())) {
                        this.newListError = 'List name already in use.';
                        return;
                  }

                  try {
                        const response = await axios.post(`${this.apiEndpoint}/create-list`, {
                              brand_id: this.brandId,
                              list_name: this.newListName
                        });

                        if (response.data && response.data.list_id) {
                              const newList = {
                                    id: response.data.list_id,
                                    name: this.newListName,
                                    containsProfile: false,
                                    // Optionally set created_date to now
                                    created_date: new Date().toISOString()
                              };

                              // Instead of pushing, unshift to add the new list at the beginning
                              this.savedLists.unshift(newList);
                              eventBus.emit('list-updated');

                              // Clear the new list modal state
                              this.newListName = '';
                              this.newListError = '';
                              this.showNewListModal = false;
                        } else {
                              throw new Error('List ID not returned from the server');
                        }
                  } catch (error) {
                        this.newListError = 'An error occurred while creating the list.';
                  }
            },
            // Add this method to fetch saved lists
            async fetchSavedLists() {
                  const brandId = this.brandId;
                  const profileId = this.profileId.toString();

                  // Check if brandId is valid (i.e., not null or undefined)
                  if (!this.brandId) {
                        //console.log("Brand ID is not set. Skipping fetch for saved lists.");
                        return;
                  }
                  //this.isLoading = true;

                  try {
                        const response = await axios.get(`${this.apiEndpoint}/saved-lists/${brandId}`);
                        this.savedLists = response.data.map(list => ({
                              id: list.list_id,
                              name: list.name,
                              // Assuming the API now includes a property `containsProfile` indicating if the profile is in the list
                              containsProfile: list.profile_ids.includes(profileId),
                        }));
                        // Automatically update clickedItems based on containsProfile
                        this.clickedItems = this.savedLists.filter(list => list.containsProfile).map(list => list.id);
                  } catch (error) {
                        // console.error('Error fetching saved lists:', error);
                  } //finally {
                  //this.isLoading = false;
                  //}
            },

            async handleItemClick(item) {
                  const brandId = this.brandId;
                  const profileID = this.profileId.toString();

                  if (item.containsProfile) {
                        // If the profile is already in the list, send a request to remove it
                        try {
                              await axios.post(`${this.apiEndpoint}/remove-profile`, {
                                    brand_id: brandId,
                                    profile_id: profileID,
                                    list_id: item.id
                              });
                              console.log("Profile removed from list successfully");
                              // Update the state to reflect the change
                              item.containsProfile = false;
                              const index = this.clickedItems.indexOf(item.id);
                              if (index !== -1) {
                                    this.clickedItems.splice(index, 1);
                              }
                        } catch (error) {
                              // console.error('Error removing profile from list:', error);
                        }
                  } else {
                        // If the profile is not in the list, send a request to add it
                        try {
                              await axios.post(`${this.apiEndpoint}/add-profile`, {
                                    brand_id: brandId,
                                    profile_id: profileID,
                                    list_id: item.id
                              });
                              // console.log("Profile added to list successfully");
                              // Update the state to reflect the change
                              item.containsProfile = true;
                              this.clickedItems.push(item.id);
                        } catch (error) {
                              // console.error('Error adding profile to list:', error);
                        }
                  }
            },

            openCreatorGraphsModal(tabName) {
                  this.activeTabForModal = tabName;
                  this.isCreatorGraphsVisible = true;
            },

            toggleDropdown() {


                  if (!this.$store.getters.isSubscribed) {
                        this.showSubscribeModal = true;
                        return;
                  }
                  this.showDropdownMenu = !this.showDropdownMenu;


                  if (this.showDropdownMenu) {
                        // Add click event listener when dropdown is shown
                        document.addEventListener('click', this.handleClickOutside, true);
                  } else {
                        // Remove listener when dropdown is hidden
                        document.removeEventListener('click', this.handleClickOutside, true);
                  }
            },

            closeDropdown() {
                  this.showDropdownMenu = false;
                  document.removeEventListener('click', this.handleClickOutside, true);
            },

            handleClickOutside(event) {
                  if (this.showDropdownMenu && (!this.$refs.dropdownMenu.contains(event.target) && event.target !== this.$refs.icon)) {
                        this.showDropdownMenu = false;
                        document.removeEventListener('click', this.handleClickOutside, true);
                  }
            },

            handleVibeClick(vibeId) {

                  this.$router.push({ path: '/dashboard', query: { vibe: vibeId } });
                  this.$emit('vibeSearch', vibeId);
                  localStorage.setItem('vibe', vibeId);
            },
            formatNumber(num) {
                  if (num >= 1000000) {
                        return (num / 1000000).toFixed(1) + 'M';
                  } else if (num >= 1000) {
                        return (num / 1000).toFixed(1) + 'K';
                  } else {
                        return num;
                  }
            },

            formatNumber2(num) {
                  if (!num) return '-';

                  // Remove any non-digit characters except the decimal point
                  const numericPart = num.toString().replace(/[^0-9.]/g, '');

                  // Convert the cleaned string back to a number
                  const asNumber = parseFloat(numericPart);

                  // Return formatted number with 'en-US' locale to ensure commas
                  return '$' + asNumber.toLocaleString('en-US');
            },

            async fetchTags() {
                  try {
                        const response = await axios.get(`${this.apiEndpoint}/creator-tags/${this.profileId}`);
                        this.tags = response.data.map(tagObj => {
                              return { tag: tagObj.tag.toLowerCase() };
                        });
                  } catch (error) {
                        //  console.error('Error fetching tags:', error);
                  }
            },

            openModal(post) {
                  this.selectedPost = post;
                  this.isModalOpen = true;
            },

            closeModal() {
                  this.isModalOpen = false;
            },

            openImageModal(imagePath) {
                  this.selectedImage = imagePath;
                  this.isImageModalOpen = true;
            },

            closeImageModal() {
                  this.isImageModalOpen = false;
            },

            backdropClick(e) {
                  if (e.target === e.currentTarget) {
                        this.closeModal();
                        this.closeImageModal();
                  }
            },

            handlePostClick(post, index) {
                  if (this.isSubscribed || (!this.isSubscribed && index === 0)) {
                        this.selectedPost = post;
                        // Determine whether to display an image or video in the modal
                        if (post.video_path) {
                              this.modalContentType = 'video';
                              this.selectedImage = this.getImageUrl(post.video_path);
                        } else {
                              this.modalContentType = 'image';
                              this.selectedImage = this.getImageUrl(post.thumbnail_path);
                        }
                        this.isModalOpen = true;
                  } else {
                        // Navigate to pricing page when not subscribed
                        this.showSubscribeModal=true;
                  }
            },

            openSignUpModal() {
                  this.$refs.signUpModal.isVisible = true;
            },

            toggleText() {
                  this.isCollapsed = !this.isCollapsed;
                  this.isExpanded = !this.isExpanded;
            },



            // ------------------------------------------------------------------
            // Fetch data
            // ------------------------------------------------------------------

            async fetchProfileData(profileId) {

                  eventBus.emit('loading-state-changed', true);

                  const onsite = sessionStorage.getItem('onsite');
                  console.error("before onsite:", onsite);
                  if (onsite === 'false') {
                        sessionStorage.setItem('onsite', 'true');
                        console.error("after onsite:", onsite);
                  }


                  if (!profileId) {
                        //console.error("Profile ID is undefined");
                        return;
                  }

                  try {
                        const profileResponse = await axios.get(`${this.apiEndpoint}/profile-view/${profileId}`);

                        // Reduce the array of objects into a single object
                        this.profileData = profileResponse.data.reduce((acc, item) => {
                              const key = Object.keys(item)[0];
                              acc[key] = item[key];
                              return acc;
                        }, {});

                        //console.log('Profile data:', this.profileData);
                  } catch (error) {
                        // console.error('Error fetching profile data:', error);
                  } finally {
                        eventBus.emit('loading-state-changed', false); // End loading
                  }
            },

            getImageUrl(relativePath) {
                  return `${this.spacesBaseUrl}${relativePath}`;
            },

            truncateText(text, length) {
                  if (text.length <= length) {
                        return text;
                  }
                  return text.substring(0, length) + '...';
            },

            async fetchContentData(profileId) {

                  if (!profileId) {
                        //console.error("Profile ID is undefined");
                        return;
                  }

                  try {
                        const response = await axios.get(`${this.apiEndpoint}/creator-content/${profileId}`);
                        this.contentData = response.data;
                        //console.log('Content data:', this.contentData);
                  } catch (error) {
                        //console.error('Error fetching content data:', error);
                  }
            },

            goBack() {

                  //localStorage.setItem('navigatedFromDashboard', true);
                  window.history.back();
                  //this.$emit('close');
            },
            mounted() {

                  document.addEventListener('click', this.handleClickOutside);

                  this.fetchData()
                  // Restore scroll position after navigating back
                  this.$nextTick(() => {
                        const scrollPosition = this.$store.state.scrollPosition;
                        this.$router.app.$refs.scrollableDiv.scrollTop = scrollPosition;
                  });

                  if (this.$route.path !== '/profile' || this.fromDashboard === false) {
                        //localStorage.removeItem('navigatedFromDashboard');
                  }
            },

            beforeUnmount() {
                  // Clean up the event listener when the component is destroyed
                  document.removeEventListener('click', this.handleClickOutside, true);
            },

      }
}
</script>