<template>
      <div class="header-guest">
            <div class="header-guest-logo">
                  <router-link to="/"><img class="crowdscore-logo-icon" :src="require('@/assets/crowdscore-logo.png')" alt="Logo"></router-link>
            </div>
            <div class="header-guest-flexspace" />
            <div class="header-guest-menu">
                  
                  <router-link to="/top100">
                        <div class="menu-item">
                              <div class="menu-text">Top 100</div>
                        </div>
                  </router-link>
                  
                  <!-- <div v-if="!globalState.isLoggedIn">
                        <router-link to="/dashboard">
                              <div class="menu-item">
                                    <div class="menu-text">Dashboard</div>
                              </div>
                        </router-link>
                  </div> -->
                  
                  <router-link to="/pricing">
                        <div class="menu-item">
                              <div v-if="!isSubscribed" class="menu-text">Pricing</div>
                        </div>
                  </router-link>
                  
                  <div class="menu-item">
                        <div v-if="!isSubscribed && !globalState.isLoggedIn" @click="openLoginModal" class="menu-text" >Login</div>
                        <LoginModal ref="loginModal" />
                  </div>

                  <router-link to="/search">
                        <div class="header-search"> 
                              <div class="header-search-icon"><img src="/images/icon-header-search.png" alt=""></div>
                        </div>
                  </router-link>
            

                  <!-- Dropdown: logged in and subscribed -->

                  <div v-if="globalState.isLoggedIn || isSubscribed" class="db-user-avatar2" @click="toggleDropdown"><img src="/images/icon-avatar.png" alt="Account"></div>
                  <div v-if="isDropdownVisible && globalState.isLoggedIn" class="dropdown-menu-banner" @mouseleave="hideDropdown">
                        
                        <div class="dropdown-header">
                              <div class="dropdown-header-name">{{ emailPrefix }}</div>
                              <div class="dropdown-header-email">{{ userEmail }}</div>
                        </div>
                        
                        <hr> <!-- Visual divider -->
                        
                        <router-link to="/top100" @click="hideDropdown"> 
                              <div class="dropdown-item-group"> 
                                    <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-top100.png" alt=""></div>
                                    <div class="dropdown-item-text">Top 100</div>
                              </div>
                        </router-link>

                        <router-link to="/mylists">
                              <div class="dropdown-item-group">  
                                    <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-lists.png" alt=""></div>
                                    <div class="dropdown-item-text">My Lists</div>
                              </div>
                        </router-link>

                        <router-link to="/settings" @click="hideDropdown">
                              <div class="dropdown-item-group"> 
                                    <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-settings.png" alt=""></div>
                                    <div class="dropdown-item-text">Settings</div>
                              </div>
                        </router-link>
                        
                        <hr> <!-- Visual divider -->
                        
                        <div class="dropdown-item-group" @click="logoutAndHide">  
                              <div class="dropdown-item-icon"> <img src="/images/icon-dropdown-logout.png" alt=""></div>
                              <div class="dropdown-item-text">Logout</div>
                        </div>


                  </div>

            </div>
      </div>
      
  </template>

<script>
//import { eventBus } from '@/eventBus';
import { inject, computed } from 'vue';
import { useStore } from 'vuex';
import LoginModal from './LoginPage.vue';

export default {
      name: 'HomeHeader',
      components: {
            LoginModal
      },

      data() {
            return {
                  isDropdownVisible: false,
                  // ... other data properties ...
            };
      },

      setup() {
            const globalState = inject('globalState'); // Keep using injected globalState for isLoggedIn
            const store = useStore(); // Access Vuex store
            const isSubscribed = computed(() => store.state.isSubscribed); // Use Vuex for isSubscribed
            const userEmail = computed(() => store.getters.email);

            const emailPrefix = computed(() => {
                  const email = userEmail.value;
                  const prefix = email.split('@')[0];
                  return prefix.charAt(0).toUpperCase() + prefix.slice(1);
            });
            
            return {
                  globalState, // If isLoggedIn is part of globalState
                  isSubscribed,
                  emailPrefix,
                  userEmail,
            };
      },

            

      methods: {

            toggleDropdown() {
                  this.isDropdownVisible = !this.isDropdownVisible;
            },

            //showDropdown() {
            //      this.isDropdownVisible = true;
            //},

            hideDropdown() {
                  this.isDropdownVisible = false;
            },

            logoutAndHide() {
                  // Call your logout logic here
                  this.logout();
                  this.hideDropdown();
            },

            logout() {
                  // Clear JWT tokens from localStorage
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('refreshToken');
                  localStorage.removeItem('userEmail');
                  localStorage.removeItem('brandId');

                  // Update Vuex state for isSubscribed
                  // this.$store.commit('setIsSubscribed', false);
                   // Dispatch the logout action to reset Vuex state
                  this.$store.dispatch('logout');

                  // Update isLoggedIn state if needed. This assumes you have a method in globalState to handle this
                  if (this.globalState.updateLoginStatus) {
                        this.globalState.updateLoginStatus(false, false);
                  }

                  // Redirect to home page or login page
                  //this.$router.push('/');
                  this.$router.push('/').then(() => {
                        window.location.href = window.location.origin + '/';
                  });

                  // Logic to handle user logout
                  this.isDropdownVisible = false;

            },

            openLoginModal() {
                  this.$refs.loginModal.isVisible = true;
            },

            //My Lists event bus commented out until we change to stub page...

           // handleMyListsClick() {
            //      this.toggleTaskPanel();
            //      eventBus.emit('my-lists-clicked');
            //      this.$nextTick(() => {
            //            this.isTaskPanelVisible = true;
            //      });
            //},

      }
};

</script>

