<template>
  <div class="db-content">
    <div class="db-content-result-block">
      <div class="db-content-block-panel-grid">
        <div v-if="isLoading && searchResults.length === 0">
          <img class="loading-spinner" alt="Loading…" src="/images/loading-spinner.gif" />
        </div>
        <div v-if="isLoading && searchResults.length > 0">
          <img class="loading-spinner-bottom" alt="Loading…" src="/images/loading-spinner.gif" />
        </div>

        <!-- No results -->
        <div v-if="!isLoading && searchResultsLoaded && searchResults.length === 0">
          <div v-if="isListSearch && !noSavedListsAvailable" class="no-results-message">
            {{ formattedSearchInput }} is empty
          </div>
          <div v-if="!isListSearch && !noSavedListsAvailable" class="no-results-message">
            No results found
          </div>
          <div v-if="noSavedListsAvailable" class="no-results-message">
            {{ noListsMessage }}
          </div>
        </div>

        <div v-if="showBackButton" class="profile-backbutton" @click="goBack">
          <img class="profile-backarrow-icon" alt="" src="/images/backarrow.png" />
          <div class="back">Back</div>
        </div>

        <div class="db-result-block-header-container">
          <div class="db-result-block-header" v-if="searchResults.length > 0 && !isProfileActive">
            <div class="db-block-header-info">
              <div class="db-block-header-info-text">{{ formattedSearchInput }}</div>
            </div>
            <div class="db-block-header-info-time-resu">
              {{ formatNumberWithPluralization(totalResultsCount) }}
            </div>
          </div>

          <div v-if="searchResults.length > 0 && !isProfileActive" class="filter-box">
            <div class="dropdown-container">
              <div class="dropdown-heading">Gender<br /></div>
              <select class="search-dropdown" @change="handleDropdownChange" v-model="selectedGenderFilter">
                <option value="all" selected>All</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div class="dropdown-container">
              <div class="dropdown-heading">Age<br /></div>
              <select class="search-dropdown" @change="handleDropdownChange" v-model="selectedAgeFilter">
                <option value="age-all" selected>All</option>
                <option value="18-25">18-25</option>
                <option value="25-35">25-35</option>
                <option value="35-45">35-45</option>
                <option value="45-55">45-55</option>
                <option value="55+">55+</option>
              </select>
            </div>

            <div class="dropdown-container">
              <div class="dropdown-heading">Country<br /></div>
              <select class="search-dropdown" @change="handleDropdownChange" v-model="selectedCountryFilter">
                <option value="ALL" selected>All</option>
                <option v-for="country in availableCountries" :value="country.code" :key="country.code">
                  {{ country.name }}
                </option>
              </select>
            </div>

            <div class="dropdown-container">
              <div class="dropdown-heading">Sort by<br /></div>
              <select class="search-dropdown" @change="handleDropdownChange" v-model="selectedSortBy">
                <option value="date" selected>Date added</option>
                <option value="followers-high-low">Followers: High to Low</option>
                <option value="followers-low-high">Followers: Low to High</option>
                <option value="cpp-high-low">CPP: High to Low</option>
                <option value="cpp-low-high">CPP: Low to High</option>
                <option value="engagement">Engagement</option>
                <option value="growth">Growth</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Profile Grid Container with Auto-scroll -->
        <div class="searchgrid2" v-show="!isProfileActive" ref="scrollableDiv">
          <div class="profile-card-search" v-for="(profile, index) in searchResults" :key="profile.ig_id || index"
          @click="handleProfileClick(profile.ig_username)">
            <div class="creator-avatar-search">
              <img class="creator-info-avatar-displaynam-icon" :alt="profile.ig_full_name"
                :src="getImageUrl(profile.ig_profile_image_path)" />
              <div class="overlay-content">
                <div class="badge">{{ profile.ig_category }}</div>
                <div class="user-info">
                  <h3 class="username-search">@{{ profile.ig_username }}</h3>
                  <p class="followers-search">{{ formatNumber(profile.ig_followers) }} followers</p>
                </div>
                <div v-if="isListSearch" class="remove-user-btn" @click.stop="removeUser(index)">×</div>
              </div>
            </div>
          </div>
          <div v-if="!isSubscribed && searchResultsLoaded && searchResults.length > 0" class="floating-upgrade-message-container">
            <router-link to="/pricing"><div class="floating-upgrade-message">Upgrade to see all results</div></router-link>
          </div>
        </div>

        <transition name="slide">
          <ProfileView v-if="isProfileActive" :profile-id="activeProfileId" :show-back-button="searchResults.length !== 1"
            @close="closeProfile" @vibeSearch="handleVibeSearch" />
        </transition>
      </div>
    </div>
  </div>

  <div v-if="showSubscribeModal" class="alertmodal">
    <div class="alertmodal-content">
      <div class="alertmodal-heading">This is a subscriber feature</div>
      <div class="alertmodal-actions">
        <router-link to="/pricing" class="alertmodal-submit">Subscribe</router-link>
        <div @click="showSubscribeModal = false" class="alertmodal-cancel">OK</div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/eventBus';
import axios from 'axios';
import ProfileView from '@/components/ProfileView.vue';
import { useStore, mapState } from 'vuex';
import { inject, computed } from 'vue';

export default {
  components: {
    ProfileView
  },
  props: {
    searchInput: String,
    currentListName: String,
    username: String
  },

  setup() {
    const globalState = inject('globalState'); // Inject the global state
    const store = useStore(); // Vuex store for state management
    const isSubscribed = computed(() => store.state.isSubscribed); // Vuex managed isSubscribed

    return {
      globalState, // For isLoggedIn
      isSubscribed,
    };
  },

  data() {
    return {
      isProfileActive: false,
      activeProfileId: null,
      totalResultsCount: 0,
      searchResults: [],
      sortOptions: ['Option 1', 'Option 2', 'Option 3'],
      spacesBaseUrl: 'https://cdn-crowdscore.nyc3.cdn.digitaloceanspaces.com/',
      isLoading: false,
      showSpinner: false,
      isSearchInitiated: false,
      currentPage: 1,
      pageSize: 30,
      allResultsLoaded: false,
      isPageLoading: false,
      searchResultsLoaded: false,
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
      noSavedListsAvailable: false,
      noListsMessage: '',
      showSubscribeModal: false,
      selectedGenderFilter: 'all', // Gender filter
      selectedAgeFilter: 'age-all', // Age filter
      selectedCountryFilter: 'ALL', // Country filter
      availableCountries: [
        { code: 'ARG', name: 'Argentina' },
        { code: 'AUS', name: 'Australia' },
        { code: 'AUT', name: 'Austria' },
        { code: 'BEL', name: 'Belgium' },
        { code: 'BRA', name: 'Brazil' },
        { code: 'CAN', name: 'Canada' },
        { code: 'CHN', name: 'China' },
        { code: 'COL', name: 'Colombia' },
        { code: 'DNK', name: 'Denmark' },
        { code: 'FIN', name: 'Finland' },
        { code: 'DEU', name: 'Germany' },
        { code: 'GRC', name: 'Greece' },
        { code: 'IND', name: 'India' },
        { code: 'IDN', name: 'Indonesia' },
        { code: 'IRL', name: 'Ireland' },
        { code: 'ISR', name: 'Israel' },
        { code: 'ITA', name: 'Italy' },
        { code: 'JAM', name: 'Jamaica' },
        { code: 'JPN', name: 'Japan' },
        { code: 'MEX', name: 'Mexico' },
        { code: 'NLD', name: 'Netherlands' },
        { code: 'NZL', name: 'New Zealand' },
        { code: 'NOR', name: 'Norway' },
        { code: 'PHL', name: 'Philippines' },
        { code: 'POL', name: 'Poland' },
        { code: 'PRT', name: 'Portugal' },
        { code: 'RUS', name: 'Russia' },
        { code: 'SGP', name: 'Singapore' },
        { code: 'ZAF', name: 'South Africa' },
        { code: 'KOR', name: 'South Korea' },
        { code: 'ESP', name: 'Spain' },
        { code: 'SWE', name: 'Sweden' },
        { code: 'CHE', name: 'Switzerland' },
        { code: 'THA', name: 'Thailand' },
        { code: 'TUR', name: 'Turkey' },
        { code: 'UKR', name: 'Ukraine' },
        { code: 'GBR', name: 'United Kingdom' },
        { code: 'USA', name: 'United States' },
      ],  // Static list of available countries
      selectedSortBy: 'date'  // Default sort option
    };
  },

  computed: {
    ...mapState(['brandId']),

    formattedSearchInput() {
      if (this.searchInput.startsWith('c=')) {
        return this.searchInput.replace('c=', '');
      } else if (this.searchInput.startsWith('v=')) {
        return this.searchInput.replace('v=', '');
      } else if (this.searchInput.startsWith('l=')) {
        const listName = localStorage.getItem('listname');
        if (listName) {
          return listName || this.currentListName || 'Saved List';
        }
      }
      return this.searchInput;
    },

    isListSearch() {
      return this.searchInput.startsWith('l=');
    },

    ...mapState(['isSubscribed']),

    showBackButton() {
      const lastRoutePath = localStorage.getItem('lastRoutePath');
      return (lastRoutePath === '/mylists' || lastRoutePath === '/dashboard' || window.location.pathname === '/dashboard') && this.isProfileActive === false;
    },
  },

  watch: {
    '$route'(newRoute) {
      if (newRoute.params.username) {
        this.fetchProfileIdByUsername(newRoute.params.username);
      } else {
        this.isProfileActive = false;
      }
    },

    searchInput(newVal) {
      this.updateMeta();
      if (newVal) {
        this.searchResults = [];
        this.currentPage = 1;
        this.allResultsLoaded = false;
        this.searchResultsLoaded = false;
        this.fetchSearchResults();
      }
    },

    searchResults(newVal) {
      if (newVal && newVal.length > 0) {
        this.attachScrollListener();
      }
    },

    username(newUsername) {
      if (newUsername) {
        this.fetchProfileIdByUsername(newUsername);
      }
    }
  },

  methods: {

    handleProfileClick(username) {
      this.$router.push({ path: '/' + username });
    },

    async removeUser(index) {
      const profile = this.searchResults[index];
      const brandId = this.brandId;
      let listId;

      if (this.searchInput.startsWith('l=')) {
        listId = this.searchInput.split('=')[1];
      }

      try {
        const response = await axios.post(`${this.apiEndpoint}/remove-profile`, {
          brand_id: brandId,
          profile_id: profile.ig_id,
          list_id: listId
        });

        if (response.status === 200) {
          this.searchResults.splice(index, 1);
        } else {
          console.error('Failed to remove profile from list:', response.data);
        }
      } catch (error) {
        console.error('Error removing profile from list:', error);
      }
    },

    goBack() {
      window.history.back();
    },

    updateMeta() {
      let searchTerm = this.searchInput;

      if (searchTerm.startsWith('c=') || searchTerm.startsWith('v=')) {
        searchTerm = searchTerm.substring(2);
      }

      document.title = `${searchTerm} Creators, Top ${searchTerm} Influencers, Top ${searchTerm} Bloggers and more ...`;
      this.setMetaTag('description', `Use powerful AI to search for the best ${searchTerm} creators and influencers in the world. Find the top ${searchTerm} Instagram creators, top ${searchTerm} Youtube creators, top ${searchTerm} Tiktok creators`);
      this.setMetaTag('keywords', `top ${searchTerm} creators, top ${searchTerm} influencers, top youtube ${searchTerm} creators, top youtube ${searchTerm} influencers`);
    },

    setMetaTag(name, content) {
      let element = document.head.querySelector(`meta[name="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('name', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    },

    async fetchProfileIdByUsername(username) {
      try {
        const url = `${this.apiEndpoint}/search-creators/@${username}`;
        const response = await axios.get(url);

        if (response.data && response.data.results.length > 0) {
          const profileId = response.data.results[0].id;
          this.activeProfileId = profileId;
          this.isProfileActive = true;
        } else {
          console.error('No profile found for the given username');
          this.isProfileActive = false;
        }
      } catch (error) {
        console.error('Error fetching profile ID by username:', error);
        this.isProfileActive = false;
      }
    },

    handleVibeSearch(vibeId) {
      this.$emit('vibeSearch', vibeId);
    },

    formatNumber(num) {
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
      } else {
        return num;
      }
    },

    formatNumberWithPluralization(number) {
      const formattedNumber = new Intl.NumberFormat().format(number);
      return `${formattedNumber} result${number === 1 ? '' : 's'}`;
    },

    formatGrowth(growth) {
      return growth != null ? (growth * 100).toFixed(2) + '%' : '0%';
    },

    saveScrollPosition() {
      const scrollableDiv = this.$refs.scrollableDiv;
      if (scrollableDiv) {
        sessionStorage.setItem('dashboardScrollPosition', scrollableDiv.scrollTop);
      }
    },

    closeProfileView() {
      this.isProfileActive = false;
    },

    restoreScrollPosition() {
      const savedPosition = sessionStorage.getItem('dashboardScrollPosition');
      if (savedPosition) {
        this.$refs.scrollableDiv.scrollTop = savedPosition;
        sessionStorage.removeItem('dashboardScrollPosition');
      }
    },

    openProfile(profileId) {
      this.activeProfileId = profileId;
      this.saveScrollPosition();
      this.isProfileActive = true;
    },

    closeProfile() {
      this.isProfileActive = false;
    },

    getImageUrl(relativePath) {
      return `${this.spacesBaseUrl}${relativePath}`;
    },

    handleClickOutside(index) {
      if (this.searchResults[index]) {
        // No empty block
      }
    },

    handleDropdownChange() {
      if (!this.isSubscribed) {
        this.showSubscribeModal = true;
      } else {
        this.updateUrlParams(); // Update the URL with selected filters
        this.searchResults = [];
        this.currentPage = 1;
        this.allResultsLoaded = false;
        this.searchResultsLoaded = false;
        this.fetchSearchResults();
      }
    },

    updateUrlParams() {
      const url = new URL(window.location.href);
      url.searchParams.set('gender', this.selectedGenderFilter);
      url.searchParams.set('age', this.selectedAgeFilter);
      url.searchParams.set('country', this.selectedCountryFilter);
      url.searchParams.set('sort_by', this.selectedSortBy); // Ensure sort_by is included
      window.history.replaceState({}, '', url);
    },

    async fetchSearchResults() {
      this.closeProfileView();

      if (this.allResultsLoaded || this.isLoading || (!this.isSubscribed && this.currentPage > 1)) {
        return;
      }
      this.isSearchInitiated = true;
      this.isLoading = true;
      this.showSpinner = false;

      const genderFilter = this.selectedGenderFilter || 'all'; // Ensure gender filter is always passed
      const ageFilter = this.selectedAgeFilter || 'age-all'; // Ensure age filter is always passed
      const countryFilter = this.selectedCountryFilter || 'ALL'; // Ensure country filter is always passed
      const sortBy = this.selectedSortBy || 'date';  // New sortBy parameter

      let searchUrl;
      if (this.searchInput.startsWith('c=')) {
        const categoryId = this.searchInput.split('=')[1];
        searchUrl = `${this.apiEndpoint}/search-creators/c=${categoryId}?page=${this.currentPage}&pageSize=${this.pageSize}&gender=${genderFilter}&age=${ageFilter}&country=${countryFilter}&sort_by=${sortBy}`;
      } else if (this.searchInput.startsWith('v=')) {
        const categoryId = this.searchInput.split('=')[1];
        searchUrl = `${this.apiEndpoint}/search-creators/v=${categoryId}?page=${this.currentPage}&pageSize=${this.pageSize}&gender=${genderFilter}&age=${ageFilter}&country=${countryFilter}&sort_by=${sortBy}`;
      } else if (this.searchInput.startsWith('l=')) {
        const listId = this.searchInput.split('=')[1];
        searchUrl = `${this.apiEndpoint}/search-creators/l=${listId}?page=${this.currentPage}&pageSize=${this.pageSize}&gender=${genderFilter}&age=${ageFilter}&country=${countryFilter}&sort_by=${sortBy}`;
      } else {
        searchUrl = `${this.apiEndpoint}/search-creators/${encodeURIComponent(this.searchInput)}?page=${this.currentPage}&pageSize=${this.pageSize}&gender=${genderFilter}&age=${ageFilter}&country=${countryFilter}&sort_by=${sortBy}`;
      }

      console.log(`Selected Gender: ${genderFilter}`); // For debugging
      console.log(`Selected Age: ${ageFilter}`); // For debugging
      console.log(`Selected Country: ${countryFilter}`); // For debugging
      console.log(`Selected Sort By: ${sortBy}`); // For debugging
      console.log(`Search URL: ${searchUrl}`); // For debugging

      try {
        const response = await axios.get(searchUrl);

        if (response.data && Array.isArray(response.data.results) && typeof response.data.total_count === 'number') {
          this.totalResultsCount = response.data.total_count;

          const profileDataPromises = response.data.results.map(item => {
            const profileUrl = `${this.apiEndpoint}/profile-card/${item.id}`;
            return axios.get(profileUrl);
          });

          const profileResponses = await Promise.all(profileDataPromises);

          const newProfiles = profileResponses.map(profileResponse => {
            const profileData = profileResponse.data.reduce((acc, item) => ({ ...acc, ...item }), {});
            return {
              ig_id: profileData.ig_id,
              ig_full_name: profileData.ig_full_name,
              ig_profile_image_path: profileData.ig_profile_image_path,
              ig_category: profileData.ig_category,
              ig_username: profileData.ig_username,
              ig_followers: profileData.ig_followers,
              follower_growth: profileData.follower_growth, // Added to include growth in the profile data
              isContentVisible: true,
              selectedOption: this.sortOptions[0],
            };
          });

          this.searchResults = [...this.searchResults, ...newProfiles];

          if (response.data.results.length > 0) {
            this.searchResultsLoaded = true;
          }

          if (response.data.results.length < this.pageSize) {
            this.allResultsLoaded = true;
          }

          this.searchResultsLoaded = true;
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        this.isLoading = false;
      }

      this.currentPage++;
    },

    handleScroll(event) {
      if (!this.isSubscribed && this.currentPage > 1) {
        return;
      }

      const element = event.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 200) {
        this.fetchSearchResults();
      }
    },

    attachScrollListener() {
      this.$nextTick(() => {
        const scrollableDiv = this.$refs.scrollableDiv;
        if (scrollableDiv) {
          scrollableDiv.removeEventListener('scroll', this.handleScroll);
          scrollableDiv.addEventListener('scroll', this.handleScroll);
        } else {
          setTimeout(() => this.attachScrollListener(), 500);
        }
      });
    },
  },

  mounted() {
    this.updateMeta();
    this.attachScrollListener();
    this.restoreScrollPosition();
    
    // Parse the URL parameters and set filters accordingly
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedGenderFilter = urlParams.get('gender') || 'all';
    this.selectedAgeFilter = urlParams.get('age') || 'age-all';
    this.selectedCountryFilter = urlParams.get('country') || 'ALL';
    this.selectedSortBy = urlParams.get('sort_by') || 'date';
    
    if (this.username) {
      this.fetchProfileIdByUsername(this.username);
    }
    document.body.style.visibility = 'visible';

    eventBus.on('no-lists-available', () => {
      this.searchResults = [];
      this.noListsMessage = "You have no saved lists";
      this.noSavedListsAvailable = true;
    });
  },

  beforeUnmount() {
    const scrollableDiv = this.$refs.scrollableDiv;
    if (scrollableDiv) {
      scrollableDiv.removeEventListener('scroll', this.handleScroll);
    }
    eventBus.off('no-lists-available');
  },
};
</script>
